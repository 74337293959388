import CardItem from 'components/SoraWallet/Cards/CardItem';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import PencilAndPaperIcon from 'assets/icons/PencilAndPaper.svg';

// Our Utility
import { dollarFormatter, roundToDecimal } from 'shared/utils';

function LoanDataCard({ currentLoanDetails }) {
	const SoraTheme = useTheme();
	const { indigo } = SoraTheme.palette.primary;

	const interestRate = currentLoanDetails?.interestRate;
	const monthlyPayment = dollarFormatter.format(
		currentLoanDetails?.monthlyMortgagePayment
	);

	return (
		<Grid item sm={6} md={6} lg={3} xl={3}>
			<CardItem
				withoutButton
				cardHeading="Loan Details"
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: '0'
				}}
			>
				<Typography variant="h5" sx={{ color: indigo }}>
					{roundToDecimal(interestRate, 1)}%
				</Typography>

				<Typography variant="caption" sx={{ flexGrow: 2 }}>
					Interest Rate
				</Typography>

				<Typography variant="h5" sx={{ color: indigo }}>
					{monthlyPayment}
				</Typography>

				<Typography variant="caption" sx={{ flexGrow: 2 }}>
					Monthly Payment
				</Typography>
				<Box
					component="img"
					alt="Loan Data icon, pencil and paper"
					src={PencilAndPaperIcon}
					sx={{
						width: '84px',
						height: '84px',
						alignSelf: 'center'
					}}
				/>
			</CardItem>
		</Grid>
	);
}

LoanDataCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	currentLoanDetails: PropTypes.object.isRequired
};

export default LoanDataCard;
