import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';

// Our components
import SearchInput from 'components/Input/SearchInput';
import useDebounce from 'hooks/useDebounce';

function SearchClients({ debounceCallBack, sx }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const [searchData, setSearchData] = useState('');
	const debouncedSearchResult = useDebounce(searchData, 100);

	useEffect(() => {
		debounceCallBack(debouncedSearchResult);
	}, [debouncedSearchResult]);

	return (
		<SearchInput
			autoComplete="off"
			value={searchData}
			onChange={(e) => setSearchData(e.target.value)}
			placeholder="Client Name"
			sx={{
				...sx,
				border: `1px solid ${primary.lightGrey}`,
				borderRadius: 40
			}}
		/>
	);
}

export default SearchClients;
