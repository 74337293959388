import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

// Our components
import Checkbox from 'components/Checkbox/Checkbox';

// Our Assets
import AutoLoanImage from 'assets/images/auto_loan.svg';
import HomeLoanImage from 'assets/images/house_loan.svg';
import PersonalLoanImage from 'assets/images/personal_loan.svg';
import StudentLoanImage from 'assets/images/student_loans.svg';

const VALID_LIABILITY_TYPES = [
	'Mortgage',
	'Home',
	'Auto',
	'Student',
	'Personal'
];

// this fn decides which image to show
const getImageBasedOnLiabilityType = (LiabilityType) => {
	switch (LiabilityType) {
		// home and Mortgage get treated the same
		case 'Home':
		case 'Mortgage':
			return HomeLoanImage;
		case 'Auto':
			return AutoLoanImage;
		case 'Student':
			return StudentLoanImage;
		case 'Personal':
			return PersonalLoanImage;
		default:
			return HomeLoanImage;
	}
};

// this fn decies which heading to use based on LiabilityType
const getLoanHeading = (LiabilityType) => {
	switch (LiabilityType) {
		case 'Home':
		case 'Mortgage':
			return 'Home Loan';
		case 'Auto':
			return 'Auto Loan';
		case 'Student':
			return 'Student Loan';
		case 'Personal':
			return 'Personal Loan';
		default:
			return HomeLoanImage;
	}
};

function SelectLoanLiability({
	disable,
	LiabilityType,
	onClickNavRoute,
	subHeading1,
	subHeading2,
	subValue1,
	subValue2,
	withDetails,
	withoutCheckBox,
	routeState
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const gapBetweenCheckBoxAndHeading = LiabilityType === 'Auto' ? 8 : 6;
	const justifyContentCheckBox = !withoutCheckBox ? 'normal' : 'center';
	const borderStyle = !disable ? '3px solid #295983' : '';
	const cursorStyle = !disable && 'pointer';
	const opacityValue = !disable ? 1 : 0.4;
	const navigate = useNavigate();
	// This determines what Image is rendered based on Liability Type
	const LiabilityImage = useMemo(
		() => getImageBasedOnLiabilityType(LiabilityType),
		[LiabilityType]
	);

	const LiabilityHeading = useMemo(
		() => getLoanHeading(LiabilityType),
		[LiabilityType]
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxSizing: 'border-box',
				width: 400,
				height: 300,
				marginBottom: 3,
				paddingTop: 2,
				background:
					'linear-gradient(180deg, rgba(0, 156, 255, 0.4) 0%,rgba(0, 156, 255, 0.17) 100%)',
				border: borderStyle,
				borderRadius: '20px',
				opacity: opacityValue,
				cursor: cursorStyle,
				userSelect: 'none'
			}}
			onClick={() =>
				!disable && navigate(onClickNavRoute, { state: routeState })
			}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: justifyContentCheckBox,
					alignItems: 'flex-end',
					width: '100%',
					gap: gapBetweenCheckBoxAndHeading
				}}
			>
				{!withoutCheckBox && (
					<Checkbox
						sx={{
							marginLeft: 2,
							'&:hover': {
								cursor: 'pointer'
							}
						}}
						checkboxSx={{
							'&.Mui-checked': {
								color: primary.indigo
							}
						}}
						label=""
						checked={!disable && true}
						onChange={() => null} // this is here BC we get an error if onChange isn't defined
						disabled={!disable}
					/>
				)}

				<Typography variant="h5" gutterBottom>
					{LiabilityHeading}
				</Typography>
			</Box>

			<Box sx={{ height: 120 }}>
				{/* The box above will occupy the same amount of space.
					Making the height of the cards below it consistent
				 */}
				{disable && (
					<Typography variant="body1" gutterBottom>
						*Coming Soon
					</Typography>
				)}
			</Box>

			{!withDetails && (
				<Box
					component="img"
					src={LiabilityImage}
					sx={{
						width: '70%',
						height: '70%',
						WebkitUserDrag: 'none',
						alignSelf: 'center'
					}}
				/>
			)}

			{/* TODO this flag is to enable the ability to add additional details. such as Lower total cost of debt in the select loan for refi */}
			{/* Based on https://sorafinance.atlassian.net/browse/SWB-211 we will not be using the select loan for refi or cash out */}
			{/* The code will remain but consider withDetails obsolete for now Sep 6, 2022 */}
			{withDetails && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignContent: 'center',
						alignItems: 'center',
						flexGrow: 2,
						width: '100%'
					}}
				>
					{/* if withDetails is false then */}
					<>
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							{subHeading1}
						</Typography>
						<Typography variant="h6">{subValue1}</Typography>
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'center',
								paddingLeft: 9,
								marginTop: 2
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignText: 'center'
								}}
							>
								<Typography variant="body1">
									{subHeading2}
								</Typography>
								<Typography
									variant="h6"
									sx={{ alignSelf: 'center' }}
								>
									{subValue2}
								</Typography>
							</Box>

							<Box
								component="img"
								src={LiabilityImage}
								sx={{
									width: 72,
									height: 72,
									userDrag: 'none',
									WebkitUserDrag: 'none'
								}}
							/>
						</Box>
					</>
				</Box>
			)}
		</Box>
	);
}

SelectLoanLiability.propTypes = {
	disable: PropTypes.bool,
	LiabilityType: PropTypes.oneOf(VALID_LIABILITY_TYPES).isRequired,
	onClickNavRoute: PropTypes.string,
	subHeading1: PropTypes.string,
	subHeading2: PropTypes.string,
	withDetails: PropTypes.bool, // This flag is for showing additional info like address, cost of debt, monthly Payment etc.
	withoutCheckBox: PropTypes.bool,
	routeState: PropTypes.shape({
		fromWallet: PropTypes.bool
	})
};

SelectLoanLiability.defaultProps = {
	disable: false,
	onClickNavRoute: '/newloan/home-loan-questionnaire',
	subHeading1: '',
	subHeading2: '',
	withDetails: false,
	withoutCheckBox: false,
	routeState: {}
};

export default SelectLoanLiability;
