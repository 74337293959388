import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import HomeLoanImage from 'assets/images/house_loan.svg';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function PropertyDataCardSimple({ currentLoanDetails }) {
	const SoraTheme = useTheme();

	const { indigo } = SoraTheme.palette.primary;

	const homeValue = dollarFormatter.format(
		currentLoanDetails?.home?.estimatedValue ?? 0
	);

	const addressPart1 = currentLoanDetails?.home?.addressLine1 ?? 'Unknown';

	const city = currentLoanDetails?.home?.city;
	const state = currentLoanDetails?.home?.state;
	const zipCode = currentLoanDetails?.home?.zipcode;

	const formattedAddressPart1 = addressPart1.toLowerCase();

	const addressPart2 = `${city?.toLowerCase() ?? 'Unknown'}, ${state ?? ''} ${
		zipCode ?? ''
	}`;

	return (
		<Grid item sm={6} md={6} lg={3} xl={3}>
			<CardItem
				withoutButton
				cardHeading="Property Details"
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: '0'
				}}
			>
				<Box sx={{ marginBottom: 2 }}>
					<Typography
						variant="caption"
						sx={{ textTransform: 'capitalize' }}
					>
						{formattedAddressPart1}
					</Typography>
					<br />
					<Typography
						variant="caption"
						sx={{ textTransform: 'capitalize' }}
					>
						{addressPart2}
					</Typography>
				</Box>

				<Typography variant="h5" sx={{ color: indigo }}>
					{homeValue}
				</Typography>

				<Typography variant="caption" sx={{ flexGrow: 2 }}>
					Home Value
				</Typography>
				<Box
					component="img"
					src={HomeLoanImage}
					sx={{
						width: '84px',
						height: '84px',
						alignSelf: 'center'
					}}
				/>
			</CardItem>
		</Grid>
	);
}

PropertyDataCardSimple.propTypes = {
	currentLoanDetails: PropTypes.shape({
		cashOutPotential: PropTypes.number,
		costOfDebt: PropTypes.number,
		escrow: PropTypes.number,
		home: PropTypes.shape({
			addressLine1: PropTypes.string,
			addressLine2: PropTypes.string,
			assedValueUsd: PropTypes.number,
			bathCount: PropTypes.number,
			bedroomCount: PropTypes.number,
			builtYear: PropTypes.number,
			city: PropTypes.string,
			confidenceScore: PropTypes.number,
			estimatedValue: PropTypes.number,
			id: PropTypes.number,
			marketValue: PropTypes.number,
			ownerName: PropTypes.string,
			propertyType: PropTypes.string,
			saleAmount: PropTypes.number,
			salesTxDate: PropTypes.string,
			sellerName: PropTypes.string,
			state: PropTypes.string,
			toalSqft: PropTypes.number,
			zipcode: PropTypes.string
		})
	})
};

PropertyDataCardSimple.defaultProps = {
	currentLoanDetails: {
		totalOutstandingBalance: 0,
		costOfDebt: 0,
		monthlyPayment: 0,
		remainingMonth: 0,
		interestRate: 0,
		cashOutPotential: 0,
		tradeLineType: 'MORTGAGE',
		home: {
			id: -1,
			addressLine1: 'Unknown',
			addressLine2: 'Unknown',
			city: 'Unknown',
			state: 'Unknown',
			zipcode: 'Unknown',
			marketValue: 0,
			assedValueUsd: 0,
			estimatedValue: 0,
			confidenceScore: 0,
			propertyType: 'Unknown',
			builtYear: 0,
			toalSqft: 0,
			bedroomCount: 0,
			bathCount: 0,
			ownerName: 'Unknown',
			sellerName: 'Unknown',
			saleTxDate: 'Unknown',
			saleAmount: 0
		},
		monthlyMortgagePayment: 0,
		escrow: 0
	}
};

export default PropertyDataCardSimple;
