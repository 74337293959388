import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

import CommonPropTypes from 'shared/prop-types';

function LiabilityFormHeading({ headingText, variant, sx }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	return (
		<Typography
			data-test="heading"
			variant={variant}
			sx={{ color: primary.soraBlue, ...sx }}
		>
			{headingText}
		</Typography>
	);
}

LiabilityFormHeading.propTypes = {
	sx: CommonPropTypes.sx,
	variant: CommonPropTypes.typography
};

LiabilityFormHeading.defaultProps = {
	sx: {},
	variant: 'h4'
};

export default LiabilityFormHeading;
