import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

// Our Component
import CardItem from 'components/SoraWallet/Cards/CardItem';
import SoraToolTip from 'components/Tooltip';

// Wallet Json Data
import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

// Our Utility
import { dollarFormatter, roundToDecimal } from 'shared/utils';

// Our Prop Types
import {
	currentLoanPropShape,
	homePropShape
} from 'components/SoraWallet/WalletPropTypes';

// Icons
import autoLoan from 'assets/images/auto_loan.svg';
import houseLoan from 'assets/images/house_loan.svg';
import personalLoan from 'assets/images/personal_loan.svg';
import studentLoan from 'assets/images/student_loans.svg';

// Constant
import { MORTGAGE } from 'shared/constants';

const loanInformation = {
	MORTGAGE: {
		currentLoanHeading: 'Home Loan',
		currentLoanIcon: houseLoan
	},
	HELOC: {
		currentLoanHeading: 'Home Loan (HELOC)',
		currentLoanIcon: houseLoan
	},
	AUTO: {
		currentLoanHeading: 'Auto Loan',
		currentLoanIcon: autoLoan
	},
	STUDENT: {
		currentLoanHeading: 'Student Loan',
		currentLoanIcon: studentLoan
	},
	PERSONAL: {
		currentLoanHeading: 'Personal Loan',
		currentLoanIcon: personalLoan
	}
};

function SoraWalletLoanCard({
	currentLoanDetails,
	firstName,
	homeDetails,
	lastName,
	soraGrade
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const {
		interestRate,
		monthlyPayment,
		totalOutstandingBalance: outstandingBalance,
		tradeLineType
	} = currentLoanDetails;

	const getLoanInfoBasedOnTradeline = loanInformation[tradeLineType];

	const { currentLoanIcon, currentLoanHeading } = getLoanInfoBasedOnTradeline;

	const isMortgageTradeline = tradeLineType === MORTGAGE;

	// addressLine1 can be null
	const homeAddress =
		(homeDetails &&
			homeDetails.addressLine1 &&
			`${homeDetails.addressLine1} | `) ||
		'';

	return (
		<Grid item sm={12} md={12} lg={8} xl={6}>
			<CardItem
				withoutButton
				sx={{
					background:
						'linear-gradient(180deg, rgba(38, 133, 193, 0.3) 0%, rgba(0, 156, 255, 0.051) 100%);',
					borderRadius: 4,
					paddingBottom: 0,
					boxSizing: 'border-box'
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly'
						}}
					>
						<Typography variant="h5" gutterBottom>
							{currentLoanHeading}
						</Typography>
						<Typography variant="body1" gutterBottom>
							{homeAddress}
							{firstName} {lastName}
						</Typography>
					</Box>
					{currentLoanDetails.logoUrl && (
						<Box
							component="img"
							src={currentLoanDetails.logoUrl}
							sx={{
								userDrag: 'none',
								userSelect: 'none',
								WebkitUserSelect: 'none',
								WebKitUserDrag: 'none'
							}}
						/>
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							flexGrow: 2
						}}
					>
						<Box sx={{ display: 'flex', gap: 10 }}>
							<Box>
								<Typography variant="h4" color={primary.indigo}>
									{dollarFormatter.format(outstandingBalance)}
								</Typography>
								<Typography variant="caption">
									Balance Outstanding
								</Typography>
							</Box>
							<Box>
								<Typography variant="h4" color={primary.indigo}>
									{roundToDecimal(interestRate, 2)}%
								</Typography>
								<Typography variant="caption">Rate</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', gap: 10 }}>
							<Box>
								<Typography variant="h4" color={primary.indigo}>
									{dollarFormatter.format(
										isMortgageTradeline
											? currentLoanDetails?.monthlyMortgagePayment ??
													monthlyPayment
											: monthlyPayment
									)}
								</Typography>
								<Typography variant="caption">
									Monthly Payment
								</Typography>
							</Box>
							<Box>
								<Typography variant="h4" color={primary.indigo}>
									{soraGrade}
								</Typography>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'flex-end',
										gap: 2
									}}
								>
									<Typography variant="caption">
										Sora Score
									</Typography>
									<SoraToolTip
										text={WalletToolTips.soraScore}
									>
										<InfoIcon sx={{ cursor: 'pointer' }} />
									</SoraToolTip>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						component="img"
						src={currentLoanIcon}
						alt={currentLoanHeading}
						sx={{ justifySelf: 'flex-end' }}
					/>
				</Box>
			</CardItem>
		</Grid>
	);
}

SoraWalletLoanCard.propTypes = {
	currentLoanDetails: PropTypes.shape(currentLoanPropShape).isRequired,
	homeDetails: PropTypes.shape(homePropShape),
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	soraGrade: PropTypes.string.isRequired
};

SoraWalletLoanCard.defaultProps = {
	homeDetails: {}
};

export default SoraWalletLoanCard;
