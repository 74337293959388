import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import SoraLogoImage from 'assets/images/SoraLogoBlack.svg';

function SoraLogo() {
	const SoraTheme = useTheme();
	return (
		<Box
			component="div"
			sx={{
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center',
				paddingTop: '1rem',
				paddingBottom: '3.75rem',
				marginRight: SoraTheme.spacing(1),
				boxSizing: 'border-box',
				height: 100,
				width: '100%'
			}}
		>
			<img src={SoraLogoImage} alt="Sora logo" width="104" height="38" />
		</Box>
	);
}

export default SoraLogo;
