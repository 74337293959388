import styled from '@emotion/styled';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import PropTypes from 'prop-types';

// Our Components
import BasicFrame from 'components/Frame/Basic';
import ProgressMeter from 'components/ProgressMeter/ProgressMeter';

// Old one that will be replaced soon.
import SidebarBg from 'assets/images/OnboardingNavigationImage.svg';
import SoraLogo from 'assets/images/SoraLogoBlack.svg';

const StyledGrid = styled(Grid)(`
	margin: 0;

	& .MuiGrid-item {
		padding-top: 0;
		padding-left: 0;
	}
`);

function BasicOnboarding({
	onboardingEnabled,
	backEnabled,
	backButtonGridColumns,
	mainContentGridColumns,
	progress,
	children,
	sx
}) {
	// A basic 12 column grid split:
	// [ 3 columns | 9 columns ]
	// 3 columns: will hold the onboarding progress meter w/ illustration
	// 9 columns: Within these 9 cols we re-implemented another 12 column grid,
	// giving us more spacing options for the right side of the frame.
	const navigate = useNavigate();

	return (
		<StyledGrid
			container
			spacing={2}
			sx={{
				minHeight: '930px',
				height: '100vh',
				width: '100vw',
				position: 'relative'
			}}
		>
			<Grid
				item
				xs={3}
				sx={{
					background:
						'linear-gradient(180deg, #E3F6FF 20%, #B6DBEE 100%)',
					position: 'relative'
				}}
			>
				{/* Onboarding left bar here */}
				<Link to="/">
					<img
						src={SoraLogo}
						alt="Sora Logo"
						style={{
							width: '86px',
							marginTop: '32px',
							marginLeft: '32px'
						}}
					/>
				</Link>

				{onboardingEnabled && (
					<Box
						sx={{
							marginTop: 4,
							marginRight: 4,
							marginBottom: 10,
							marginLeft: 4
						}}
					>
						<Typography
							component="div"
							variant="h5"
							color="primary.indigo"
						>
							Onboarding
						</Typography>
						<ProgressMeter
							value={progress}
							containerStyles={{
								marginTop: 2,
								marginBottom: 2
							}}
						/>
						<Typography
							component="div"
							variant="subtitle1"
							color="primary.indigo"
						>
							{progress}% complete
						</Typography>
					</Box>
				)}
				<img
					src={SidebarBg}
					alt="Sidebar"
					style={{
						width: '100%',
						maxWidth: '435px',
						position: 'absolute',
						left: 0,
						bottom: 0
					}}
				/>
			</Grid>

			<Grid item xs={9}>
				<BasicFrame sx={{ ...sx }}>
					<Grid item xs={backButtonGridColumns}>
						{backEnabled && (
							<Box
								sx={{
									height: 64,
									width: 64,
									padding: '12px',
									cursor: 'pointer'
								}}
								onClick={() => navigate(-1)}
							>
								<IconButton aria-label="back">
									<KeyboardBackspaceOutlinedIcon />
								</IconButton>
							</Box>
						)}
					</Grid>

					{/* New row first grid item is for left hand spacing on pages */}
					<Grid item xs={mainContentGridColumns}>
						{children}
					</Grid>
				</BasicFrame>
			</Grid>
		</StyledGrid>
	);
}

BasicOnboarding.propTypes = {
	onboardingEnabled: PropTypes.bool,
	backEnabled: PropTypes.bool,
	backButtonGridColumns: PropTypes.number,
	mainContentGridColumns: PropTypes.number,
	progress: PropTypes.number
};

BasicOnboarding.defaultProps = {
	onboardingEnabled: true,
	backEnabled: false,
	backButtonGridColumns: 2,
	mainContentGridColumns: 8,
	progress: 0
};

export default BasicOnboarding;
