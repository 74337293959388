import { Box, Link as ExternalLink, Typography } from '@mui/material';
import Layout from 'components/Frame/BasicOnboarding';
import { PrimaryButton } from 'components/Button/Button';

function ThankYou() {
	return (
		<Layout backEnabled sx={{ height: '100vh', width: '100%' }}>
			<Typography
				variant="h4"
				color="primary.soraBlue"
				marginTop={25}
				sx={{ width: '23ch' }}
			>
				Thank you! You have completed the onboarding process
			</Typography>

			<Typography variant="body2" marginTop={1} width="53ch">
				It may take ~72 hours to display your information in your Sora
				Wallet. Sora will reach out once it’s complete.
			</Typography>

			<Box
				sx={{
					marginTop: 6
				}}
			>
				<ExternalLink href="https://www.sorafinance.com/">
					<PrimaryButton>Back To Homepage</PrimaryButton>
				</ExternalLink>
			</Box>
		</Layout>
	);
}

export default ThankYou;
