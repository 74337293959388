/* eslint-disable react/forbid-prop-types */
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';

// Our Components
import InsightsCard from 'components/SoraWallet/Cards/insights/InsightsCard';
import SoraCarouselArrow from 'components/Carousel/SoraCarouselArrow';

// Our Constants

const FIVE_SECONDS = 1000 * 5; // 1000 ms = 1s * 5 => 5s

function SoraInsightsCarousel({
	clientData,
	carouselBreakPoints,
	isClient,
	insightCardSX,
	insights,
	leftArrowSX,
	rightArrowSX
}) {
	const insightCards = useMemo(
		() =>
			insights.map((insight) => (
				<InsightsCard
					sx={insightCardSX}
					isClient={isClient}
					key={uuidv4()}
					insightMessage={insight}
					clientData={clientData}
				/>
			)),
		insights
	);
	return (
		<Carousel
			responsive={carouselBreakPoints}
			autoPlay
			autoPlaySpeed={FIVE_SECONDS}
			customLeftArrow={
				<SoraCarouselArrow variant="left" sx={leftArrowSX} />
			}
			customRightArrow={
				<SoraCarouselArrow variant="right" sx={rightArrowSX} />
			}
			infinite={false}
			slidesToSlide={1}
		>
			{insightCards}
		</Carousel>
	);
}

SoraInsightsCarousel.propTypes = {
	clientData: PropTypes.shape({
		annualIncome: PropTypes.number.isRequired,
		assetsAmount: PropTypes.number.isRequired,
		creditScore: PropTypes.number.isRequired,
		email: PropTypes.string,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired
	}).isRequired,
	carouselBreakPoints: PropTypes.shape({
		superLargeDesktop: PropTypes.any,
		desktop: PropTypes.any,
		tablet: PropTypes.any,
		mobile: PropTypes.any
	}),
	isClient: PropTypes.bool.isRequired,
	insightCardSX: CommonPropTypes.sx,
	insights: PropTypes.arrayOf(PropTypes.string).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	leftArrowSX: CommonPropTypes.sx,
	rightArrowSX: CommonPropTypes.sx
};

SoraInsightsCarousel.defaultProps = {
	carouselBreakPoints: {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 6000, min: 2000 },
			items: 3,
			slidesToSlide: 3
		},
		desktop: {
			breakpoint: { max: 2000, min: 1024 },
			items: 2,
			slidesToSlide: 2
		},
		tablet: {
			breakpoint: { max: 1024, min: 600 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 599, min: 0 },
			items: 1
		}
	},
	insightCardSX: {},
	leftArrowSX: {},
	rightArrowSX: {}
};

export default SoraInsightsCarousel;
