import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import Frame from 'components/Frame/BasicNav';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

import { INVITE_PAGE } from 'routes/index';

export default function ClientAddedThanksPage() {
	const SoraTheme = useTheme();

	return (
		<>
			<Helmet>
				<title>Client invited</title>
			</Helmet>

			<Frame>
				<Grid item xs={8}>
					<Typography
						variant="h4"
						gutterBottom
						component="div"
						sx={{
							marginTop: 8,
							color: SoraTheme.palette.primary.soraBlue
						}}
					>
						Thank you! You have added a client.
					</Typography>

					<Typography
						variant="body1"
						gutterBottom
						component="div"
						sx={{
							marginTop: 4
						}}
					>
						Sora will reach out once your dashboard has been
						updated. You can edit this information at any time by
						selecting a client on your dashboard.
					</Typography>

					<SecondaryButton
						href={INVITE_PAGE}
						sx={{
							marginTop: 4
						}}
					>
						Add another client
					</SecondaryButton>

					<PrimaryButton
						href="/dashboard"
						sx={{
							marginTop: 4,
							marginLeft: 2
						}}
					>
						Back to dashboard
					</PrimaryButton>
				</Grid>
			</Frame>
		</>
	);
}
