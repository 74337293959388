import { Link } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import SoraTheme from 'theme';

function SettingsTab() {
	return (
		<>
			<Typography
				variant="h6"
				sx={{
					color: SoraTheme.palette.primary.soraBlue,
					marginBottom: 2
				}}
			>
				Your Plan
			</Typography>

			<Typography
				variant="body"
				sx={{
					marginBottom: 2
				}}
			>
				If you have questions about your monthly plan, please reach out
				to Sora via our <Link to="/contact-us">contact us form</Link> or
				via email at contact@sorafinance.com
			</Typography>
		</>
	);
}

export default SettingsTab;
