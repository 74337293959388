/* eslint-disable import/prefer-default-export */
import numbro from 'numbro'; // numbro package - https://numbrojs.com/

/**
 *
 * @param {string | number | boolean | null | undefined} data
 * @returns {boolean}
 */
export const dataIsValid = (data) => data !== undefined && data !== null;

// This will format a number to currency in USD
// It will round the fraction units to 0 meaning no decimal point will be used.
// e.g. 45000 -> 45,000
export const dollarFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 0
});

// This function takes 2 things:
// 1 - The number being rounded
// 2 - To which decimal point you'd like the number rounded
// examples:
// roundToDecimal(945.876, 1) = 945.9
// roundToDecimal(945.876, 2) = 945.88
// roundToDecimal(945.876, 0) = 946

/**
 *
 * @param {number} number
 * @param {number} toDecimalPoint
 * @returns {number}
 */

export const roundToDecimal = (number, toDecimalPoint) => {
	const convertedNumber = numbro(number).format({
		thousandSeparated: true,
		mantissa: toDecimalPoint
	});

	if (Number.isNaN(convertedNumber)) {
		throw new Error(
			`${number} is not a number and therefore cannot be roundToDecimal`
		);
	}
	return convertedNumber;
};

// email regex info - https://www.regular-expressions.info/email.html
// potential regex to use - https://emailregex.com/
const validEmailRegex =
	// eslint-disable-next-line no-useless-escape
	/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;

export const isFormValueReady = (inputData) => inputData !== ''; // Returns false if value is empty
export const isSubmissionReady = (formData) => formData.every(isFormValueReady);
export const clearFormValues = (reactSetValueHooks) =>
	reactSetValueHooks.map((reactHook) => reactHook('')); // expects an array of react hook changes e.g. [setMessage, setFirstNameValue]

export const simpleValidEmail = (email) =>
	email.length > 0 && email.includes('.') && email.includes('@');

export const extractSoraScore = (soraScore) => {
	try {
		if (soraScore && soraScore.includes('_'))
			return soraScore.split('_')[2];
	} catch (e) {
		console.error(e);
		return 'NA';
	}
	return soraScore;
};

export const getErrorMessage = (errorMessage, errorMessageHandler) => {
	const processedErrorMessage = errorMessage.split(' ');
	const isFireBaseError = processedErrorMessage[0] === 'Firebase:';

	// This if statement can be converted to a switch statement once we implement more error handling
	// currently if its a firebase error then we use errorMessageHandler to write a custom error message based on the error provided
	// we can implement the same thing for pinwheel
	// for Sora API errors we simply return the errorMessage and rener our back end teams error
	if (isFireBaseError) {
		const FirebaseErrorType =
			processedErrorMessage[processedErrorMessage.length - 1];
		return errorMessageHandler[processedErrorMessage[0]][FirebaseErrorType];
	}
	return errorMessage;
};

// this fn will turn a number like 1000 into 1k
// this fn will turn 2051233904.2800293 into 2.05b
export const numbroAveragedToDecimal = (num, toDecimalPoint = 3) =>
	numbro(num).format({ average: true, totalLength: toDecimalPoint });

export const convertMonthsToYears = (months, roundedToDecimal = 2) =>
	roundToDecimal(months / 12, roundedToDecimal) ?? months;
