import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation, generatePath } from 'react-router-dom';

// Our axios instance
import axiosInstance from 'services/API/API';

// Endpoints
import { userIncome } from 'shared/api-urls';

// Our Query keys
import {
	CLIENT_ONBOARDING_INCOME,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

// Our Routes
import { ADVISOR_WALLET_ROUTE, CLIENT_WALLET_OVERVIEW } from 'routes';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';

// Our Constants
import { INCOME_UPDATED } from 'shared/constants';

function useMutateSetIncome() {
	const navigate = useNavigate();
	const isFromWallet = useWasFromWallet();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();
	const queryClient = useQueryClient();
	const location = useLocation();

	const { state } = location;

	return useMutation(
		async ({ totalOtherAnnualIncome, otherIncomeId }) => {
			const payload = {
				compensationAmount: totalOtherAnnualIncome,
				compensationUnit: 'annually',
				compensationCurrency: 'USD'
			};

			if (state?.clientId) {
				payload.clientId = state?.clientId;
			}

			const apiMethodUsed = otherIncomeId ? 'patch' : 'post'; // PATCH if id passed. Otherwise POST without id

			const urlBasedOnIncomeId = `${userIncome}/${otherIncomeId ?? ''}`;

			const response = await axiosInstance[apiMethodUsed](
				urlBasedOnIncomeId,
				payload
			);

			const userIncomeData = response.data.data;

			// variable here is not needed but the AWAIT is.
			// stored in a variable for contextual purposes.

			if (!isFromWallet) {
				const makeOnboardingStatusUpdateResponse =
					await updateOnboardingStatus.mutate(INCOME_UPDATED);
			} else {
				await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
					refetchInactive: true
				});
			}

			return userIncomeData;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CLIENT_ONBOARDING_INCOME);
				if (isFromWallet) {
					if (state?.clientId) {
						navigate(
							generatePath(`${ADVISOR_WALLET_ROUTE}:clientId`, {
								clientId: state?.clientId
							}),
							{ replace: true }
						);
					} else {
						navigate(CLIENT_WALLET_OVERVIEW, { replace: true });
					}
				}
			}
		}
	);
}

export default useMutateSetIncome;
