import axiosInstance from 'services/API/API';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { walletOverviewUrl } from 'shared/api-urls';
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

const FIVE_MINUTES = 1000 * 60 * 5;

function useGetWalletOverview() {
	const { clientId } = useParams();

	const endpointBasedOnClientId = clientId
		? `${walletOverviewUrl}/${clientId}`
		: walletOverviewUrl;

	const DYNAMIC_WALLET_OVERVIEW_QUERY_KEY = clientId
		? [WALLET_OVERVIEW_DATA, clientId]
		: WALLET_OVERVIEW_DATA;

	return useQuery(
		DYNAMIC_WALLET_OVERVIEW_QUERY_KEY,
		async () => {
			const response = await axiosInstance.get(endpointBasedOnClientId);
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: FIVE_MINUTES
		}
	);
}

export default useGetWalletOverview;
