import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Typography, List, ListItem, ListItemButton } from '@mui/material';

// Icons
import AssessmentIcon from '@mui/icons-material/Assessment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';

function NavMenuAdvisor() {
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	return (
		<List>
			{/* Dashboard link */}
			<ListItem disablePadding onClick={() => navigate('/dashboard')}>
				<ListItemButton>
					<ListItemIcon sx={{ paddingLeft: 1 }}>
						<DashboardOutlinedIcon
							sx={{
								color: SoraTheme.palette.primary.white
							}}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							variant="subtitle1"
							sx={{ color: SoraTheme.palette.primary.white }}
						>
							Dashboard
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>

			{/* Loan Status link */}
			{/* <ListItem
				disablePadding
				onClick={() => navigate('/dashboard/loan-status')}
			>
				<ListItemButton>
					<ListItemIcon sx={{paddingLeft: 1}}>
						<img
							src={LoanStatusIcon}
							alt="Loan status icon"
							width="20"
							height="14"
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography variant="subtitle1">Loan Status</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem> */}

			{/* Query Engine */}
			<ListItem
				disablePadding
				onClick={() => navigate('/dashboard/report')}
			>
				<ListItemButton>
					<ListItemIcon sx={{ paddingLeft: 1 }}>
						<AssessmentIcon
							sx={{
								color: SoraTheme.palette.primary.white
							}}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							variant="subtitle1"
							sx={{ color: SoraTheme.palette.primary.white }}
						>
							Reports
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>

			{/* Analytics */}
			<ListItem
				disablePadding
				onClick={() => navigate('/dashboard/analytics')}
			>
				<ListItemButton>
					<ListItemIcon sx={{ paddingLeft: 1 }}>
						<BarChartIcon
							sx={{
								color: SoraTheme.palette.primary.white
							}}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							variant="subtitle1"
							sx={{
								color: SoraTheme.palette.primary.white,
								display: 'inline-block'
							}}
						>
							Analytics
						</Typography>

						<Typography
							variant="subtitle1"
							sx={{
								color: SoraTheme.palette.primary.lightGrey,
								display: 'inline-block',
								marginLeft: 1
							}}
						>
							(beta)
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>
		</List>
	);
}

export default NavMenuAdvisor;
