import { Grid } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Our Components
import BasicNavFrame from 'components/Frame/BasicNav';
import Offers from 'components/Offers/Offers';
import OffersPageHeading from 'components/Offers/OffersPageHeading';

// Query Keys
import { ROLE } from 'shared/query-keys';

// Constants
const SUPPORTED_OFFER_TYPES = ['new_loan', 'cash_out', 'refinance'];

function OffersPage({ OfferType }) {
	const queryClient = useQueryClient();
	const location = useLocation();

	const { clientData } = location.state;

	const role = queryClient.getQueryData(ROLE);

	const { firstName } = clientData;

	return (
		<BasicNavFrame backEnabled>
			<Grid item xs={12} sx={{ marginBottom: 6 }}>
				<OffersPageHeading OfferType={OfferType} />
			</Grid>
			<Grid item xs={11}>
				<Offers
					clientId={clientData?.clientId}
					offerType={OfferType}
					name={firstName}
					role={role}
				/>
			</Grid>
			<Grid item xs={1} />
		</BasicNavFrame>
	);
}

OffersPage.propTypes = {
	OfferType: PropTypes.oneOf(SUPPORTED_OFFER_TYPES).isRequired
};

export default OffersPage;
