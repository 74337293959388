import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Link, useParams } from 'react-router-dom';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import { TertiaryButton } from 'components/Button/Button';

// Our Utils
import { dollarFormatter } from 'shared/utils';

// Our Routes
import { INCOME_ROUTE } from 'routes';

function IncomeCard({ income }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const params = useParams();

	const clientId = params ?? {};

	const formattedIncome = dollarFormatter.format(income);

	return (
		<CardItem
			sx={{
				alignItems: 'center',
				height: 'inherit',
				marginBottom: 4,
				flexGrow: 1
			}}
			withoutButton
			cardHeading="Income"
		>
			<Box sx={{ flexGrow: 2 }}>
				<Typography
					variant="h5"
					color={primary.indigo}
					fontWeight={700}
					sx={{ marginTop: 1, marginBottom: 1 }}
				>
					{formattedIncome ?? 'NA'}
				</Typography>
			</Box>

			<Link
				to={INCOME_ROUTE}
				state={{ fromWallet: true, ...clientId }}
				style={{ width: 'fit-content', textDecoration: 'none' }}
			>
				<TertiaryButton sx={{ minWidth: 145, padding: 1.5 }}>
					Update
				</TertiaryButton>
			</Link>
		</CardItem>
	);
}

IncomeCard.propTypes = {
	income: PropTypes.number.isRequired
};

export default IncomeCard;
