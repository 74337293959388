import { v4 as uuidv4 } from 'uuid';

// Our Components
import ExportableReportBox from 'components/ExportableReport/ExportableReportBox';

// Our Utils
import { getExportableReportInsights } from 'components/ExportableReport/ExportableReportRuleProcessor';

function ExportableReportInsights({
	sortedLiabilities,
	clientFinancialDetails,
	headingText,
	withBorder
}) {
	const insights = getExportableReportInsights(
		sortedLiabilities,
		clientFinancialDetails
	);

	return (
		<ExportableReportBox
			withoutHeadingColon
			withBorder={withBorder}
			headingText={headingText}
			sx={{ marginBottom: 2 }}
		>
			<ul>
				{insights.map((currentInsight) => {
					const splitInsight = currentInsight.split(':');
					const insightType = splitInsight[0];

					// TODO @Daniel make this robust so that all items that fall past the slice get joined by a colon
					const restOfMessage =
						splitInsight.length > 2
							? splitInsight.slice(1).join(':')
							: splitInsight[1];

					return (
						<li
							key={uuidv4()}
							variant="body1"
							sx={{ marginTop: 1, marginBottom: 1 }}
						>
							<strong>{insightType}:</strong>
							{restOfMessage}
						</li>
					);
				})}
			</ul>
		</ExportableReportBox>
	);
}

export default ExportableReportInsights;
