// Our axios Instance
import axiosInstance from 'services/API/API';

import { useQueryClient, useMutation } from 'react-query';
import { SUCCESS_MESSAGE_DATA, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateSubscribeToNewsletter() {
	const queryClient = useQueryClient();

	return useMutation(
		(email) => {
			axiosInstance
				.post(
					'https://sheet.best/api/sheets/bfd3d998-efba-4544-81ae-1e6683550236',
					{
						'Submitted On': Date.now(),
						'Email Address': email.email,
						Tag: 'opted-in'
					},
					{ withCredentials: true }
				)
				.then((res) => res.data.data);
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Thank you for subscribing to the Sora newsletter.'
				);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
}

export default useMutateSubscribeToNewsletter;
