/* eslint-disable consistent-return */

function getOffersRowMapping(offerType) {
	if (offerType === 'refinance') {
		const refinanceRowMapping = [
			'totalLoanAmount',
			'monthlyMortgagePayment',
			'totalCostOfDebt',
			'closingCost',
			'interestRate',
			'remainingMonth',
			'soraScore'
		];

		return refinanceRowMapping;
	}
	if (offerType === 'new_loan') {
		const newLoanRowMapping = [
			'totalLoanAmount',
			'monthlyPayUsd',
			'totalCostOfDebt',
			'closingCost',
			'interestRate',
			'tenure',
			'soraScore'
		];

		return newLoanRowMapping;
	}

	if (offerType === 'cash_out') {
		const cashOutRowMapping = [
			'totalCashOutAmount',
			'monthlyMortgagePayment',
			'totalCostOfDebt',
			'interestRate',
			'remainingMonth',
			'soraScore'
		];

		return cashOutRowMapping;
	}
}

export default getOffersRowMapping;
