// TODO for hard sprint. we should just use the base URL and create a modify fn that will append that route to the end point
// e.g. API_URL + /user/login => 'https://sora-app-api.herokuapp.com/user/login'
export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const userLogin = `${API_URL}/user/login`;
export const userProfile = `${API_URL}/user/profile`;
export const userLogout = `${API_URL}/user/logout`;
export const onboardingStatusUrl = `${API_URL}/user/onboarding-status`;
export const userExecuteUrl = `${API_URL}/user/execute`;
export const userGetLoginTokenUrl = `${API_URL}/user/fbtoken`;

export const onboardUrl = `${API_URL}/onboard`;
export const fileUploadUrl = `${API_URL}/file`;
export const newHomeUrl = `${API_URL}/newhome`;
export const clientInvitation = `${API_URL}/client/invite`;
export const faClients = `${API_URL}/fa/v1/clients`;
export const faContactClient = `${API_URL}/fa/send-message`;
export const faLiabilitySummary = `${API_URL}/fa/summary`;
export const getFaSoraWalletUrl = `${API_URL}/fa/wallet`;
export const loanAppliedUrl = `${API_URL}/loanapplied`;
export const clientLiabilitiesPost = `${API_URL}/v1/onboard1`;
export const checkForCompany = `${API_URL}/user/check-domain?domain=`;
export const verifyClientInvitation = `${API_URL}/client/verify?inviteCode=`;
export const estimatedValueUrl = `${API_URL}/estimatedvalue`;

// advisor upload profile
export const uploadProfilePicture = `${API_URL}/user/upload-profile-pic`;
export const uploadCompanyLogo = `${API_URL}/user/upload-company-logo`;

// Employeer Related
export const verifyEmployeeUrl = `${API_URL}/verifyemployee`;

// Onboarding
// used for GET and POST calls
export const userIncome = `${API_URL}/user/income`;
export const userAssets = `${API_URL}/user/asset`;

// Advisor generate report
export const generateReport = `${API_URL}/client/get-query-report`;

// Methodfi
export const getMethodfiToken = `${API_URL}/mfi-connect`;
export const getMethodfiLiabilities = `${API_URL}/mfi-liabilities/`;

// PinWheel
export const pinwheelTokenUrl = `${API_URL}/token`;
export const pinwheelAccountConnectUrl = `${API_URL}/pinwheel`;
export const clientIncomeUrl = `${API_URL}/income/manualupload`;

// Offers
export const cashoutOfferUrl = `${API_URL}/user/offer/v1/cashout`;
export const newOfferUrl = `${API_URL}/user/offer/new`;
export const refinanceOfferUrl = `${API_URL}/user/offer/v1/refinance`;

// Wallet page
export const clientWalletUrl = `${API_URL}/client/wallet`;
export const walletLiabilityURL = `${API_URL}/v1/wallet/liability/`;
export const walletOverviewUrl = `${API_URL}/wallet/overview`;

// User
export const getUserLiabilitiesUrl = `${API_URL}/v1/user/liability/`;
export const getUserEmploymentDataUrl = `${API_URL}/user/employment`;
export const userOnboardingStatus = `${API_URL}/v1/user/onboarding-status/`;

// Mask mode, impersonating another user account
export const maskAsAnotherUser = `${API_URL}/v1/info/super`;

// Analytics
export const analyticsOfferUrl = `${API_URL}/analytics/offer`;
export const analyticsLiabilityUrl = `${API_URL}/analytics/liability`;
