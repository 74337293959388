import axios from 'axios';

import { useQuery } from 'react-query';

// React Query Keys
import { NEWSFEED_ITEMS_DATA } from 'shared/query-keys';

// if select is passed. PLEASE pass a fn
function useGetNewsfeedItems() {
	return useQuery(
		NEWSFEED_ITEMS_DATA,
		async () => {
			const NewsfeedResponse = await axios.get(
				'https://sheet.best/api/sheets/2321d7cb-e9ce-440f-8e01-dff6ca14d8fc?_raw=1'
			);
			return NewsfeedResponse.data;
		}
		// ,
		// {
		// 	onSuccess: () => {
		// I think we'll want to cache the newsfeed in react query I want to talk to look up the best way to do this to limit the requests to the spreadsheet.
		// }
	);
}

export default useGetNewsfeedItems;
