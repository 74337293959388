import { useTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// our Component
import HighlightCarousel from 'components/FADashboard/Liabilities/HighlightCarousel';
import LiabilitiesTable from 'components/FADashboard/Liabilities/LiabilitiesTable';
import Loader from 'components/Loader';
import PageFrame from 'components/Frame/BasicNavAndFeed';
import { TertiaryButton } from 'components/Button/Button';
import TableHeadingAndSearch from 'components/FADashboard/TableHeadingAndSearch';

// our Hooks
import useGetFaSummary from 'hooks/useGetFaSummary';

// utils
import { extractSoraScore } from 'shared/utils';
import { INVITE_PAGE } from 'routes/index';

function FADashboardPage() {
	const SoraTheme = useTheme();
	const navigate = useNavigate();
	const [filterLiabilities, setFilterLiabilities] = useState('');
	const { data, isLoading, isSuccess } = useGetFaSummary(filterLiabilities);

	if (isLoading) {
		return (
			<PageFrame>
				<Helmet>
					<title>Financial advisor dashboard</title>
				</Helmet>

				<Grid item xs={6}>
					<Typography
						variant="h4"
						gutterBottom
						sx={{
							fontWeight: 700,
							color: SoraTheme.palette.primary.indigo,
							marginTop: 6
						}}
					>
						Liability Dashboard
					</Typography>
				</Grid>

				<Grid
					container
					item
					xs={6}
					sx={{
						alignContent: 'flex-start',
						justifyContent: 'flex-end',
						paddingRight: 2,
						marginBottom: 1
					}}
				>
					<TertiaryButton
						sx={{ marginTop: 6 }}
						startIcon={<AddIcon />}
						onClick={() => navigate(INVITE_PAGE)}
					>
						Add Client
					</TertiaryButton>
				</Grid>

				<Grid
					container
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Loader size={70} />
				</Grid>
			</PageFrame>
		);
	}

	if (isSuccess) {
		const {
			avgSoraScore,
			soraScore,
			summaryLiability,
			pendingOnboardClient,
			untappedSavings,
			upsidePotential,
			totalLoans
		} = data.data; // TODO tidy this up to match other areas

		// Sorting the liabilities by highest amount of untapped savings
		const sortLiabilitiesByUntappedSavingsAmount = summaryLiability
			.sort((a, b) => a.untappedSavings - b.untappedSavings)
			.reverse();

		const formattedAvgSoraScore =
			avgSoraScore && extractSoraScore(avgSoraScore);

		const avgSoraScoreToRender =
			soraScore === 0 ? 'NA' : formattedAvgSoraScore;

		return (
			<PageFrame>
				<Helmet>
					<title>Financial advisor dashboard</title>
				</Helmet>

				{/* Child Grid container for Your highlights */}

				<Grid item xs={8} sx={{ marginTop: 4 }}>
					<Typography
						variant="h4"
						gutterBottom
						sx={{
							fontWeight: 700,
							color: SoraTheme.palette.primary.indigo
						}}
					>
						Liability Dashboard
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={4}
					sx={{
						alignContent: 'flex-start',
						justifyContent: 'flex-end',
						paddingRight: 2,
						marginTop: 4,
						marginBottom: 4
					}}
				>
					<TertiaryButton
						sx={{ marginTop: 1 }}
						startIcon={<AddIcon />}
						onClick={() => navigate(INVITE_PAGE)}
					>
						Add Client
					</TertiaryButton>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{ marginBottom: 2, overflowX: 'hidden' }}
				>
					<HighlightCarousel
						averageSoraScore={avgSoraScoreToRender}
						pendingClients={pendingOnboardClient}
						untappedSavings={untappedSavings}
						upsidePotential={upsidePotential}
						totalLoansUnderManagement={totalLoans}
					/>
				</Grid>
				<Grid item xs={12} sx={{ marginBottom: 1 }}>
					<TableHeadingAndSearch
						deBounceCallBack={setFilterLiabilities}
						tableHeading="Clients"
					/>
				</Grid>

				{/* if the grid is a container everything within it should be a Grid Item */}
				<Grid container item xs={12}>
					<LiabilitiesTable
						liabilities={sortLiabilitiesByUntappedSavingsAmount}
					/>
				</Grid>
			</PageFrame>
		);
	}
}

export default FADashboardPage;
