import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as savePersonalLiabilityURL } from 'shared/api-urls';

// Our Routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Our query keys
import { USER_LIABILITIES, WALLET_OVERVIEW_DATA } from 'shared/query-keys';

function useMutateSavePersonalLiability() {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();

	const queryClient = useQueryClient();

	const isManualEntry = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);

	const urlForRequest = isManualEntry
		? savePersonalLiabilityURL
		: `${savePersonalLiabilityURL}${tradeLineId}`;

	return useMutation(async (personalLiabilityData) => {
		if (isManualEntry) {
			// manual entry is a post call
			const response = await axiosInstance.post(
				urlForRequest,
				personalLiabilityData
			);

			await queryClient.invalidateQueries(USER_LIABILITIES);
			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});

			return response.data;
		}

		// dynamic entries will be patch calls
		const response = await axiosInstance.patch(
			urlForRequest,
			personalLiabilityData
		);

		await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
			refetchInactive: true
		});

		return response.data;
	});
}

export default useMutateSavePersonalLiability;
