import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import Alert from 'components/Alert';
import BasicNav from 'components/Frame/BasicNav';
import ContactUserForm from 'components/FADashboard/Clients/ContactUserForm';
import LoanStatusClient from 'components/FADashboard/LoanStatus/LoanStatusClient';
import Loader from 'components/Loader';
import Modal from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';

// Hooks
import useGetLoanApplied from 'hooks/useGetLoanApplied';
import useGetProfile from 'hooks/useGetProfile';
import useMutateContactClient from 'hooks/useMutateContactClient';

// Query Keys
import { useNavigate } from 'react-router-dom';

function LoanStatusPage() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const navigate = useNavigate();

	// local State
	const [showModal, setShowModal] = useState(false);
	const [contactClientData, setContactClientData] = useState('');

	// Query State
	const {
		isLoading: isGetLoanAppliedLoading,
		isSuccess: isGetLoanAppliedSuccess,
		data: getLoanAppliedData
	} = useGetLoanApplied(); // the loans applied
	const {
		isLoading: isGetProfileLoading,
		isSuccess: isGetProfileSuccess,
		data: getProfileData
	} = useGetProfile();

	// mutation for contacting a client
	const contactClient = useMutateContactClient();

	const { isError: contactClientError, isSuccess: contactClientSuccess } =
		contactClient;

	const handleContactSubmission = ({ clientId, messageText }) => {
		contactClient.mutate({ clientId, messageText });
		setContactClientData('');
		setShowModal(false);
	};

	const handleContactClientClick = ({ name, clientId }) => {
		setContactClientData({ name, clientId });
		setShowModal(true);
	};

	const renderLoanStatusCards = (loans) =>
		loans.map((loanInfo) => {
			const { clientId } = loanInfo;
			return (
				<LoanStatusClient
					key={uuidv4()}
					loanInformation={loanInfo}
					clientId={clientId}
					contactClientHandler={handleContactClientClick}
				/>
			);
		});

	if (isGetLoanAppliedLoading || isGetProfileLoading)
		return (
			<BasicNav>
				<Helmet>
					<title>Loan Status</title>
				</Helmet>
				<Loader
					size={60}
					boxSX={{
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%'
					}}
				/>
			</BasicNav>
		);

	if (isGetLoanAppliedSuccess && isGetProfileSuccess) {
		const isClient = getProfileData.role === 'CLIENT';
		return (
			<BasicNav sx={{ height: '100vh' }}>
				<Helmet>
					<title>Loan Status</title>
				</Helmet>
				{contactClientSuccess && <Alert variant="success" />}
				{contactClientError && <Alert variant="error" />}
				<Grid item xs={12}>
					<Typography
						variant="h4"
						gutterBottom
						component="div"
						sx={{ color: primary.indigo, marginTop: 6 }}
					>
						Here are your
						{isClient ? ' ' : " Client's "}
						outstanding loan applications
					</Typography>
				</Grid>
				<Grid item xs={2}>
					{isClient && (
						<PrimaryButton
							onClick={() => navigate('/select-loan/new-loan')}
						>
							New Loan
						</PrimaryButton>
					)}
				</Grid>
				<Modal
					isOpen={showModal}
					handleClose={() => setShowModal(false)}
					title="Contact Client"
					subtitle="Edit the fields below to send a custom note to your client"
				>
					<ContactUserForm
						clientData={contactClientData}
						handleContactSubmission={handleContactSubmission}
					/>
				</Modal>
			</BasicNav>
		);
	}
}

export default LoanStatusPage;
