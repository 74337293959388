import { Box, Grid, Typography } from '@mui/material/index';
import {
	BarChart,
	Bar,
	Legend,
	XAxis,
	YAxis,
	Tooltip,
	ReferenceLine,
	ResponsiveContainer
} from 'recharts';

import { useMemo } from 'react';

// Our Theme
import SoraTheme from 'theme';

// Our Components
import ResponsiveTable from 'components/Table/ResponsiveTable';
import SoraToolTip from 'components/Tooltip/index';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';

function NetPresentValueTab({ analyticsNPVData }) {
	const netPresentValueTableRows = useMemo(() => {
		if (analyticsNPVData?.currentLoan && analyticsNPVData?.bestOffer) {
			const { currentLoan, bestOffer } = analyticsNPVData;
			return [
				[
					'Month When Current Loan Ends',
					currentLoan.remainingMonth,
					'NA'
				],
				createFormattedRowData(
					'Balance When Current Loan Ends',
					currentLoan.amortValueAtMonth,
					bestOffer.amortValueAtMonth
				),
				createFormattedRowData(
					'Monthly Payment Savings with Best Offer',
					currentLoan.monthlyPaymentSavings,
					bestOffer.monthlyPaymentSavings
				),
				['', '', ''],
				createFormattedRowData(
					'NPV of Balance (Best Offer)',
					' ',
					bestOffer.npvBalance
				),
				createFormattedRowData(
					'NPV of Monthly Payment Savings',
					' ',
					bestOffer.npvMonthlyPaymentSavings
				),
				['', '', ''],
				createFormattedRowData(
					'NPV of Refinance',
					'',
					analyticsNPVData?.netPresentValue
				)
			];
		}

		return [];
	}, [analyticsNPVData]);

	const graphData = useMemo(() => {
		if (analyticsNPVData) {
			const { bestOffer, netPresentValue } = analyticsNPVData;

			const { npvBalance, npvMonthlyPaymentSavings } = bestOffer;

			return [
				{
					name: 'NPV of Balance Outstanding',
					npvBalance: Math.floor(npvBalance)
				},
				{
					name: 'NPV of Monthly Payment Savings',
					npvMonthlyPaymentSavings: Math.floor(
						npvMonthlyPaymentSavings
					)
				},
				{
					name: 'NPV of Refinancing',
					netPresentValue: Math.floor(netPresentValue)
				}
			];
		}
		return [];
	}, [analyticsNPVData]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
				<Typography
					variant="h6"
					sx={{
						color: SoraTheme.palette.primary.soraBlue,
						marginBottom: 6
					}}
				>
					NPV of Refinancing
				</Typography>
				<SoraToolTip text="The net present value represents the present value of the future savings you will have by refinancing your mortgage to the new loan offer." />
			</Box>

			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					xl={6}
					sx={{
						marginBottom: 4,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<ResponsiveContainer width="90%" height={500}>
						<BarChart layout="vertical" data={graphData}>
							<Tooltip />
							<Legend />
							<XAxis
								type="number"
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>

							<YAxis type="category" dataKey="name" width={150} />

							<ReferenceLine x={0} stroke="black" />
							<Bar
								dataKey="npvBalance"
								fill="#efc683"
								stroke="black"
								minPointSize={100}
								name="NPV Of Balance Outstanding"
								label={{
									fill: 'black',
									formatter: (npvBalance) =>
										dollarFormatter.format(npvBalance ?? 0)
								}}
							/>
							<Bar
								dataKey="npvMonthlyPaymentSavings"
								fill="#83acef"
								stroke="black"
								minPointSize={100}
								name="NPV Of Monthly Payment Savings"
								label={{
									fill: 'black',
									formatter: (npvMonthlyPaymentSavings) =>
										dollarFormatter.format(
											npvMonthlyPaymentSavings ?? 0
										)
								}}
							/>
							<Bar
								dataKey="netPresentValue"
								fill="#83ef8f"
								stroke="black"
								minPointSize={100}
								name="NPV of Refinancing"
								label={{
									fill: 'black',
									formatter: (netPresentValue) =>
										dollarFormatter.format(
											netPresentValue ?? 0
										)
								}}
							/>
						</BarChart>
					</ResponsiveContainer>
				</Grid>

				<Grid item xs={12} xl={6} sx={{ marginBottom: 4 }}>
					<ResponsiveTable
						makeLastBold
						columns={[
							'NPV Calculation',
							'Current Loan',
							'Best Offer'
						]}
						rows={netPresentValueTableRows}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default NetPresentValueTab;
