import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import CreditScoreBar from 'components/SoraWallet/Cards/creditScoreBar/';
import ModalBox from 'components/Modal/Modal';

function getCreditScoreText(creditScore) {
	// 300-579 Poor
	// 580-669 Fair
	// 670-739 Good
	// 740-799 Very Good
	// 800+ Excellent
	if (creditScore <= 579) return 'Poor';
	if (creditScore > 579 && creditScore < 670) return 'Fair';
	if (creditScore >= 670 && creditScore < 740) return 'Good';
	if (creditScore >= 740 && creditScore < 800) return 'Very Good';
	if (creditScore >= 800) return 'Excellent';
	return 'Good'; // if we aren't given a number for some reason Good is rendered
}

function getCreditScoreArrowIndex(creditScore) {
	// this function returns an index based on credit score
	if (creditScore <= 579) return 0;
	if (creditScore > 579 && creditScore < 670) return 1;
	if (creditScore >= 670 && creditScore < 740) return 2;
	if (creditScore >= 740 && creditScore < 800) return 3;
	if (creditScore >= 800) return 4;
	return 2; // if we aren't given a number for some reason the arrow points at Good
}

function CreditScoreCard({ creditScore }) {
	const SoraTheme = useTheme();
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const { primary } = SoraTheme.palette;

	const creditScoreText = useMemo(
		() => getCreditScoreText(creditScore),
		[creditScore]
	);

	const creditScoreColorIndex = useMemo(
		() => getCreditScoreArrowIndex(creditScore),
		[creditScore]
	);

	const colors = ['#B92D43', '#E49147', '#F8DB4A', '#82C69A', '#387C5B'];

	const colorPills = useMemo(
		() =>
			colors.map((color, index) => (
				<CreditScoreBar
					key={uuidv4()}
					color={color}
					withArrow={index === creditScoreColorIndex}
				/>
			)),
		[creditScore]
	);

	return (
		<CardItem
			buttonText="Learn More"
			onClickHandler={handleOpen}
			buttonSx={{ justifySelf: 'flex-end' }}
			sx={{ flexDirection: 'column', alignItems: 'center' }}
			cardHeading="Credit Score"
		>
			<Typography
				variant="h5"
				color={primary.indigo}
				fontWeight={700}
				sx={{ marginTop: 2 }}
			>
				{creditScore}
			</Typography>
			<ModalBox
				isOpen={isOpen}
				handleClose={handleClose}
				title="Credit Score"
			>
				A credit score predicts how likely you are to pay back a loan on
				time. Companies use a mathematical formula—called a scoring
				model—to create your credit score from the information in your
				credit report. It is important to know that you do not have just
				“one” credit score and there are many credit scores available to
				you as well as to lenders. Any credit score depends on the data
				used to calculate it, and may differ depending on the scoring
				model, the source of your credit history, the type of loan
				product, and even the day when it was calculated.
			</ModalBox>
			<Box
				sx={{
					flexGrow: 2,
					width: '100%',
					textAlign: 'center'
				}}
			>
				<Box
					sx={{
						width: '100%',
						height: 50,
						display: 'flex',
						justifyContent: 'space-evenly',
						alignItems: 'flex-end'
					}}
				>
					<Typography variant="subtitle1" fontSize={10}>
						300
					</Typography>
					{colorPills}
					<Typography variant="subtitle1" fontSize={10}>
						850
					</Typography>
				</Box>
				<Typography variant="body1" sx={{ marginTop: 1 }}>
					{creditScoreText}
				</Typography>
			</Box>
		</CardItem>
	);
}

CreditScoreCard.propTypes = {
	creditScore: PropTypes.number.isRequired
};

export default CreditScoreCard;
