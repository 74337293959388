import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our component
import { PrimaryButton } from 'components/Button/Button';

// Our Routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes/index';

function ZeroLiabilities({ isClient }) {
	const navigate = useNavigate();

	return (
		<Grid item xs={12}>
			<Box sx={{ marginTop: 4, height: '45vh' }}>
				{isClient && (
					<>
						<Typography
							variant="body1"
							gutterBottom
							sx={{ marginBottom: 2 }}
						>
							You have not added any Mortgage liabilities Click
							below to get started
						</Typography>
						<PrimaryButton
							onClick={() =>
								navigate(LIABILITY_ADDITIONAL_ROUTE, {
									fromWallet: true
								})
							}
						>
							Add Liabilities
						</PrimaryButton>
					</>
				)}
				{!isClient && (
					<Typography
						variant="body1"
						gutterBottom
						sx={{ marginBottom: 2 }}
					>
						This client has no Liabilities. Please contact them
					</Typography>
				)}
			</Box>
		</Grid>
	);
}

export default ZeroLiabilities;
