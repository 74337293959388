import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function LoanStatusItem({ heading, data, isAddress, children }) {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography
					variant="p"
					gutterBottom
					sx={{ fontWeight: 'bold' }}
				>
					{heading}
				</Typography>

				<Typography variant="p" gutterBottom>
					{data}
				</Typography>
				{isAddress && children}
			</Box>
		</Box>
	);
}

LoanStatusItem.defaultProps = {
	isAddress: false
};

LoanStatusItem.propTypes = {
	heading: PropTypes.string.isRequired,
	data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	isAddress: PropTypes.bool
};

export default LoanStatusItem;
