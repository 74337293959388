import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// Our Utils
import {
	convertMonthsToYears,
	dollarFormatter,
	extractSoraScore,
	roundToDecimal
} from 'shared/utils';

// Our Constants
import { ACCEPTED_OFFER_KEYS } from 'shared/constants';

// helper function that formats offer value
const formatOfferValueBasedOnKey = (currentOfferKey, currentOfferData) => {
	if (currentOfferKey === undefined || currentOfferData === null) return 'NA';
	if (currentOfferKey === 'soraScore')
		return extractSoraScore(currentOfferData);
	if (currentOfferKey === 'tenure') {
		const tmpSplit = currentOfferData.split('-');
		return `${tmpSplit[0]} years`;
	}
	if (currentOfferKey === 'interestRate')
		return roundToDecimal(currentOfferData, 2);
	if (currentOfferKey === 'remainingMonth')
		return `${convertMonthsToYears(currentOfferData, 0)} Years`;

	// most offer keys refer to a value that is numeric and is currency
	// the default for keys is to format them as currency in USD
	return dollarFormatter.format(currentOfferData);
};

// helper function that provides any suffix styling to a value
const getOfferValueSuffix = (currentOfferKey) => {
	if (currentOfferKey === 'interestRate') return '%';
	if (
		currentOfferKey === 'monthlyPayUsd' ||
		currentOfferKey === 'monthlyMortgagePayment'
	)
		return '/mo';
	return '';
};

function OfferTableCell({
	currentOfferKey,
	currentOfferData,
	existingClientOffer
}) {
	const formattedOfferValue = formatOfferValueBasedOnKey(
		currentOfferKey,
		currentOfferData
	);

	const offerValueSuffix = getOfferValueSuffix(currentOfferKey);

	const shouldRenderExisitingClientOffer =
		existingClientOffer && currentOfferKey in existingClientOffer;

	const formattedExisitingOfferValue = shouldRenderExisitingClientOffer
		? formatOfferValueBasedOnKey(
				currentOfferKey,
				existingClientOffer[currentOfferKey]
		  )
		: '';

	const exisitingClientOfferValueSuffix = shouldRenderExisitingClientOffer
		? getOfferValueSuffix(currentOfferKey)
		: '';

	return (
		<Box
			key={uuidv4()}
			width="261.17px"
			textAlign="center"
			sx={{ boxSizing: 'border-box' }}
		>
			<Typography component="div" variant="subtitle1">
				{formattedOfferValue}
				{offerValueSuffix}
			</Typography>
			{shouldRenderExisitingClientOffer && (
				<Typography variant="caption">
					Current: {formattedExisitingOfferValue}
					{exisitingClientOfferValueSuffix}
				</Typography>
			)}
		</Box>
	);
}

OfferTableCell.propTypes = {
	currentOfferKey: PropTypes.oneOf(ACCEPTED_OFFER_KEYS).isRequired,
	currentOfferData: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired
};

export default OfferTableCell;
