import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// our Components
import { SecondaryButton } from 'components/Button/Button';

// Our assets
import UploadImg from 'assets/images/upload.png';

function FileDefaultMessage({ isDragActive, open }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	return (
		<>
			<img
				src={UploadImg}
				alt="upload cloud icon"
				style={{ marginRight: '12px' }}
			/>
			<Typography variant="subtitle1" marginTop={1}>
				Select a file or drag and drop here
			</Typography>
			{isDragActive ? (
				<Typography variant="body1" marginTop={2} sx={{ height: 66 }}>
					Drop your file here
				</Typography>
			) : (
				<Typography
					variant="caption"
					marginTop={2}
					color={primary.mediumEmphasis}
				>
					Any file type no more than 5MB
				</Typography>
			)}
		</>
	);
}

export default FileDefaultMessage;
