import { Box, Typography } from '@mui/material/index';

// Our Components
import SoraToolTip from 'components/Tooltip/index';

function StepBox({ enableToolTip, isReady, titleText, toolTipText, children }) {
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				paddingTop: 2,
				paddingLeft: 4,
				paddingRight: 1,
				paddingBottom: 2,
				height: 89,
				opacity: isReady ? 1 : 0.5
			}}
		>
			<Box
				sx={{
					flexBasis: 250,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start'
				}}
			>
				<Typography variant="h6" sx={{ marginRight: 1 }}>
					{titleText}
				</Typography>
				{enableToolTip && (
					<SoraToolTip
						text={toolTipText}
						toolTipSx={{
							cursor: 'pointer',
							marginTop: 0
						}}
						placement="right"
					/>
				)}
			</Box>

			<Box sx={{ flexGrow: 2 }}>{children}</Box>
		</Box>
	);
}

export default StepBox;
