import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { toDate, format } from 'date-fns';
import PropTypes from 'prop-types';

import { dataIsValid } from 'shared/utils';

// Our Assets
import SoraLogoBlack from 'assets/images/SoraLogoBlack.svg';

function ExportableReportHeading({ advisor }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const today = toDate(new Date());
	const formattedDate = format(today, 'MMMM dd, yyyy');

	const { name, companyLogoUrl } = advisor;

	const companyLogo =
		dataIsValid(companyLogoUrl) && companyLogoUrl !== ''
			? companyLogoUrl
			: SoraLogoBlack;

	return (
		<Box
			sx={{
				flexGrow: 2,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'flex-end'
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography
					variant="h4"
					sx={{
						color: primary.soraMariner,
						textDecoration: 'underline'
					}}
				>
					{name}
				</Typography>
				<Typography variant="h5" sx={{ color: primary.grey }}>
					{formattedDate}
				</Typography>
			</Box>

			<Box
				component="img"
				src={companyLogo}
				sx={{
					width: 300,
					height: 100,
					objectFit: 'contain'
				}}
			/>
		</Box>
	);
}

ExportableReportHeading.propTypes = {
	advisor: PropTypes.shape({
		company: PropTypes.string,
		companyLogoUrl: PropTypes.string,
		email: PropTypes.string,
		firstName: PropTypes.string,
		imageUrl: PropTypes.string,
		lastName: PropTypes.string,
		name: PropTypes.string
	}).isRequired
};

export default ExportableReportHeading;
