/**
 *
 * @param {number} creditScore
 * @returns {string} `Excellent | Good | Fair | Bad`
 */

function convertToCreditScoreWordFromValue(creditScore) {
	if (creditScore >= 720) return 'Excellent';
	if (creditScore >= 680) return 'Good';
	if (creditScore >= 640) return 'Fair';

	// anything < 640 is poor!
	return 'Poor';
}

export default convertToCreditScoreWordFromValue;
