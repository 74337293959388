// Valid TradeLine Types
export const ALL = 'ALL';
export const AUTO = 'AUTO';
export const MORTGAGE = 'MORTGAGE';
export const STUDENT = 'STUDENT';
export const PERSONAL = 'PERSONAL';
export const CREDITCARD = 'CREDITCARD';
export const HELOC = 'HELOC';
export const LOAN = 'LOAN';

// translated TradeLine Type used for display
export const MORTGAGE_TAB_TEXT = 'Home';

// Valid Onboard Update States
export const ASSETS_UPDATED = 'ASSETS_UPDATED';
export const INCOME_ACCOUNT_CONNECTED = 'INCOME_ACCOUNT_CONNECTED';
export const INCOME_UPDATED = 'INCOME_UPDATED';
export const LIABILITIES_UPDATED = 'LIABILITIES_UPDATED';
export const LIABILITY_ACCOUNT_CONNECTED = 'LIABILITY_ACCOUNT_CONNECTED';
export const REVIEW_COMPLETED = 'REVIEW_COMPLETED';
export const SIGNUP_COMPLETED = 'SIGNUP_COMPLETED';
// export const Unknown_Client_State = 'Unknown Client State' This is a possible item but it has not been clarified yet.

// Currently Accepted Offer Keys
export const ACCEPTED_OFFER_KEYS = [
	'closingCost',
	'interestRate',
	'monthlyPayUsd',
	'totalCashOutAmount',
	'totalCostOfDebt',
	'totalLoanAmount',
	'tenure',
	'lender',
	'soraScore',
	'cashOutPotential',
	'remainingMonth'
];
