// eslint-disable-next-line import/prefer-default-export
export function intercomUpdateUserInfo({
	email,
	firstName,
	lastName,
	phoneNumber
}) {
	if (email === undefined || email === null) return; // email is required
	const hasIntercom = window.Intercom !== undefined; // makes sure intercom exists
	if (hasIntercom) {
		const userInfo = { email };
		if (firstName && lastName) {
			userInfo.name = `${firstName} ${lastName}`; // name is used based on Intercom docs
		}
		if (phoneNumber) {
			userInfo.phone = phoneNumber; // phone is used based on Intercom docs
		}
		// window.Intercom is added by Google Tag Manager
		// Google Tag Manager installs the script on page load.
		window.Intercom('update', userInfo);
	}
}

// eslint-disable-next-line import/prefer-default-export
export function intercomSignout() {
	const hasIntercom = window.Intercom !== undefined;
	if (hasIntercom) {
		window.Intercom('shutdown');
	}
}

/*
Intercom Intro:
Intercom is an API integration that allows us to send messengers to customers and have them asked questions.
In essence is a BOT that acts a a messenger between customers and the Sora Team.

Intercom Docs:

    javascript API Methods - https://developers.intercom.com/installing-intercom/docs/intercom-javascript
    javascript API Attributes - https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects

*/
