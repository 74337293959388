import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function CreditScoreBar({ color, withArrow }) {
	const navigate = useNavigate();
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const pillTopMargin = withArrow ? 0 : '25px';

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-end',
				alignItems: 'center',
				height: 50,
				width: '14.29%'
			}}
		>
			{withArrow && <ArrowDropDownIcon width="25px" height="25px" />}

			<Box
				bgcolor={color}
				sx={{
					width: '100%',
					height: 10,
					borderRadius: 3,
					marginTop: pillTopMargin,
					marginBottom: 0.8
				}}
			/>
		</Box>
	);
}

export default CreditScoreBar;
