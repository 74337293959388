import { Grid } from '@mui/material';
import CommonPropTypes from 'shared/prop-types';

function BasicFrame({ children, sx }) {
	// A container for a basic 12 column grid
	return (
		<Grid container spacing={2} sx={sx}>
			{children}
		</Grid>
	);
}

BasicFrame.propTypes = {
	sx: CommonPropTypes
};

BasicFrame.defaultProps = {
	sx: {
		width: '100%'
	}
};

export default BasicFrame;
