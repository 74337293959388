import PropTypes from 'prop-types';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import CommonPropTypes from 'shared/prop-types';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';

function AccordionCard({ children, cardHeading, open, accordianSummarySX }) {
	const [expanded, setExpanded] = useState(open);

	const handleChange = () => {
		setExpanded(!expanded);
	};

	return (
		<Grid item sm={12} md={12} lg={12} xl={12}>
			<CardItem withoutButton>
				<Accordion
					expanded={expanded}
					elevation={0}
					onChange={handleChange}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel-content"
						id="panel-header"
						sx={{ padding: 0, ...accordianSummarySX }}
					>
						<Typography variant="h5">{cardHeading}</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: 0 }}>
						{children}
					</AccordionDetails>
				</Accordion>
			</CardItem>
		</Grid>
	);
}

AccordionCard.propTypes = {
	cardHeading: PropTypes.string.isRequired,
	open: PropTypes.bool,
	accordianSummarySX: CommonPropTypes.sx
};

AccordionCard.defaultProps = {
	open: false,
	accordianSummarySX: {}
};

export default AccordionCard;
