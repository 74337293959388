import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print/lib/index';

// Our components
import Alert from 'components/Alert';
import ExportableReport from 'components/ExportableReport/ExportableReport';
import { TertiaryButton } from 'components/Button/Button';
import ContactClientButton from 'components/SoraWallet/ContactClientButton';

// Our hooks
import useMutateContactClient from 'hooks/useMutateContactClient';

// Intended to be used for Sora Wallet and its the heading there...
// Wrapped in Grid container
function SoraWalletHeading({
	advisorName,
	clientName,
	contactClientData,
	isClient
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const contactSubmissionMutation = useMutateContactClient();

	const exportableReportRef = useRef();

	const handlePrint = useReactToPrint({
		documentTitle: `${clientName}'s_Report`,
		content: () => exportableReportRef.current
	});

	const { isError: contactClientError, isSuccess: contactClientSuccess } =
		contactSubmissionMutation;

	const isAdvisor = !isClient;

	const clientId = isAdvisor && contactClientData.clientId;

	return (
		<>
			{isAdvisor && contactClientSuccess && <Alert variant="success" />}
			{isAdvisor && contactClientError && <Alert variant="error" />}

			<Grid item xs={12} sx={{ marginTop: 5 }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 4,
						alignItems: 'flex-start',
						alignText: 'center'
					}}
				>
					<Typography
						variant="h4"
						gutterBottom
						color={primary.indigo}
					>
						{clientName}&lsquo;s Wallet
					</Typography>
					{isAdvisor && (
						<>
							<ContactClientButton
								clientName={clientName}
								advisorName={advisorName}
							/>
							<TertiaryButton
								sx={{ minHeight: 40 }}
								onClick={() => handlePrint()}
							>
								Export Report
							</TertiaryButton>
						</>
					)}
				</Box>
				<ExportableReport ref={exportableReportRef} />
			</Grid>
		</>
	);
}

SoraWalletHeading.propTypes = {
	clientName: PropTypes.string.isRequired,
	contactClientData: PropTypes.shape({
		clientId: PropTypes.number, // clientId is undefined for client
		name: PropTypes.string
	}).isRequired,
	isClient: PropTypes.bool.isRequired,
	advisorName: PropTypes.string.isRequired
};

export default SoraWalletHeading;
