import { dataIsValid } from 'shared/utils';

/**
 *
 * @param {Array<Object>} liabilities
 * @param {Number} annualIncome
 * @returns {Number}
 */

function calculateDTI(liabilities, annualIncome) {
	const roundedMonthlyIncome = Math.round(annualIncome / 12);
	if (annualIncome === 0 || roundedMonthlyIncome === 0) return 0;
	const filtered = liabilities.map(({ monthlyPay }) => ({ monthlyPay }));

	const sumOfLiabilities = filtered.reduce((prev, curr) => {
		const { monthlyPay } = curr;

		// fall back
		if (!dataIsValid(monthlyPay)) return prev;

		return prev + monthlyPay;
	}, 0);

	const roundedDTI = Math.floor(
		(sumOfLiabilities / roundedMonthlyIncome) * 100
	);

	return roundedDTI;
}

export default calculateDTI;
