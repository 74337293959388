import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Box, Grid, Typography } from '@mui/material';
import {
	getAuth,
	RecaptchaVerifier,
	signInWithPhoneNumber
} from 'firebase/auth';

// Our Components
import SoraTheme from 'theme';
import { PrimaryButton } from 'components/Button/Button';
import TextInput from 'components/Input/TextInput';

import { LOGIN_ROUTE } from 'routes/index';
import { FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

export function OTPForm() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [phoneNumber, setPhoneNumber] = useState('');
	const [otp, setOTP] = useState('');
	const [code, setCode] = useState('');
	const [confirmResult, setConfirmResult] = useState('');
	const auth = getAuth();

	// Handle field change
	const handlePhoneNumberChange = (value) => {
		setPhoneNumber(value);
	};

	const handleOTPChange = (value) => {
		setOTP(value);
	};

	const triggerSendOTP = async () => {
		console.log('triggerSendOTP fired');
		const recaptcha = new RecaptchaVerifier(
			'recaptcha-container',
			{
				size: 'normal',
				callback: (response) => {
					// reCAPTCHA solved
					console.log('response', response);
					setCode(response);
					signInWithPhoneNumber(auth, phoneNumber, recaptcha).then(
						(confirmationResult) => {
							console.log(
								'confirmationResult',
								confirmationResult
							);
							setConfirmResult(confirmationResult);
							queryClient.setQueryData(
								FIREBASEUSERCREDENTIALS,
								confirmResult.verificationId
							);
						}
					);
				},
				'expired-callback': (error) => {
					// Response expired. Ask user to solve reCAPTCHA again.
					console.log('triggerSendOTP error', error);
				}
			},
			auth
		);
		recaptcha.render();
		setPhoneNumber('');
	};

	const verifyOTP = async () => {
		await confirmResult.confirm(otp);
		setOTP('');
		// navigate('/routing-user');
	};

	return (
		<>
			<Typography
				variant="h4"
				gutterBottom
				component="div"
				sx={{
					marginTop: 18,
					marginBottom: 4,
					color: SoraTheme.palette.primary.soraBlue
				}}
			>
				Phone Number Sign Up With a OTP
			</Typography>

			<Box noValidate autoComplete="off">
				<Grid item xs={8}>
					{/* Phone number */}
					<TextInput
						type="text"
						label="Phone number (+18004449999)"
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
						sx={{ marginBottom: 4 }}
					/>

					{/* Container for captcha */}
					<Box id="recaptcha-container" />

					{/* Buttons triggers the request to send an OTP to a phone number */}
					<PrimaryButton
						id="request-otp-button"
						onClick={triggerSendOTP}
						data-test="request_otp_button"
						type="submit"
						disabled={phoneNumber === ''}
						sx={{
							marginTop: 4,
							marginBottom: 6,
							display: 'block'
						}}
					>
						Request OTP
					</PrimaryButton>

					{/* OTP values goes in this input */}
					<TextInput
						type="text"
						label="One time password"
						value={otp}
						onChange={handleOTPChange}
					/>

					{/* Buttons triggers the send an the entered OTP to the signup function */}
					<PrimaryButton
						id="login-button"
						onClick={verifyOTP}
						data-test="login_button"
						type="submit"
						disabled={otp === '' || otp.length < 6}
						sx={{
							marginTop: 4,
							display: 'block',
							marginBottom: 4
						}}
					>
						Verify OTP
					</PrimaryButton>

					{/* Route to normal login page  */}
					<Link
						to={LOGIN_ROUTE}
						style={{
							color: SoraTheme.palette.primary.black,
							textDecorationColor: SoraTheme.palette.primary.black
						}}
					>
						<Typography
							variant="subtitle2"
							sx={{
								width: '100%'
							}}
						>
							Login using email address
						</Typography>
					</Link>
				</Grid>
			</Box>
		</>
	);
}

export default OTPForm;
