import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

// our components
import AssetsCard from 'components/SoraWallet/Cards/AssetsCard';
import CreditScoreCard from 'components/SoraWallet/Cards/CreditScoreCard';
import DebtToIncomeCard from 'components/SoraWallet/Cards/DebtToIncomeCard';
import FinancialAdvisorCard from 'components/SoraWallet/Cards/FinancialAdvisorCard';
import IncomeCard from 'components/SoraWallet/Cards/IncomeCard';
import Loader from 'components/Loader';
import NewLoanCard from 'components/SoraWallet/Cards/NewLoanCard';
import SoraScoreCard from 'components/SoraWallet/Cards/SoraScoreCard';
import SoraInsightsCarousel from 'components/Carousel/SoraInsightsCarousel';
import TotalLiabilitiesCard from 'components/SoraWallet/Cards/TotalLiabilitiesCard';
import UntappedSavingsCard from 'components/SoraWallet/Cards/UntappedSavingsCard';
import UpsidePotentialCard from 'components/SoraWallet/Cards/UpsidePotentialCard';

// Tool Tips
import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

// Our Query Keys
import { CLIENT_DATA } from 'shared/query-keys';

// our hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our Utils
import sortLiabilitiesByKey from 'shared/utils/walletOverview/sortByKey';
import { getExportableReportInsights } from 'components/ExportableReport/ExportableReportRuleProcessor';

function SoraWalletOverview({ isClient }) {
	const pageTitle = isClient ? 'Client' : 'FA';
	const {
		isLoading,
		isSuccess,
		data: walletOverviewData
	} = useGetWalletOverview();

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet Overview</title>
				</Helmet>
				<Loader
					size={60}
					boxSX={{
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						height: '100%',
						width: '100%',
						marginBottom: 25
					}}
				/>
			</>
		);
	}

	if (isSuccess) {
		const {
			advisor,
			assetsAmount,
			annualIncome,
			creditScore,
			email,
			firstName,
			liability,
			lastName,
			soraScore,
			totalUntappedSavings,
			totalUpsidePotential
		} = walletOverviewData;

		const clientData = { email, firstName, lastName };

		const { total, liabilities } = liability;

		const queryClient = useQueryClient();

		queryClient.setQueryData(CLIENT_DATA, walletOverviewData);

		const sortedLiabilities = sortLiabilitiesByKey(
			liabilities,
			'outstandingBalance',
			true
		);

		const clientDataWithFinancialData = {
			assetsAmount,
			annualIncome,
			creditScore,
			...clientData
		};

		const soraWalletOverviewInsights = getExportableReportInsights(
			sortedLiabilities,
			clientDataWithFinancialData
		);

		return (
			<>
				<Helmet>
					<title>{pageTitle} Wallet Overview</title>
				</Helmet>

				<Grid
					container
					item
					xs={12}
					spacing={4}
					sx={{ paddingRight: 6 }}
				>
					<Grid item md={4} lg={4} xl={2}>
						<FinancialAdvisorCard
							advisorData={advisor}
							clientData={clientData}
						/>
					</Grid>

					<Grid item md={4} lg={4} xl={2}>
						<CreditScoreCard creditScore={creditScore} />
					</Grid>

					<Grid
						item
						md={4}
						lg={4}
						xl={2}
						sx={{ display: 'flex', flexDirection: 'column' }}
					>
						{/* Half Cards */}
						<IncomeCard income={annualIncome} />
						<AssetsCard assets={assetsAmount} />
					</Grid>

					<Grid item md={4} lg={4} xl={2}>
						<DebtToIncomeCard
							annualIncome={annualIncome}
							liabilities={liabilities}
						/>
					</Grid>

					<Grid item md={4} lg={4} xl={2}>
						<SoraScoreCard soraScore={soraScore} />
					</Grid>

					<Grid item md={4} lg={4} xl={2}>
						<NewLoanCard />
					</Grid>

					<UpsidePotentialCard
						heading="Total Upside Potential"
						calculatedValue={totalUpsidePotential}
						tooltTipText={WalletToolTips.upsidePotential}
					/>

					<UntappedSavingsCard
						heading="Total Untapped Savings"
						calculatedValue={totalUntappedSavings}
						tooltTipText={WalletToolTips.untappedSavings}
						withoutButton
					/>

					<Grid item xs={12}>
						<SoraInsightsCarousel
							clientData={clientDataWithFinancialData}
							insights={soraWalletOverviewInsights}
							isClient={isClient}
						/>
					</Grid>

					<Grid item md={12} lg={12}>
						<TotalLiabilitiesCard
							totalLiabilities={total}
							liabilities={liabilities}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
}

SoraWalletOverview.propTypes = {
	isClient: PropTypes.bool.isRequired
};

export default SoraWalletOverview;
