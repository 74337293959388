import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { isDate } from 'validator';
import { useMemo } from 'react';

// Our Components
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/GetFullAddressPartialForm';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import TextInput from 'components/Input/TextInput';

// Text Input types
import { NUMBER, TEXT } from 'components/Input/Types';

// Constants
import CitizenStatusOptions from 'pages/Client/Onboarding/Review/CitizenStatusOptions.json';
import EducationLevelOptions from 'pages/Client/Onboarding/Review/EducationLevelOptions.json';
import EmploymentStatusOptions from 'pages/Client/Onboarding/Review/EmploymentStatusOptions.json';
import VeteranStatusOptions from 'pages/Client/Onboarding/Review/VeteranStatusOptions.json';
import US_STATES from 'pages/Client/Onboarding/US_STATES.json';

function ReviewProfileForm({
	disableAutoComplete,
	setDisableAutoComplete,
	withDisabled,
	firstName,
	setFirstName,
	lastName,
	setLastName,
	dob,
	setDOB,
	citizenshipType,
	setCitizenshipType,
	isVeteran,
	setIsVeteran,
	educationRating,
	setEducationRating,
	employmentStatus,
	setEmploymentStatus,
	addressLine1,
	setAddressLine1,
	addressLine2,
	setAddressLine2,
	city,
	setCity,
	state,
	setState,
	zipCode,
	setZipCode,
	creditScore,
	setCreditScore,
	monthlyRentPayment,
	setMonthlyRentPayment,
	employer,
	setEmployer
}) {
	const opacity = withDisabled ? 0.6 : 1;
	const disabledSubLabel = withDisabled ? 'Disabled' : '';

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	return (
		<>
			{/* ROW 1 */}
			<Grid item xs={5}>
				<TextInput
					disabled={withDisabled}
					label="First name"
					value={firstName}
					onChange={setFirstName}
					type={TEXT}
					sx={{ opacity }}
					subLabel={disabledSubLabel}
				/>
			</Grid>

			<Grid item xs={5}>
				<TextInput
					disabled={withDisabled}
					label="Last name"
					value={lastName}
					onChange={setLastName}
					type={TEXT}
					sx={{ marginBottom: 4, opacity }}
					subLabel={disabledSubLabel}
				/>
			</Grid>
			<Grid item xs={2} />

			{/* ROW 2 */}
			<Grid item xs={5}>
				<Dropdown
					items={CitizenStatusOptions}
					selected={citizenshipType}
					onChange={setCitizenshipType}
					variant="outlined"
					label="Citizenship status"
					sx={{ marginBottom: 4 }}
				/>
			</Grid>

			<Grid item xs={5}>
				<StandardDatePicker
					disabled={withDisabled}
					id="dob"
					label="Date of birth"
					helperText={
						withDisabled ? 'Disabled' : 'Enter your birth date'
					}
					labelSx={{ opacity }}
					onChange={setDOB}
					value={dob}
					error={dob === null || !isValidDate}
					errorText="Date of birth needs to be a valid date"
					inputProps={{
						tabIndex: '3'
					}}
					sx={{ opacity }}
				/>
			</Grid>

			<Grid item xs={2} />

			{/* Address Rows */}
			<GetFullAddressPartialForm
				addressLine1={addressLine1}
				addressLine2={addressLine2}
				city={city}
				state={state}
				zipCode={zipCode}
				setAddressLine1={setAddressLine1}
				setAddressLine2={setAddressLine2}
				setCity={setCity}
				setState={setState}
				setZipCode={setZipCode}
				disableAutoComplete={disableAutoComplete}
				setDisableAutoComplete={setDisableAutoComplete}
			/>
			<Grid item xs={7} />

			{/* ROW 4 */}
			<Grid item xs={5} sx={{ marginTop: 4 }}>
				<TextInput
					label="Credit score"
					value={creditScore}
					onChange={setCreditScore}
					type={NUMBER}
				/>
			</Grid>

			<Grid item xs={5} sx={{ marginTop: 4 }}>
				<CurrencyTextInput
					label="Monthly rent payment"
					value={monthlyRentPayment}
					onChange={setMonthlyRentPayment}
					helperText="If applicable. Optional"
				/>
			</Grid>

			<Grid item xs={2} />

			{/* ROW 5 */}
			<Grid item xs={5}>
				<Dropdown
					items={EmploymentStatusOptions}
					selected={employmentStatus}
					onChange={setEmploymentStatus}
					variant="outlined"
					label="Employment status"
					sx={{ marginBottom: 4 }}
				/>
			</Grid>

			<Grid item xs={5}>
				<Dropdown
					items={EducationLevelOptions}
					selected={educationRating}
					onChange={setEducationRating}
					variant="outlined"
					label="Highest degree obtained"
				/>
			</Grid>

			<Grid item xs={2} />

			<Grid item xs={5}>
				<TextInput
					label="Employer"
					value={employer}
					onChange={setEmployer}
					type={TEXT}
					subLabel="Optional."
				/>
			</Grid>

			{/* ROW 7 */}
			<Grid item xs={5} sx={{ marginBottom: 4 }}>
				<Dropdown
					items={VeteranStatusOptions}
					selected={isVeteran}
					onChange={setIsVeteran}
					variant="outlined"
					label="US Military Service?"
				/>
			</Grid>

			<Grid item xs={2} />
		</>
	);
}

ReviewProfileForm.propTypes = {
	withDisabled: PropTypes.bool,
	firstName: PropTypes.string.isRequired,
	setFirstName: PropTypes.func.isRequired,
	lastName: PropTypes.string.isRequired,
	setLastName: PropTypes.func.isRequired,
	dob: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
		.isRequired,
	setDOB: PropTypes.func.isRequired,
	citizenshipType: PropTypes.string.isRequired,
	setCitizenshipType: PropTypes.func.isRequired,
	isVeteran: PropTypes.oneOf(['Yes', 'No', '']).isRequired,
	setIsVeteran: PropTypes.func.isRequired,
	educationRating: PropTypes.string.isRequired,
	setEducationRating: PropTypes.func.isRequired,
	employmentStatus: PropTypes.oneOf(EmploymentStatusOptions).isRequired,
	setEmploymentStatus: PropTypes.func.isRequired,
	addressLine1: PropTypes.string.isRequired,
	setAddressLine1: PropTypes.func.isRequired,
	addressLine2: PropTypes.string.isRequired,
	setAddressLine2: PropTypes.func.isRequired,
	city: PropTypes.string.isRequired,
	setCity: PropTypes.func.isRequired,
	state: PropTypes.oneOf([...US_STATES, '']).isRequired,
	setState: PropTypes.func.isRequired,
	zipCode: PropTypes.string.isRequired,
	setZipCode: PropTypes.func.isRequired,
	creditScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	setCreditScore: PropTypes.func.isRequired,
	monthlyRentPayment: PropTypes.string.isRequired,
	setMonthlyRentPayment: PropTypes.func.isRequired,
	employer: PropTypes.string.isRequired,
	setEmployer: PropTypes.func.isRequired
};

ReviewProfileForm.defaultProps = {
	withDisabled: true
};

export default ReviewProfileForm;
