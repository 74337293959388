import { Box, Typography } from '@mui/material';

// Our hooks
import useGetNewsfeedItems from 'hooks/useGetNewsfeedItems';

// Our components
import Loader from 'components/Loader/index';

// Our component
import NewsfeedItem from 'components/FADashboard/NewsfeedItem';

function Newsfeed() {
	const { isLoading, isSuccess, data: articles } = useGetNewsfeedItems();

	if (isLoading) return <Loader size={60} />;

	if (isSuccess) {
		return (
			<>
				{articles.map((article) => (
					<NewsfeedItem
						key={article.key}
						logo={article.imageKey}
						link={article.articleURL}
						newsHeading={article.headline}
					/>
				))}
			</>
		);
	}
}

export default Newsfeed;
