import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Layout from 'components/Frame/OnboardingFrame';
import { PrimaryButton } from 'components/Button/Button';
import Loader from 'components/Loader/index';

import { CLIENT_REVIEW_ROUTE } from 'routes/index';

// Utils
import { isSubmissionReady, dataIsValid } from 'shared/utils';

// Hooks
import useGetAssets from 'hooks/Onboarding/Assets/useGetAssets';
import useMutateSetAssetValue from 'hooks/Onboarding/Assets/useMutateSetAssets';
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';

function AssetsForm() {
	const { data, isSuccess, isError, isLoading } = useGetAssets();
	const isFromWallet = useWasFromWallet();
	const setUserAssets = useMutateSetAssetValue();
	const { isLoading: setAssetsIsLoading } = setUserAssets;
	const navigate = useNavigate();

	const [totalAssets, setTotalAssets] = useState(0);
	const [apiCallType, setApiCallType] = useState('');
	const [assetIdToUpdate, setAssetIdToUpdate] = useState('');

	const handleTotalAssetsChange = (value) => {
		setTotalAssets(value);
	};

	useEffect(() => {
		if (isSuccess) {
			if (!dataIsValid(data)) {
				setApiCallType('post');
			} else if (Array.isArray(data) && data.length < 1) {
				setApiCallType('post');
			} else if (Array.isArray(data)) {
				setApiCallType('patch');
				setTotalAssets(data[0].assetAmount);
				setAssetIdToUpdate(data[0].id);
			} else {
				setApiCallType('patch');
				setTotalAssets(data.assetAmount);
				setAssetIdToUpdate(data.id);
			}
		}
	}, [isSuccess]);

	const formValues = [totalAssets];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues),
		formValues
	);

	const submitTotalAssetsData = (event) => {
		event.preventDefault();

		setUserAssets.mutate(
			{ totalAssets, apiCallType, assetIdToUpdate },
			{
				onSuccess: () => {
					if (!isFromWallet) navigate(CLIENT_REVIEW_ROUTE);
				}
			}
		);
	};

	if (isLoading)
		return (
			<Layout
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto',
					marginTop: 20
				}}
			>
				<Helmet>
					<title>Assets</title>
				</Helmet>

				<Typography variant="h4" color="primary.soraBlue">
					Now, estimate your total assets
				</Typography>

				<Typography variant="body1" marginTop={2}>
					Estimate the value of your total assets, including cash and
					cash equivalents, checking and savings accounts, and other
					assets.
				</Typography>

				<Loader size={60} boxSX={{ marginTop: 10 }} />
			</Layout>
		);

	if (isError) {
		<Typography variant="body1">Error</Typography>;
	}

	if (isSuccess) {
		return (
			<Layout
				backEnabled
				progress={50}
				sx={{ height: '100vh', width: '100%', overflow: 'auto' }}
			>
				<Helmet>
					<title>Assets</title>
				</Helmet>

				<Typography
					variant="h4"
					color="primary.soraBlue"
					marginTop={20}
				>
					Now, estimate your total assets
				</Typography>

				<Typography variant="body1" marginTop={2}>
					Estimate the value of your total assets, including cash and
					cash equivalents, checking and savings accounts, and other
					assets.
				</Typography>

				<Box component="form" noValidate autoComplete="off">
					<Grid container columnSpacing={2} marginTop={4}>
						<Grid item xs={5}>
							<CurrencyTextInput
								label="Estimated value of your total assets"
								subLabel="Includes cash, checking and savings accounts, stocks, retirement accounts and other assets."
								value={totalAssets}
								onChange={handleTotalAssetsChange}
								inputProps={{ 'data-test': 'assets' }}
							/>
						</Grid>

						<Grid item xs={7} />

						<Grid item xs={12} sx={{ marginTop: 4 }}>
							{setAssetsIsLoading ? (
								<Loader />
							) : (
								<PrimaryButton
									data-test="next"
									disabled={!isFormReady}
									onClick={submitTotalAssetsData}
								>
									Next
								</PrimaryButton>
							)}
						</Grid>
					</Grid>
				</Box>
			</Layout>
		);
	}
}

export default AssetsForm;
