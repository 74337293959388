import { Grid, Typography, Link as ExternalLink } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';

// Our Components
import BasicNavFrame from 'components/Frame/BasicNav';
import Loader from 'components/Loader/index';
import SoraWalletHeading from 'components/SoraWallet/SoraWalletHeading';
import WalletDynamicTabs from 'components/SoraWallet/WalletDynamicTabs';

// Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Utils
import sortByTradelineType from 'shared/utils/walletOverview/sortByTradelineType';

// Constants
import { CREDITCARD } from 'shared/constants';

// Routes

import {
	ADVISOR_WALLET_LIABILITY,
	CLIENT_WALLET_OVERVIEW,
	CLIENT_WALLET_LIABILITY
} from 'routes';

function SoraWalletWrapper() {
	const location = useLocation();
	const navigate = useNavigate();

	const { pathname } = location;

	const isClient = !pathname.includes('advisor');
	const isAdvisor = !isClient;

	const {
		isError,
		isLoading,
		isSuccess,
		data: walletOverviewData
	} = useGetWalletOverview();

	const sortedLiabilitiesForDynamicTabs = useMemo(() => {
		if (isSuccess) {
			const { liabilities } = walletOverviewData.liability;

			const listOfLiabilitiesThatAreSorted =
				sortByTradelineType(liabilities);

			const filteredSortedLiabilities =
				listOfLiabilitiesThatAreSorted.filter(
					({ tradeLineType }) => tradeLineType !== CREDITCARD
				);
			return filteredSortedLiabilities;
		}
		return [];
	}, [walletOverviewData]);

	if (isError) {
		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet Overview</title>
				</Helmet>

				<Grid
					container
					item
					xs={12}
					sx={{
						flexDirection: 'column',
						gap: 2,
						alignItems: 'center',
						marginTop: 10
					}}
				>
					<Typography variant="h4">
						Whoops. Something is wrong!
					</Typography>
					<br />
					<Typography variant="h5">
						Rest assured Sora is working hard to resolve this!
					</Typography>

					<Typography variant="subtitle2" sx={{ marginTop: 4 }}>
						If you need immediate assistance please email Contact
						support{' '}
						<ExternalLink
							href="mailto:contact@sorafinance.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							contact@sorafinance.com
						</ExternalLink>{' '}
					</Typography>
				</Grid>
			</>
		);
	}
	if (isLoading) {
		return (
			<BasicNavFrame
				progress={0}
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto'
				}}
			>
				<Loader
					size={60}
					boxSX={{
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 20
					}}
				/>
			</BasicNavFrame>
		);
	}

	if (isSuccess) {
		const { clientId, firstName, advisor } = walletOverviewData;

		const advisorFirstName = advisor?.firstName;
		const advisorLastName = advisor?.lastName;
		const advisorFullName = `${`${advisorFirstName} ${advisorLastName}`}`;
		const contactClientData = { name: firstName, clientId } ?? {};

		const handleClick = (indexSelected, tradelineId) => {
			const isWalletOverviewRoute = indexSelected === 0;

			if (isWalletOverviewRoute) {
				if (isAdvisor) {
					navigate(`${ADVISOR_WALLET_LIABILITY}${clientId}`);
					return;
				}
				navigate(CLIENT_WALLET_OVERVIEW);
				return;
			}

			if (isClient) {
				navigate(`${CLIENT_WALLET_LIABILITY}${tradelineId}`);
				return;
			}

			// not a client
			navigate(
				`${ADVISOR_WALLET_LIABILITY}${clientId}/liability/${tradelineId}`
			);
		};

		return (
			<BasicNavFrame
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto'
				}}
			>
				<SoraWalletHeading
					contactClientData={contactClientData}
					clientName={firstName}
					isClient={isClient}
					advisorName={advisorFullName}
				/>

				<WalletDynamicTabs
					liabilityItems={sortedLiabilitiesForDynamicTabs}
					onChange={handleClick}
				/>

				<Outlet />
			</BasicNavFrame>
		);
	}
}

export default SoraWalletWrapper;
