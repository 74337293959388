import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Our Components
import DynamicTabs from 'components/Tabs/DynamicTabs';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { dataIsValid } from 'shared/utils';
import { MORTGAGE_TAB_TEXT } from 'shared/constants';

function getActiveTab(pathName, liabilityTabData, routeTradelineId) {
	const isWalletOverviewRoute = !pathName.includes('liability');
	if (isWalletOverviewRoute) return 0;

	const seekingIndex = liabilityTabData.findIndex(
		({ tradelineId }) => tradelineId === +routeTradelineId
	);

	return seekingIndex;
}

function WalletDynamicTabs({ onChange, liabilityItems }) {
	const { pathname } = useLocation();
	const { tradelineId: routeTradelineId } = useParams();

	const walletTabs = useMemo(
		() =>
			[{ type: 'Wallet Overview' }].concat(
				liabilityItems.map(({ tradeLineType, tradelineId, lender }) => {
					const isLenderInvalid = !dataIsValid(lender);

					if (isLenderInvalid) return { type: 'NA', tradelineId };

					const formattedLoanType = normalizeLoanType(tradeLineType);
					let ellipsisAppendedLenderString = '';

					// the lender names were not fitting in the tab space on the wallet overview page.
					// Therefore we're checking for a long lender and adding an ellipsis when required.
					if (lender.length > 15) {
						// pull off first 15 characters of the lender string
						const slicedLenderString = lender.slice(0, 20);
						// append ellipsis to the slicedLenderString
						ellipsisAppendedLenderString =
							slicedLenderString.concat('...');
					}

					return {
						type: `${
							formattedLoanType === 'Mortgage'
								? MORTGAGE_TAB_TEXT
								: formattedLoanType
						} (${
							lender.length > 15
								? ellipsisAppendedLenderString
								: lender
						})`,
						tradelineId
					};
				})
			),
		liabilityItems
	);

	const activeIndex = getActiveTab(pathname, walletTabs, routeTradelineId);

	const handleChange = (e, indexClicked) => {
		const liabilitySelected = walletTabs[indexClicked];
		const { tradelineId } = liabilitySelected;
		onChange(indexClicked, tradelineId);
	};

	return (
		<DynamicTabs
			tabItems={walletTabs}
			tabItemSX={{ whiteSpace: 'nowrap' }}
			sx={{ marginBottom: 4 }}
			value={activeIndex}
			variant="scrollable"
			handleChange={handleChange}
			toolTipText="Sora has your information and will alert you if we find a savings opportunity for this loan."
		/>
	);
}

WalletDynamicTabs.propTypes = {
	onChange: PropTypes.func
};

WalletDynamicTabs.defaultProps = {
	onChange: () => null
};

export default WalletDynamicTabs;
