import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

// Our Components
import BasicNavFrame from 'components/Frame/BasicNav';
import LiabilityOnboardTabs from 'components/Client/Onboarding/LiabilityOnboardTabs';
import Loader from 'components/Loader/index';
import Layout from 'components/Frame/OnboardingFrame';

// Routes
import { IDENTIFIED_LIABILITIES_ROUTE } from 'routes';

// Hooks
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';
import useGetAllUserLiabilities from 'hooks/clientOnboarding/useGetAllUserLiabilities';
import useGetSupportedLiabilities from 'hooks/clientOnboarding/useGetSupportedLiabilities';

function OnboardingWrapperLiability() {
	const location = useLocation();

	const { pathname } = location;

	const fromWallet = useWasFromWallet(location);

	const isPageWithDynamicTabs = pathname !== IDENTIFIED_LIABILITIES_ROUTE;

	const enableGetAllLiabilities = !fromWallet;

	const { isLoading, isSuccess, data } = useGetAllUserLiabilities(
		enableGetAllLiabilities
	);

	const filteredLiabilityList = useGetSupportedLiabilities(data, isSuccess);

	const magicRef = useRef();

	useEffect(() => {
		if (magicRef.current) {
			magicRef.current.scrollIntoView({ behavior: 'smooth' }, 500);
		}
	}, [pathname]);

	if (fromWallet) {
		return (
			<BasicNavFrame mainGridSX={{ paddingTop: 10 }}>
				<Outlet />
			</BasicNavFrame>
		);
	}

	if (isLoading) {
		return (
			<Layout
				backEnabled
				progress={0}
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto'
				}}
			>
				{isPageWithDynamicTabs && (
					<Loader
						size={60}
						boxSX={{ marginTop: 9, marginBottom: 9 }}
					/>
				)}
				<Outlet />
			</Layout>
		);
	}

	if (isSuccess) {
		return (
			<Layout
				backEnabled
				progress={0}
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto',
					paddingBottom: 30
				}}
			>
				<div ref={magicRef} style={{ width: 1, height: 1 }} />
				{isPageWithDynamicTabs && (
					<LiabilityOnboardTabs liabilities={filteredLiabilityList} />
				)}
				<Outlet />
			</Layout>
		);
	}
}

export default OnboardingWrapperLiability;
