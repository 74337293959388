import { Box, Typography } from '@mui/material';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@emotion/react';

// Our Components
import OffersColumnItem from 'components/Offers/OfferColumns/OffersColumnItem';

function OfferColumns({ offers, offerType, setIsModalOpen }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const renderOfferColumns = offers.map((offerData, index) => {
		const isSoraChoice = index === 0;
		const offerId = offerData.id;
		const { lenderLogoUrl } = offerData;
		if (isSoraChoice) {
			return (
				<Fragment key={uuidv4()}>
					<Box
						sx={{
							bgcolor: primary.indigo,
							display: 'flex',
							flexDirection: 'column',
							border: `1px solid ${primary.soraBlue}`,
							borderRadius: '10px',
							maxWidth: 200,
							maxHeight: 150,
							padding: 4,
							paddingTop: 2,
							paddingLeft: 2,
							gap: 1,
							boxSizing: 'border-box',
							alignItems: 'center',
							alignText: 'center',
							marginTop: 4,
							zIndex: 2,
							justifySelf: 'start'
						}}
					>
						<Typography
							variant="subtitle2"
							sx={{ color: primary.white, zIndex: 2 }}
						>
							*The lowest total cost of debt
						</Typography>
						<Typography
							variant="caption"
							sx={{ color: primary.white, zIndex: 2 }}
						>
							The Sora Choice is the loan with the lowest total
							cost
						</Typography>
					</Box>
					<OffersColumnItem
						isSoraChoice
						offerType={offerType}
						offerId={offerId}
						modalCallBack={setIsModalOpen}
						lenderLogoUrl={lenderLogoUrl}
					/>
				</Fragment>
			);
		}
		return (
			<OffersColumnItem
				key={uuidv4()}
				offerType={offerType}
				offerId={offerId}
				modalCallBack={setIsModalOpen}
				lenderLogoUrl={lenderLogoUrl}
			/>
		);
	});

	const distanceFromBottom = offerType === 'cash_out' ? 550 : 650;
	const OffersColumnItemsJustifyContent =
		offers.length >= 3 ? { justifyContent: 'space-between' } : { gap: 10 };

	return (
		<Box
			sx={{
				display: 'flex',
				zIndex: 2,
				position: 'relative',
				bottom: distanceFromBottom,
				...OffersColumnItemsJustifyContent
			}}
		>
			{renderOfferColumns}
		</Box>
	);
}

export default OfferColumns;
