import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import { useState, useMemo, useEffect } from 'react';

// Our Components
import AnalyticsTabs from 'pages/FADashboard/AnalyticsTabs';
import AnalyticsTable from 'components/Table/AnalyticsTable';
import AmortizationCard from 'components/Analytics/AmortizationCard';
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader/index';
import PageFrame from 'components/Frame/BasicNav';
import { PrimaryButton } from 'components/Button/Button';
import StepBox from 'components/Analytics/StepBox';
import RateInput from 'components/Client/Onboarding/RateInput';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useGetClientLiabilitiesAnalytics from 'hooks/analytics/useGetClientLiabilitiesForAnalytics';
import useMutateGetAnalyticsOffer from 'hooks/analytics/useMutateGetAnalyticsOffer';
import useMutateGetAmortizationTable from 'hooks/analytics/useMutateGetAmortizationTable';
import useMutateGetAnalyticsSavings from 'hooks/analytics/useMutateGetAnalyticsInterestSavings';
import useMutateGetAnalyticsNPV from 'hooks/analytics/useMutateGetAnalyticsNPV';
import useMutateGetAnalyticsUpsidePotential from 'hooks/analytics/useMutateGetAnalyticsUpsidePotential';

// Our utils
import { dataIsValid } from 'shared/utils';

// our constants
import { MORTGAGE } from 'shared/constants';

const createLiabilityLabels = (clientLiabilities) => {
	const isValidLiabilities =
		Array.isArray(clientLiabilities) && clientLiabilities.length > 0;

	if (isValidLiabilities) {
		return clientLiabilities.map(
			({ lender, tradelineId }) => `${lender} - ${tradelineId}`
		);
	}

	return ['No Liabilities Found'];
};

const stripTradelineId = (liabilityLabel) => {
	const isValidLabel =
		liabilityLabel !== '' && liabilityLabel !== 'No Liabilities Found';

	if (isValidLabel) {
		const splitLabel = liabilityLabel.split(' ');

		const tradeLineId = splitLabel[splitLabel.length - 1];

		return tradeLineId;
	}

	return null;
};

function Analytics() {
	const SoraTheme = useTheme();

	// Currently selected Tab
	const [tabPanelSelected, setTabPanelSelected] = useState(0);
	const [rate, setRate] = useState('6');

	// Client State
	const [selectedClient, setSelectedClient] = useState(null);
	const [clientSearchName, setClientSearchName] = useState('');
	const [selectedLiabilityData, setSelectedLiabilityData] = useState('');

	// Analytics Table State
	const [bestOfferBalance, setBestOfferBalance] = useState('');
	const [bestOfferInterestRate, setBestOfferInterestRate] = useState('');
	const [bestOfferTenure, setBestOfferTenure] = useState('');

	const { isLoading, isSuccess, data } = useGetClients('');

	const isSomeClientSelected = selectedClient ?? false;

	const enabledGatherLiabilitiesCall =
		isSuccess && dataIsValid(data) && !!isSomeClientSelected;

	const analyticsOffer = useMutateGetAnalyticsOffer();
	const analyticsAmortization = useMutateGetAmortizationTable();
	const analyticsSavings = useMutateGetAnalyticsSavings();
	const analyticsNPV = useMutateGetAnalyticsNPV();
	const analyticsUpsidePotential = useMutateGetAnalyticsUpsidePotential();

	const {
		isLoading: isAnalyticsOfferLoading,
		isSuccess: isAnalyticsOfferSuccess,
		data: analyticsOfferTableData
	} = analyticsOffer;

	const {
		data: analyticsAmortizationData,
		isSuccess: analyticsAmortizationIsSuccess,
		isLoading: analyticsAmortizationIsLoading
	} = analyticsAmortization;

	const {
		isLoading: isAnalyticsSavingLoading,
		isSuccess: isAnalyticsSavingSuccess,
		data: analyticsSavingData
	} = analyticsSavings;

	const {
		isLoading: isAnalyticsNPVLoading,
		isSuccess: isAnalyticsNPVSuccess,
		data: analyticsNPVData
	} = analyticsNPV;

	const {
		isLoading: isAnalyticsUpsidePotentialLoading,
		isSuccess: isAnalyticsUpsidePotentialSuccess,
		data: analyticsUpsidePotentialData
	} = analyticsUpsidePotential;

	const {
		isLoading: clientLiabilitiesIsLoading,
		data: clientLiabilitiesData,
		isError: clientLiabilitiesIsError,
		isSuccess: clientLiabilitiesIsSuccess
	} = useGetClientLiabilitiesAnalytics(
		selectedClient?.id ?? 0,
		enabledGatherLiabilitiesCall,
		(clientWalletOverviewData) => {
			const clientLiabilities = clientWalletOverviewData;

			if (dataIsValid(clientLiabilities))
				return clientLiabilities.filter(
					(currentClientLiability) =>
						currentClientLiability.tradeLineType === MORTGAGE
				);

			return [];
		}
	);

	const isLiabilityDataEmpty =
		clientLiabilitiesIsSuccess &&
		Array.isArray(clientLiabilitiesData) &&
		clientLiabilitiesData.length < 1;

	useEffect(() => {
		setSelectedLiabilityData('');
		setBestOfferBalance('');
		setBestOfferInterestRate('');
		setBestOfferTenure('');
		analyticsOffer.reset();
		analyticsAmortization.reset();
		analyticsSavings.reset();
		analyticsNPV.reset();
		analyticsUpsidePotential.reset();
	}, [selectedClient, clientLiabilitiesData]);

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfClients = useMemo(() => {
		if (isSuccess) {
			const clientList = data.map(({ fullName, clientId }) => ({
				name: fullName,
				id: clientId
			}));

			return clientList;
		}

		return [];
	}, [data]);

	const isPageReady =
		isSomeClientSelected &&
		!clientLiabilitiesIsLoading &&
		selectedLiabilityData !== '';

	const currentlySelectedClientLiabilitiesLabels = createLiabilityLabels(
		clientLiabilitiesData
	);

	const MemoizedAmortizationCard = useMemo(
		() =>
			isPageReady &&
			analyticsAmortizationIsSuccess && (
				<AmortizationCard
					analyticsAmortizationData={analyticsAmortizationData}
				/>
			),
		[analyticsAmortizationData, isPageReady]
	);

	const requestOffer = () => {
		const liabilityId = stripTradelineId(selectedLiabilityData);

		const createAssumptionArgs = () => {
			const isAssumptionOffer =
				bestOfferBalance !== '' &&
				bestOfferInterestRate !== '' &&
				bestOfferTenure !== '';
			if (isAssumptionOffer)
				return {
					assumptionOffer: {
						balance: bestOfferBalance,
						rate: bestOfferInterestRate,
						termInMonth: +bestOfferTenure * 12
					}
				};
			return {};
		};

		const additionalArgs = createAssumptionArgs();

		const analyticsRequestPayload = {
			liabilityId,
			expectedAnnualReturn: rate,
			...additionalArgs
		};

		analyticsOffer.mutate(analyticsRequestPayload, {
			onSuccess: (analyticsOfferData) => {
				const { bestOffer } = analyticsOfferData;

				const {
					balance: analyticsOfferBalance,
					interestRate: analyticsOfferInterestRate,
					remainingMonth: analyticsOfferTenure
				} = bestOffer;

				setBestOfferBalance(analyticsOfferBalance);
				setBestOfferInterestRate(`${analyticsOfferInterestRate}`);
				setBestOfferTenure(analyticsOfferTenure / 12);
			}
		});

		analyticsAmortization.mutate(analyticsRequestPayload);

		analyticsSavings.mutate(analyticsRequestPayload);

		analyticsNPV.mutate(analyticsRequestPayload);

		analyticsUpsidePotential.mutate(analyticsRequestPayload);
	};

	const handleClientSelect = (
		currentSelectedClient,
		updateSelectedClient
	) => {
		updateSelectedClient(currentSelectedClient);
		setSelectedClient(currentSelectedClient);
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedClient('');
		}
		setClientSearchName(incomingValue);
	};

	if (isLoading) {
		return (
			<PageFrame>
				<Helmet>
					<title>Analytics</title>
				</Helmet>

				<Typography
					variant="h4"
					gutterBottom
					sx={{
						fontWeight: '700',
						color: SoraTheme.palette.primary.indigo,
						marginTop: 6,
						marginBottom: 4
					}}
				>
					Analytics
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Skeleton
							variant="rectangular"
							height={350}
							sx={{ marginBottom: 4 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Skeleton
							variant="rectangular"
							height={350}
							sx={{ marginBottom: 4 }}
						/>
					</Grid>
				</Grid>
			</PageFrame>
		);
	}

	if (isSuccess) {
		return (
			<PageFrame>
				<Helmet>
					<title>Analytics</title>
				</Helmet>

				<Typography
					variant="h4"
					gutterBottom
					sx={{
						fontWeight: '700',
						color: SoraTheme.palette.primary.indigo,
						marginTop: 6
					}}
				>
					Analytics
				</Typography>

				<Typography
					variant="subtitle1"
					gutterBottom
					sx={{
						color: SoraTheme.palette.primary.grey,
						marginBottom: 4
					}}
				>
					(beta)
				</Typography>

				<Grid
					container
					component={Paper}
					elevation={4}
					sx={{ borderRadius: 8, minHeight: 400 }}
				>
					<Grid item xs={6}>
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingTop: 2
							}}
						>
							<StepBox
								enableToolTip
								toolTipText="Expected return defines the expected rate of return on any money that is reinvested from savings achieved when refinancing your mortgage."
								isReady
								stepNumber={1}
								titleText="Expected Return:"
							>
								<RateInput
									sx={{ flexGrow: 2 }}
									label={null}
									subLabel={null}
									rate={rate}
									setRate={setRate}
								/>
							</StepBox>

							<StepBox
								isReady
								stepNumber={2}
								titleText="Select Client:"
							>
								{isSuccess && listOfClients.length > 0 && (
									<AutocompleteTextinput
										autoCompleteSX={{
											flexGrow: 2
										}}
										forcePopupIcon
										getOptionLabel={({ name }) => name}
										helperText=""
										handleChange={handleClientChange}
										handleSelect={handleClientSelect}
										label=""
										value={clientSearchName}
										optionList={listOfClients}
										withoutFilter={false}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.name}
											</li>
										)}
									/>
								)}
								{isSuccess && listOfClients.length < 1 && (
									<Typography variant="body1">
										No clients were found. <br /> <br />
										Please add some or try again later.
									</Typography>
								)}
							</StepBox>

							<StepBox
								isReady={
									isSomeClientSelected &&
									!clientLiabilitiesIsLoading
								}
								stepNumber={3}
								titleText="Select Liabilities:"
							>
								{selectedClient === '' && (
									<Typography variant="body1">
										Please select a client.
									</Typography>
								)}

								{isSomeClientSelected &&
									clientLiabilitiesIsLoading && (
										<Loader
											boxSX={{
												width: '100%',
												justifyContent: 'center'
											}}
										/>
									)}

								{isSomeClientSelected &&
									!clientLiabilitiesIsLoading &&
									!isLiabilityDataEmpty && (
										<Dropdown
											sx={{ flexGrow: 2 }}
											items={
												currentlySelectedClientLiabilitiesLabels
											}
											onChange={setSelectedLiabilityData}
											selected={selectedLiabilityData}
										/>
									)}

								{isLiabilityDataEmpty && (
									<Typography variant="subtitle1">
										No Liabilities Found
									</Typography>
								)}
							</StepBox>
							<Box
								sx={{
									height: 89,
									display: 'flex',
									alignItems: 'center',
									paddingLeft: 4
								}}
							>
								<PrimaryButton
									onClick={requestOffer}
									isDisabled={
										!isSomeClientSelected ||
										selectedLiabilityData === '' ||
										clientLiabilitiesIsLoading ||
										selectedLiabilityData ===
											'No Liabilities Found'
									}
								>
									Generate Analyses
								</PrimaryButton>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={6}>
						<Box
							sx={{
								maxWidth: '100%',
								height: '100%',
								padding: 2
							}}
						>
							{isAnalyticsOfferLoading && (
								<Skeleton
									variant="rectangular"
									sx={{ height: 350, width: '95%' }}
								/>
							)}
							{isAnalyticsOfferSuccess &&
								selectedLiabilityData !== '' && (
									<AnalyticsTable
										analyticsOffer={analyticsOfferTableData}
										bestOfferBalance={bestOfferBalance}
										setBestOfferBalance={
											setBestOfferBalance
										}
										bestOfferInterestRate={
											bestOfferInterestRate
										}
										setBestOfferInterestRate={
											setBestOfferInterestRate
										}
										bestOfferTenure={bestOfferTenure}
										setBestOfferTenure={setBestOfferTenure}
									/>
								)}

							{analyticsOfferTableData === undefined &&
								!isAnalyticsOfferLoading && (
									<Box
										sx={{
											height: 390,
											width: '100%',
											paddingTop: 6,
											paddingLeft: 3,
											paddingRight: 3
										}}
									>
										<Typography variant="subtitle1">
											Use this analytics tool to compare
											different loan options on your
											mortgage.
											<br />
											<br />
											Our software will auto populate
											available offers that we see on our
											platform for your mortgage.
											<br />
											<br />
											However, you can also input your own
											loan offers into the software.
										</Typography>
									</Box>
								)}
						</Box>
					</Grid>
				</Grid>

				<Grid item xs={12} sx={{ marginBottom: 4 }}>
					{isPageReady &&
						isAnalyticsSavingSuccess &&
						isAnalyticsNPVSuccess &&
						isAnalyticsUpsidePotentialSuccess && (
							<AnalyticsTabs
								tabPanelSelected={tabPanelSelected}
								setTabPanelSelected={setTabPanelSelected}
								analyticsAmortizationData={
									analyticsAmortizationData
								}
								analyticsSavingData={analyticsSavingData}
								analyticsNPVData={analyticsNPVData}
								analyticsUpsidePotentialData={
									analyticsUpsidePotentialData
								}
							/>
						)}

					{(isAnalyticsSavingLoading ||
						isAnalyticsUpsidePotentialLoading ||
						isAnalyticsNPVLoading) && (
						<Skeleton
							variant="rectangle"
							height={350}
							sx={{ marginTop: 3 }}
						/>
					)}
				</Grid>

				<Grid item xs={12}>
					{analyticsAmortizationIsLoading && (
						<Skeleton height={500} />
					)}
					{MemoizedAmortizationCard}
				</Grid>
			</PageFrame>
		);
	}
}

export default Analytics;
