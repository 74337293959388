import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const TemplateBox = styled(Box)`
	border-radius: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 4;
	margin: 3.75rem 0;
	width: 10rem;
	height: 10rem;
`;

export default function SoraColors() {
	return (
		<Box
			sx={{
				justifyContent: 'center',
				display: 'flex',
				gap: 4,
				my: '3.75rem',
				textAlign: 'center'
			}}
		>
			<TemplateBox
				sx={{
					backgroundColor: 'primary.soraBlue',
					color: '#ffffff'
				}}
			>
				<Typography variant="body1" gutterBottom component="div">
					#2685C1 <br /> Sora Blue <br /> (primary.soraBlue)
				</Typography>
			</TemplateBox>

			<TemplateBox
				sx={{
					backgroundColor: 'primary.lightBlue',
					color: '#000000'
				}}
			>
				<Typography variant="body1" gutterBottom component="div">
					#97D8E8 <br /> Light Blue <br /> (primary.lightBlue)
				</Typography>
			</TemplateBox>

			<TemplateBox
				sx={{
					backgroundColor: 'primary.indigo',
					color: '#ffffff'
				}}
			>
				<Typography variant="body1" gutterBottom component="div">
					#295983 <br /> Indigo <br /> (primary.indigo)
				</Typography>
			</TemplateBox>

			<TemplateBox
				sx={{
					backgroundColor: 'primary.iceBlue',
					color: '#000000'
				}}
			>
				<Typography variant="body1" gutterBottom component="div">
					#C8E9F1DE <br /> Ice Blue <br /> (primary.iceBlue)
				</Typography>
			</TemplateBox>

			<TemplateBox
				sx={{
					backgroundColor: '#B00020',
					color: '#ffffff'
				}}
			>
				<Typography variant="body1" gutterBottom component="div">
					#B00020 <br /> Error <br /> (error.main)
				</Typography>
			</TemplateBox>
		</Box>
	);
}
