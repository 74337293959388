import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import FinancialGoalDropdown from 'components/Client/Onboarding/FinancialGoalDropdown';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import LoanTypeDropdown from 'components/Client/Onboarding/LoanTypeDropdown';
import Loader from 'components/Loader';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Client/Onboarding/RateInput';
import TextInput from 'components/Input/TextInput';
import { NUMBER, TEXT } from 'components/Input/Types';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TenureDropdown from 'components/Client/Onboarding/TenureDropdown';

// Utils
import {
	convertMonthsToYears,
	dataIsValid,
	isSubmissionReady,
	roundToDecimal
} from 'shared/utils';

import dateToIsoFormat from 'shared/utils/clientOnboarding/dateToIsoFormat';
import inverseFinancialGoalMapping from 'shared/utils/clientOnboarding/inverseFinancialGoalMapping';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import FINANCIAL_GOAL_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/financialGoalMapping';
import getDefaultApproxPayoffDate from 'shared/utils/clientOnboarding/getDefaultApproxPayoffDate';

// Our Hooks
import useGetUserStudentLiability from 'hooks/clientOnboarding/useGetUserStudentLiability';
import useSaveStudentLiability from 'hooks/clientOnboarding/mutations/useSaveStudentLiability';

// CONSTANTS
import { STUDENT } from 'shared/constants';

const DEGREE_TYPES = [
	'Some High School',
	'High School Graduate',
	'Bachelors',
	'Masters',
	'Doctorate',
	'Professional',
	'Other Educational Degree'
];
const TYPES_OF_STUDENT_LOANS = ['Federal', 'Private'];
const GRADUATION_YEAR_ONLY_VIEW = ['year']; // This is for the DatePicker of Graduation Year

function StudentLiability() {
	// Student Liability Related
	const navigate = useNavigate();
	const { isLoading, data, isSuccess } = useGetUserStudentLiability();
	const saveStudentLiability = useSaveStudentLiability();

	const { isLoading: isMutationLoading } = saveStudentLiability;

	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(STUDENT)
	);
	const [lender, setLender] = useState('');
	const [lenderType, setLenderType] = useState('Federal');
	const [loanType, setLoanType] = useState('Fixed');
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [ownershipTenure, setOwnershipTenure] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Student Related Data
	const [fieldOfStudy, setFieldOfStudy] = useState('');
	const [gpa, setGpa] = useState('');
	const [graduationYear, setGraduationYear] = useState(null);
	const [highestDegree, setHighestDegree] = useState('');
	const [schoolName, setSchoolName] = useState('');
	const [linkedinProfile, setLinkedinProfile] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	useEffect(() => {
		if (isSuccess) {
			const WANTED_KEY_SET_MAPPING = {
				degree: setHighestDegree,
				expectedPayOffDate: setApproxPayoffDate,
				financialGoal: setFinancialGoal,
				fieldOfStudy: setFieldOfStudy,
				gpa: setGpa,
				graduationYear: setGraduationYear,
				interestRate: setRate,
				lender: setLender,
				lenderType: setLenderType,
				linkedin: setLinkedinProfile,
				loanType: setLoanType,
				monthlyPay: setMonthlyPayment,
				outstandingBalance: setOutstandingBalance,
				school: setSchoolName,
				tenureMonth: setOwnershipTenure
			};

			const studentLiabilityFields = Object.keys(data);

			try {
				studentLiabilityFields.forEach((studentLiabilityField) => {
					const currentData = data[studentLiabilityField];

					const setUpdater =
						WANTED_KEY_SET_MAPPING[studentLiabilityField];

					if (studentLiabilityField === 'studentInfo') {
						const studentSpecificFields = Object.keys(currentData);

						studentSpecificFields.forEach(
							(studentSpecificField) => {
								const studentSpecificUpdater =
									WANTED_KEY_SET_MAPPING[
										studentSpecificField
									];
								const studentSpecificData =
									currentData[studentSpecificField];

								// eslint-disable-next-line no-useless-return
								if (!dataIsValid(studentSpecificData)) return;

								if (studentSpecificField === 'graduationYear') {
									// if we provide DatePicker just an int like 2005.
									// it will parse it as 1969.
									// so instead we create an arbitary month and year date with the given year
									// e.g. graduationYear is 2005
									// we pass date picker 2005, march, 23 => datePicker of Year type says oh okay lets just give it a year of 2005.
									studentSpecificUpdater(
										new Date(studentSpecificData, 3, 23)
									);
									return;
								}
								if (studentSpecificField === 'degree') {
									const possibleDegreeTypes = new Set(
										DEGREE_TYPES
									);
									const isValidDegree =
										possibleDegreeTypes.has(
											studentSpecificData
										);
									if (isValidDegree)
										studentSpecificUpdater(
											studentSpecificData
										);
									return;
								}
								studentSpecificUpdater(studentSpecificData);
							}
						);
						return;
					}

					if (
						studentLiabilityField === 'monthlyPay' ||
						studentLiabilityField === 'outstandingBalance' ||
						studentLiabilityField === 'interestRate'
					) {
						// Here the values are ints we need to convert them to strings
						setUpdater(`${currentData}`);
						return;
					}

					if (studentLiabilityField === 'tenureMonth') {
						// here the backend provides in months e.g. 360 but we want in years
						// hence 360 / 12 => 30 years
						const formattedTenureMonth = `${convertMonthsToYears(
							currentData
						)}`;
						setUpdater(formattedTenureMonth);
						return;
					}

					if (
						studentLiabilityField === 'loanType' ||
						studentLiabilityField === 'lenderType'
					) {
						const formattedLoanType =
							normalizeLoanType(currentData);
						setUpdater(formattedLoanType);
						return;
					}

					if (studentLiabilityField === 'financialGoal') {
						setUpdater(inverseFinancialGoalMapping(currentData));
						return;
					}

					setUpdater(currentData);
				});
			} catch (e) {
				console.error(e);
			}
		}
	}, [data]);

	const formValues = [
		approxPayoffDate,
		financialGoal,
		graduationYear,
		highestDegree,
		lender,
		lenderType,
		loanType,
		monthlyPayment,
		outstandingBalance,
		ownershipTenure,
		rate,
		schoolName
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(formValues) &&
			approxPayoffDate !== null &&
			graduationYear !== null,
		formValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const handleGpachange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setGpa(value);
			return;
		}
		const hasPointlessDecimal = value.includes('4.');
		if (hasPointlessDecimal) return;

		// if we made it here the value is not empty and its not 4.something
		const parsedValue = +value;
		const hasDecimal = parsedValue % 1 !== 0;

		if (parsedValue >= 1 && parsedValue <= 4) {
			if (hasDecimal) {
				const roundedValue = Math.floor(parsedValue * 100) / 100;

				setGpa(roundedValue);
				return;
			}

			setGpa(value);
		}
	};

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);
		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);
		const formattedGraduationYear = new Date(graduationYear).getFullYear(); // graduationYear is an object but this turns it into a number
		const formattedGPA = +gpa; // must be a num
		const formattedRate = roundToDecimal(+rate, 2);

		// everything below is subject to change once we databind.
		const studentLiability = {
			approxPayoffDate: formattedApproxPayOffDate,
			financialGoal: formattedFinancialGoal,
			fieldOfStudy,
			gpa: formattedGPA,
			graduationYear: formattedGraduationYear,
			degree: highestDegree,
			lender,
			lenderType: lenderType.toUpperCase(),
			linkedin: linkedinProfile,
			loanType: loanType.toUpperCase(),
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			rate: formattedRate,
			school: schoolName,
			tenure: +ownershipTenure,
			tradelineType: 'STUDENT' // can be one of AUTO | MORTGAGE | PERSONAL | STUDENT
		};

		saveStudentLiability.mutate(studentLiability, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Student Loan</title>
				</Helmet>
				<LiabilityFormHeading headingText="Student Loan" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);

	return (
		<>
			<Helmet>
				<title>Student Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Student Loan" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={5}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
						/>
					</Grid>
					<Grid item xs={5} marginBottom={4}>
						<MonthlyPaymentInput
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>

					<Grid item xs={2} />
					<Grid item xs={5}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>
					<Grid item xs={5} marginBottom={4}>
						<StandardDatePicker
							label="Expected Payoff Date"
							helperText="Enter the approximate payoff date"
							onChange={handleApproxPayoffDateChange}
							value={approxPayoffDate}
							error={approxPayoffDate === ''}
						/>
					</Grid>

					<Grid item xs={2} />
					{/* Takes 5 Grid Columns */}
					<TenureDropdown
						tenure={ownershipTenure}
						setTenure={setOwnershipTenure}
						dropDownSX={{ width: '100%' }}
						sx={{ marginBottom: 0 }}
					/>

					{/* Takes 5 Grid Columns */}
					<LoanTypeDropdown
						loanType={loanType}
						setLoanType={setLoanType}
					/>

					<Grid item xs={2} />
					<Grid item xs={5}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>
					<Grid item xs={5} marginBottom={4}>
						<Dropdown
							dataTestTag="loanType"
							items={TYPES_OF_STUDENT_LOANS}
							selected={lenderType}
							onChange={setLenderType}
							variant="outlined"
							label="Lender Type"
						/>
					</Grid>

					<Grid item xs={2} />

					<Grid item xs={5}>
						<Dropdown
							items={DEGREE_TYPES}
							label="Degree"
							selected={highestDegree}
							onChange={setHighestDegree}
							helperText="Degree obtained."
						/>
					</Grid>

					<Grid item xs={5} marginBottom={4}>
						<TextInput
							inputProps={{
								'data-test': 'school'
							}}
							type={TEXT}
							label="School attended"
							value={schoolName}
							onChange={setSchoolName}
							subLabel="Name of university"
						/>
					</Grid>

					<Grid item xs={2} />

					<Grid item xs={5}>
						<TextInput
							type={TEXT}
							label="What was your field of study?"
							value={fieldOfStudy}
							onChange={setFieldOfStudy}
							subLabel="Optional"
						/>
					</Grid>
					<Grid item xs={5} marginBottom={4}>
						<TextInput
							type={NUMBER}
							label="What was your GPA?"
							value={gpa}
							onChange={handleGpachange}
							subLabel="Optional. Please report out of 4.0 scale."
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={5}>
						<StandardDatePicker
							helperText="Enter a year like 2022"
							inputFormat="yyyy"
							label="Graduation year"
							onChange={setGraduationYear}
							variant="outlined"
							value={graduationYear}
							views={GRADUATION_YEAR_ONLY_VIEW}
						/>
					</Grid>
					<Grid item xs={5} marginBottom={4}>
						<TextInput
							type={TEXT}
							label="Linkedin profile"
							value={linkedinProfile}
							onChange={setLinkedinProfile}
							subLabel="Optional"
						/>
					</Grid>

					<Grid item xs={2} />
					{/* FinancialGoalDropdown uses 5 Grid columns */}
					<FinancialGoalDropdown
						withoutCashout
						financialGoal={financialGoal}
						setFinancialGoal={setFinancialGoal}
						sx={{ marginBottom: 0 }}
						dropDownLabelSX={{ whiteSpace: 'nowrap' }}
					/>

					<Grid item xs={7} />

					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default StudentLiability;
