import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

// Our Components
import { TertiaryButton } from 'components/Button/Button';

// Our Assets
import PlaceholderImg from 'assets/images/HCPlaceholder.svg';

// Our Theme
import SoraTheme from 'theme';

export function HighlightCard({
	background,
	buttonText,
	cardSubmit,
	cardHeading,
	headingVariant,
	headingColor,
	image,
	soraScore,
	totalLoansValue
}) {
	// Outer wrapper
	const StyledCard = styled(Card)`
		width: 20rem;
		height: 90%;
		position: relative;
		border-radius: 1.25rem;
		background-image: ${background};
		padding: 1rem;
		user-drag: none;
		user-select: none;
	`;

	// Heading typography
	const StyledCardHeading = styled(Typography)`
		color: ${headingColor};
	`;

	// Styles for the soraScore and totalLoansValue typography
	const StyledSoraOutput = styled(Typography)`
		margin-bottom: 0;
		color: ${headingColor};
	`;

	// Wrapper around call to action button
	const StyledCardActions = styled(CardActions)`
		padding-left: 1rem;
		padding-bottom: 1rem;
	`;

	// Image
	const CardImage = styled(CardMedia)`
		position: absolute;
		bottom: 0;
		right: 0;
		width: 6.875rem;
		height: 6.875rem;
		padding-top: 0.375rem;
		padding-right: 0.375rem;
		user-drag: none;
		user-select: none;
		-webkit-user-drag: none;
	`;

	return (
		<StyledCard
			onClick={cardSubmit}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				minHeight: 190,
				marginBottom: 2
			}}
		>
			<CardContent>
				<StyledCardHeading variant={headingVariant} gutterBottom>
					{cardHeading}
				</StyledCardHeading>

				{soraScore && (
					<StyledSoraOutput variant="h4" gutterBottom>
						{soraScore}
					</StyledSoraOutput>
				)}

				{totalLoansValue && (
					<StyledSoraOutput variant="h5" gutterBottom>
						{totalLoansValue}
					</StyledSoraOutput>
				)}
			</CardContent>

			<StyledCardActions>
				<TertiaryButton
					variant="contained"
					onClick={cardSubmit}
					sx={{ backgroundColor: '#295983' }}
				>
					{buttonText}
				</TertiaryButton>
			</StyledCardActions>

			<CardImage component="img" image={image} alt="placeholder" />
		</StyledCard>
	);
}

HighlightCard.propTypes = {
	cardSubmit: PropTypes.func.isRequired,
	background: PropTypes.string,
	headingVariant: PropTypes.string,
	headingColor: PropTypes.string,
	cardHeading: PropTypes.string,
	soraScore: PropTypes.string,
	totalLoansValue: PropTypes.string,
	buttonText: PropTypes.string,
	image: PropTypes.string
};

HighlightCard.defaultProps = {
	background:
		'linear-gradient(180deg, #2685C1 0%, rgba(0, 156, 255, 0.17) 100%)',
	headingVariant: 'h5',
	headingColor: SoraTheme.palette.primary.indigo,
	cardHeading: 'Card Heading',
	soraScore: 'A',
	totalLoansValue: '$1,300',
	buttonText: 'Learn More',
	image: PlaceholderImg
};

export default HighlightCard;
