import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// List of States
import US_STATES from 'pages/Client/Onboarding/US_STATES.json';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function USStateDropdown({
	dropDownSX,
	dropDownLabelSX,
	gridColumns,
	helperText,
	state,
	setState,
	sx
}) {
	const US_STATE_LIST = useMemo(() => US_STATES, []);

	return (
		<Grid item xs={gridColumns} sx={sx}>
			<Dropdown
				helperText={helperText}
				items={US_STATE_LIST}
				label="State"
				labelSx={dropDownLabelSX}
				onChange={setState}
				placeholder="State"
				selected={state}
				sx={dropDownSX}
				variant="outlined"
			/>
		</Grid>
	);
}

USStateDropdown.propTypes = {
	dropDownLabelSX: CommonPropTypes.sx,
	dropDownSX: CommonPropTypes.sx,
	gridColumns: PropTypes.number,
	helperText: PropTypes.string,
	state: PropTypes.string.isRequired,
	setState: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx
};

USStateDropdown.defaultProps = {
	dropDownLabelSX: {},
	dropDownSX: {},
	gridColumns: 5,
	helperText: '',
	sx: {}
};

export default USStateDropdown;
