import { useEffect } from 'react';

const INTERCOM_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

function useManageIntercom() {
	return useEffect(() => {
		const hasIntercom = window.Intercom !== undefined;
		const isProd = APP_ENVIRONMENT === 'production';

		if (isProd && hasIntercom) {
			// boots intercom with base info
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: INTERCOM_ID
			});
		}
		// cleanup that when the App closes it will shutdown the bot and close convos.
		return () => {
			if (hasIntercom) {
				window.Intercom('shutdown');
			}
		};
	}, []);
}

export default useManageIntercom;
