import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as saveMortgageLiabilityURL } from 'shared/api-urls';

// Our Routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Our query keys
import {
	USER_LIABILITIES,
	WALLET_OVERVIEW_DATA,
	LIABILITY
} from 'shared/query-keys';

function useMutateSaveMortgageLiability() {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();

	const queryClient = useQueryClient();

	const isManualEntry = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);

	const urlForRequest = isManualEntry
		? saveMortgageLiabilityURL
		: `${saveMortgageLiabilityURL}${tradeLineId}`;

	return useMutation(async (mortgageLiabilityData) => {
		if (isManualEntry) {
			const response = await axiosInstance.post(
				urlForRequest,
				mortgageLiabilityData
			);

			await queryClient.invalidateQueries(USER_LIABILITIES);
			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});
			return response.data;
		}

		// is not a manual entry
		const response = await axiosInstance.patch(
			urlForRequest,
			mortgageLiabilityData
		);

		await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
			refetchInactive: true
		});

		await queryClient.invalidateQueries([LIABILITY, tradeLineId], {
			refetchInactive: true
		});

		return response.data;
	});
}

export default useMutateSaveMortgageLiability;
