import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useQueryClient } from 'react-query';

// Our Components
import Alert from 'components/Alert';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import SelectLoanLiability from 'components/Client/newLoan/SelectLoanLiabilityCard';
import { PrimaryButton } from 'components/Button/Button';

// Routes
import {
	AUTO_LIABILITY_ADDITIONAL_ROUTE,
	HOME_LIABILITY_ADDITIONAL_ROUTE,
	PERSONAL_LIABILITY_ADDITIONAL_ROUTE,
	STUDENT_LIABILITY_ADDITIONAL_ROUTE,
	INCOME_ROUTE
} from 'routes';

// Hooks
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

// Query Keys
import { ERROR_MESSAGE_DATA, USER_LIABILITIES } from 'shared/query-keys';

// Constants
import { LIABILITIES_UPDATED } from 'shared/constants';

function LoanSelection() {
	// Defining useNavigate from react router dom
	const queryClient = useQueryClient();
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const navigate = useNavigate();
	const location = useLocation();

	const { state: locationState } = location;

	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	const isAtAdditionalPage =
		location.pathname === '/client-onboarding/liabilities/additional/';

	const ERROR_MESSAGE = queryClient.getQueryData(ERROR_MESSAGE_DATA);

	if (isAtAdditionalPage)
		return (
			<>
				<Helmet>
					<title>Additional Loan</title>
				</Helmet>

				{/* Once data binding begins we will add Mortgage specific information to this header */}
				<LiabilityFormHeading
					headingText="Add additional loan(s) manually"
					sx={{ marginBottom: 2 }}
				/>
				{ERROR_MESSAGE && <Alert variant="error" />}
				<Typography variant="body1" color={primary.black}>
					Click on the card to add a liability.
				</Typography>
				<Grid item xs={12} sx={{ marginTop: 4, marginBottom: 4 }}>
					<Box
						sx={{
							display: 'flex',
							gap: 6,
							width: '100%'
						}}
					>
						<SelectLoanLiability
							LiabilityType="Mortgage"
							loanHeading="Home Loan"
							onClickNavRoute={HOME_LIABILITY_ADDITIONAL_ROUTE}
							routeState={locationState}
							withoutCheckBox
						/>
						<SelectLoanLiability
							LiabilityType="Auto"
							loanHeading="Auto Loan"
							onClickNavRoute={AUTO_LIABILITY_ADDITIONAL_ROUTE}
							routeState={locationState}
							withoutCheckBox
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							gap: 6,
							width: '100%'
						}}
					>
						<SelectLoanLiability
							LiabilityType="Student"
							loanHeading="Student Loan"
							onClickNavRoute={STUDENT_LIABILITY_ADDITIONAL_ROUTE}
							routeState={locationState}
							withoutCheckBox
						/>
						<SelectLoanLiability
							LiabilityType="Personal"
							loanHeading="Personal Loan"
							onClickNavRoute={
								PERSONAL_LIABILITY_ADDITIONAL_ROUTE
							}
							routeState={locationState}
							withoutCheckBox
						/>
					</Box>
				</Grid>

				<PrimaryButton
					data-test="next"
					onClick={() => {
						updateOnboardingStatus.mutate(LIABILITIES_UPDATED, {
							onSuccess: () => {
								navigate(INCOME_ROUTE);
								queryClient.invalidateQueries(USER_LIABILITIES);
							},
							onError: (error) => {
								queryClient.setQueryData(
									ERROR_MESSAGE_DATA,
									error.message
								);
							}
						});
					}}
				>
					Done
				</PrimaryButton>
			</>
		);

	return <Outlet />;
}

export default LoanSelection;
