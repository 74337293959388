import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import { Grid, Button, Typography } from '@mui/material';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';
// our components
import BasicOnboardingFrame from 'components/Frame/BasicOnboarding';

// our query keys
import {
	FA_ASSOCIATION_DATA,
	USERPROFILEDATA,
	UNVERIFIED_USER
} from 'shared/query-keys';

const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export default function FaThanksPage() {
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// Handling login case, checking status of user's profile check if it's unverified
	const isUnverified = queryClient.getQueryData(UNVERIFIED_USER);

	// Getting user data to determine if associated company was found and if the association was confirmed or denied
	const FAUserData = queryClient.getQueryData(FA_ASSOCIATION_DATA);
	const isAssociationFound = FAUserData?.isCompanyFound;
	const isAssociationMade = FAUserData?.isAssociatedToCompany;

	const userProfileData = queryClient.getQueryData(USERPROFILEDATA);

	const sendFormData = useMutation(async () => {
		const emailSubject =
			'An advisor attempted to sign up without a company';
		const {
			firstName,
			lastName,
			phoneNumber,
			companyName,
			companyWebsite
		} = FAUserData;
		let emailJsParams = {
			name: `${firstName} ${lastName}`,
			subject: emailSubject
		};

		if (userProfileData) {
			const { email } = userProfileData.data;
			const { name: fullName } = emailJsParams;
			const message = `
			We have the following User Details
			full name: ${fullName}
			email: ${email}
			phone: ${phoneNumber}
			company name: ${companyName}
			company website: ${companyWebsite}
			
			${fullName} attempted to make an Financial Advisor account and did not want to be associated with ${companyName}.
			
			Using this ${email} and the company website was ${companyWebsite}
			`;
			emailJsParams = { ...emailJsParams, emailAddress: email, message };
		}
		await emailjs.send(
			EMAIL_SERVICE_ID,
			EMAIL_TEMPLATE_ID,
			emailJsParams,
			EMAIL_PUBLIC_KEY
		);
	});

	useEffect(() => {
		// only send email if company association data is found, but denied by user
		if (isAssociationFound && !isAssociationMade) {
			setTimeout(() => {
				sendFormData.mutate({});
			}, 200);
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>Thank you</title>
			</Helmet>

			<BasicOnboardingFrame progress={100}>
				<Grid item xs={8}>
					<Typography
						variant="h4"
						gutterBottom
						component="div"
						sx={{
							marginTop: 8,
							color: SoraTheme.palette.primary.soraBlue
						}}
					>
						{isAssociationMade ||
						(!isAssociationFound && !isUnverified)
							? 'Thank you! Your account was created'
							: `Please reach out to Sora to get your account setup.`}
					</Typography>

					{((!isAssociationMade && isAssociationFound) ||
						isUnverified) && (
						<Typography
							variant="body1"
							gutterBottom
							component="div"
							sx={{ marginTop: 8 }}
						>
							Because your account is not associated to a company
							it requires additional setup.
							<br /> <br />
							Please reach out to Sora at contact@sorafinance.com
							to finish setting up your account.
						</Typography>
					)}

					{(isAssociationMade ||
						(!isAssociationFound && !isUnverified)) && (
						<>
							<Typography
								variant="body1"
								gutterBottom
								component="div"
								sx={{
									marginTop: 4
								}}
							>
								Please login with your new account
							</Typography>
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate('/login')}
								sx={{
									marginTop: 4,
									width: 'fit-content',
									padding: '8px 16px'
								}}
							>
								Login
							</Button>
						</>
					)}
				</Grid>
			</BasicOnboardingFrame>
		</>
	);
}
