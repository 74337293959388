import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

// Our axios instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { userProfile } from 'shared/api-urls';

// Our Query keys
import { USER_DATA, USERPROFILEDATA } from 'shared/query-keys';

// Our Routes
import { CLIENT_REVIEW_ROUTE } from 'routes';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

import { REVIEW_COMPLETED } from 'shared/constants';

function useMutateAddProfileData() {
	const queryClient = useQueryClient();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();
	const location = useLocation();

	const { pathname } = location;

	return useMutation(
		USER_DATA,
		async (userData) => {
			const response = await axiosInstance.post(userProfile, userData);
			const userProfileData = response.data.data;

			const isOnReviewPage = pathname.includes(CLIENT_REVIEW_ROUTE);

			if (isOnReviewPage) {
				// variable here is not needed but the AWAIT is.
				// stored in a variable for contextual purposes.
				const makeOnboardingStatusUpdateResponse =
					await updateOnboardingStatus.mutate(REVIEW_COMPLETED);
			}

			return userProfileData;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(USERPROFILEDATA);
			}
		}
	);
}

export default useMutateAddProfileData;
