import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import { useState } from 'react';

// Our Components
import BasicNavFrame from 'components/Frame/BasicNav';
import SettingsTabs from 'components/Settings/SettingsTabs';

function SettingsPage() {
	const SoraTheme = useTheme();
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	return (
		<BasicNavFrame>
			<Helmet>
				<title>Settings</title>
			</Helmet>

			<Grid item xs={11}>
				<Typography
					variant="h4"
					gutterBottom
					component="div"
					sx={{
						marginTop: 8,
						color: SoraTheme.palette.primary.soraBlue
					}}
				>
					Settings
				</Typography>

				<SettingsTabs
					tabPanelSelected={tabPanelSelected}
					setTabPanelSelected={setTabPanelSelected}
				/>
			</Grid>
		</BasicNavFrame>
	);
}

export default SettingsPage;
