import { Helmet } from 'react-helmet-async';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Our defined styles
import StyleReferencePage from 'pages/StyleReferencePage';

// App Related
import ContactUs from 'pages/ContactUs/ContactUsPage';
import Error404 from 'pages/Error/404';
import Settings from 'pages/Settings/SettingsPage';
import MaintenancePage from 'pages/Maintenance/MaintenancePage';
import SuperAdminPage from 'pages/Admin/SuperAdminPage';
import UnsubscribedPage from 'pages/Email/UnsubscribedPage';

// Login Related
import ForgotPasswordPage from 'pages/ForgotPassword/ForgotPasswordPage';
import LoginPage from 'pages/Login/LoginPage';
import OTPLoginPage from 'pages/Login/OTPLoginPage';
import RoutingUser from 'components/Signup/RoutingUser';
import SignupPage from 'pages/Signup/SignupPage';

// Client Invite
import AddingClients from 'pages/Client/AddingClient/AddingClients';
import ClientInviteThanks from 'pages/Client/AddingClient/ClientAddedThanks';
import TriggerClientInvitation from 'pages/Client/Entry/TriggerClientInvitePage';
import VerifyClient from 'components/Client/Onboarding/VerifyClientInvite';
import VerifyEmployee from 'components/Client/Onboarding/Employee/VerifyEmployee';

// FA Onboarding
import FaAdditionalDetails from 'components/Signup/FaAdditionalDetails';
import Thanks from 'pages/Signup/FaThanksPage';

/* FA Dashboard */
import FADashboard from 'pages/FADashboard/FADashboard';
import LoanStatus from 'pages/FADashboard/LoanStatus';
import ClientReports from 'pages/FADashboard/ClientReports';
import Analytics from 'pages/FADashboard/Analytics';

// Sora Wallet
import SoraWallet from 'pages/SoraWallet/SoraWalletPage';
import SoraWalletOverview from 'pages/SoraWalletOverview/SoraWalletOverview';
import SoraWalletWrapper from 'components/SoraWallet/SoraWalletWrapper';

// Offers Page
import OffersPage from 'pages/Offers/OffersPage';

/* Client Onboarding */
import ClientAdditionalDetails from 'pages/Client/Onboarding/AdditionalDetailsPage';
import ClientThankYouPage from 'pages/Client/Onboarding/ThankYou';
import UploadLoanDocs from 'pages/Client/Onboarding/UploadLoanDocs';
import UnableToVerify from 'pages/Client/Onboarding/UnableToVerify';

// Multiple Liabilities
import AutoLiability from 'pages/Client/Onboarding/Liabilities/AutoLiability';
import OtherLiabilities from 'pages/Client/Onboarding/Liabilities/OtherLiabilities';
import HomeLiability from 'pages/Client/Onboarding/Liabilities/HomeLiability';
import LoanSelection from 'pages/Client/Onboarding/Liabilities/LoanSelection';
import LiabilitiesList from 'pages/Client/Onboarding/Liabilities/LiabilitiesList';
import OnboardingWrapperLiability from 'components/Client/Onboarding/OnboardingWrapperLiability';
import PersonalLiability from 'pages/Client/Onboarding/Liabilities/PersonalLiability';
import StudentLiability from 'pages/Client/Onboarding/Liabilities/StudentLiability';

// Onboarding Income
import IncomeInfo from 'pages/Client/Onboarding/Income/IncomeInfo';

// Onboarding Assets
import AssetsForm from 'pages/Client/Onboarding/Assets/AssetsForm';

import OnboardingReviewForm from 'pages/Client/Onboarding/Review/OnboardingReview';

// Client New Loan
// import SelectLoanCashOut from 'pages/Client/selectLoan/SelectLoanCashOut';
// import SelectLoanRefinance from 'pages/Client/selectLoan/SelectLoanRefinance';
import ClientSelectNewLoan from 'pages/Client/newLoan/NewLoanSelectType';
import HomeLoanQuestionnaire from 'pages/Client/newLoan/HomeLoanQuestionnaire';
import AutoLoanQuestionnaire from 'pages/Client/newLoan/AutoLoanQuestionnaire';

// Routes
import {
	CLIENT_ADDITIONAL_DETAILS_ROUTE,
	LOGIN_ROUTE,
	LOGIN_OTP_ROUTE,
	ADVISOR_ANALYTICS
} from 'routes/index';

// Hooks
import useManageIntercom from 'hooks/Intercom/useManageIntercom';

// TODO:
// After company firebase account is setup, go to google analytics and connect the firebase
// property to google analytics, replace the initialize code below
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');
// ReactGA.pageview(window.location.pathname + window.location.search);

// CONSTANTS
const isRunningLocally = process.env.NODE_ENV === 'development'; // process.env.NODE_EV will be development if project is being run as local host.
const LATEST_GIT_VERSION = process.env.REACT_APP_GIT_VERSION;
const QUERY_CACHE_TIME = 1000 * 60 * 10; // 1000 ms * 60s * 10 => 10 minutes

function App() {
	// Changes default retry for both queries and mutations to 0 so a SINGLE request is made for now.
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: 0,
				cacheTime: QUERY_CACHE_TIME
			},
			mutations: {
				retry: 0,
				cacheTime: QUERY_CACHE_TIME
			}
		}
	});

	// const hasErrorMsg = queryClient.getQueryData(ERROR_MESSAGE_DATA);
	// const hasSuccessMsg = queryClient.getQueryData(SUCCESS_MESSAGE_DATA);

	// This initializes and cleans up Intercom from Sora App.
	useManageIntercom();

	return (
		<div className="App">
			<Helmet>
				<title>Sora Finance</title>
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Routes>
					{/* RELEASE RELATED:
						For now, this will get uncommented when a release of the back end happens. We'll comment out all other routes and uncomment the maintenance routes.
						Once release is complete, we'll flip this back. */}

					{/* Maintenance Page */}
					{/* <Route path="*" element={<MaintenancePage />} /> */}

					<Route path="/" element={<Navigate to="/login" />} />
					<Route path="super-admin" element={<SuperAdminPage />} />
					<Route path="unsubscribe" element={<UnsubscribedPage />} />
					<Route path="signup" element={<SignupPage />} />
					<Route path={LOGIN_ROUTE} element={<LoginPage />} />
					<Route path={LOGIN_OTP_ROUTE} element={<OTPLoginPage />} />
					<Route path="routing-user" element={<RoutingUser />} />
					<Route path="forgot" element={<ForgotPasswordPage />} />
					<Route path="styles" element={<StyleReferencePage />} />
					<Route
						path="fa/onboarding/additional-details"
						element={<FaAdditionalDetails />}
					/>
					<Route path="fa/onboarding/thanks" element={<Thanks />} />

					{/* FA Dashboard */}
					<Route path="dashboard" element={<FADashboard />} />
					<Route
						path="dashboard/loan-status"
						element={<LoanStatus />}
					/>

					<Route
						path="dashboard/report"
						element={<ClientReports />}
					/>

					<Route path={ADVISOR_ANALYTICS} element={<Analytics />} />

					{/* Sora Wallet */}
					<Route path="sora-wallet-overview">
						<Route element={<SoraWalletWrapper />}>
							<Route
								index
								element={<SoraWalletOverview isClient />}
							/>
							{/* Route for the advisor view of a specific tradeline */}
							<Route path="liability">
								<Route
									path=":tradelineId"
									element={<SoraWallet isClient />}
								/>
							</Route>

							<Route path="advisor">
								<Route path=":clientId">
									<Route
										index
										element={
											<SoraWalletOverview
												isClient={false}
											/>
										}
									/>
									<Route
										path="liability/:tradelineId"
										element={
											<SoraWallet isClient={false} />
										}
									/>
								</Route>
							</Route>
						</Route>
					</Route>

					<Route path="settings" element={<Settings />} />
					<Route path="contact-us" element={<ContactUs />} />

					{/* Sora Offers Related */}
					<Route path="offers">
						<Route
							path="new_loan"
							element={<OffersPage OfferType="new_loan" />}
						/>
						<Route
							path="cash_out"
							element={<OffersPage OfferType="cash_out" />}
						/>
						<Route
							path="refinance"
							element={<OffersPage OfferType="refinance" />}
						/>
					</Route>

					{/* Client Onboarding */}
					<Route
						path={CLIENT_ADDITIONAL_DETAILS_ROUTE}
						element={<ClientAdditionalDetails />}
					/>

					<Route
						path="client/onboarding/unable-to-verify"
						element={<UnableToVerify />}
					/>

					<Route
						path="client/onboarding/upload-loan-docs"
						element={<UploadLoanDocs />}
					/>
					<Route
						path="client/onboarding/thankyou"
						element={<ClientThankYouPage />}
					/>

					{/* MLO Client Onboarding Related */}
					<Route path="client-onboarding">
						{/* Layout Route that wraps ALL of Liability related routes. */}
						<Route element={<OnboardingWrapperLiability />}>
							<Route path="liabilities">
								{/* Dynamic Ones */}
								<Route
									path="auto/:tradeLineId"
									element={<AutoLiability />}
								/>
								<Route
									path="mortgage/:tradeLineId"
									element={<HomeLiability />}
								/>
								<Route
									path="student/:tradeLineId"
									element={<StudentLiability />}
								/>
								<Route
									path="personal/:tradeLineId"
									element={<PersonalLiability />}
								/>

								{/* Static Routes */}
								<Route
									path="identified"
									element={<LiabilitiesList />}
								/>
								<Route
									path="other"
									element={<OtherLiabilities />}
								/>
								<Route
									path="additional"
									element={<LoanSelection />}
								>
									{/* Outlets for additional */}
									<Route
										path="auto"
										element={<AutoLiability />}
									/>
									<Route
										path="mortgage"
										element={<HomeLiability />}
									/>
									<Route
										path="student"
										element={<StudentLiability />}
									/>
									<Route
										path="personal"
										element={<PersonalLiability />}
									/>
								</Route>
							</Route>
						</Route>

						<Route path="income">
							<Route path="" element={<IncomeInfo />} />
						</Route>

						<Route path="assets" element={<AssetsForm />} />

						<Route
							path="review"
							element={<OnboardingReviewForm />}
						/>
					</Route>

					{/* Client select new loan */}
					{/* These Routes are for filing a new loan NOT adding a liability. */}
					<Route path="newloan">
						<Route
							path="home-loan-questionnaire"
							element={<HomeLoanQuestionnaire />}
						/>
						<Route
							path="auto-loan-questionnaire"
							element={<AutoLoanQuestionnaire />}
						/>
					</Route>

					{/* Select Loan */}
					<Route path="select-loan">
						<Route
							path="new-loan"
							element={<ClientSelectNewLoan />}
						/>
						{/* <Route
							path="refinance"
							element={<SelectLoanRefinance />}
						/>
						<Route
							path="cash-out"
							element={<SelectLoanCashOut />}
						/> */}
					</Route>

					{/* Client Invitation */}
					<Route path="invite" element={<AddingClients />} />
					<Route
						path="invite/thanks"
						element={<ClientInviteThanks />}
					/>
					{/* Acceptance of a client invitation */}
					<Route path="clientVerify">
						<Route path=":inviteCode" element={<VerifyClient />} />
					</Route>
					<Route path="employeeVerify">
						<Route
							path=":employeeData"
							element={<VerifyEmployee />}
						/>
					</Route>

					<Route
						path="/sora-for-everybody"
						element={<TriggerClientInvitation />}
					/>

					{/* 404 page */}
					<Route path="*" element={<Error404 />} />
				</Routes>
				{isRunningLocally && (
					<>
						<p
							style={{
								position: 'absolute',
								top: 2,
								right: 55,
								backgroundColor: 'white',
								padding: 2
							}}
						>
							Build Version:{' '}
							{LATEST_GIT_VERSION &&
								LATEST_GIT_VERSION.slice(0, 8)}
						</p>
						<ReactQueryDevtools initialIsOpen={false} />
					</>
				)}
			</QueryClientProvider>
		</div>
	);
}

export default App;
