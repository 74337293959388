import { useLocation } from 'react-router-dom';

/**
 *
 * @param {@memberof useLocation} userLocation - instance of useLocation
 * @returns {boolean}
 */

function useWasFromWallet(userLocation) {
	const location = userLocation ?? useLocation();

	const { state } = location;

	return state?.fromWallet;
}

export default useWasFromWallet;
