import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import { TertiaryButton } from 'components/Button/Button';

// Our Assets
import PersonalLoanImage from 'assets/images/personal_loan.svg';

// OUr Utils
import { dollarFormatter } from 'shared/utils';

function UpsidePotentialCard({
	heading,
	tooltTipText,
	calculatedValue,
	withButton
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const formattedCalculatedValue = dollarFormatter.format(calculatedValue);

	return (
		<Grid item xs={12} lg={6} xl={6}>
			<CardItem
				withoutButton
				toolTip={tooltTipText}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingBottom: 1
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
					<Typography variant="h5">{heading}</Typography>
					<Typography variant="h4" sx={{ color: primary.indigo }}>
						{formattedCalculatedValue}
					</Typography>
					{withButton && <TertiaryButton>Learn More</TertiaryButton>}
				</Box>
				<Box
					component="img"
					src={PersonalLoanImage}
					sx={{ width: 'auto', height: 'auto' }}
				/>
			</CardItem>
		</Grid>
	);
}

UpsidePotentialCard.propTypes = {
	heading: PropTypes.string.isRequired,
	tooltTipText: PropTypes.string.isRequired,
	calculatedValue: PropTypes.number.isRequired,
	withButton: PropTypes.bool
};

UpsidePotentialCard.defaultProps = {
	withButton: false
};

export default UpsidePotentialCard;
