import { Box, Typography, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import BasicFrame from 'components/Frame/Basic';

// Our Assets
import SoraLogoBlack from 'assets/images/SoraLogoBlack.svg';

function UnsubscribedPage() {
	return (
		<>
			<Helmet>
				<title>Unsubscribed</title>
			</Helmet>

			<Grid container sx={{ marginTop: 12, textAlign: 'center' }}>
				<Grid item md={12}>
					<Box
						component="img"
						src={SoraLogoBlack}
						sx={{
							width: 400,
							height: 160
						}}
					/>

					<Typography variant="h4" sx={{ marginTop: 10 }}>
						You have been unsubscribed from Sora emails
					</Typography>
				</Grid>
			</Grid>
		</>
	);
}

export default UnsubscribedPage;
