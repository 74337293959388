import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

const useSignInWithPopup = (isEnabled) => {
	const auth = getAuth();

	const provider = new GoogleAuthProvider();
	provider.setCustomParameters({
		prompt: 'select_account'
	});

	const navigate = useNavigate();
	return useQuery(
		FIREBASEUSERCREDENTIALS,
		() =>
			signInWithPopup(auth, provider).then(
				(result) => result.user.accessToken
			),
		{
			enabled: isEnabled,
			onSuccess: () => {
				navigate('/routing-user');
			}
		}
	);
};

export default useSignInWithPopup;
