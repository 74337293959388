export const LOGIN_ROUTE = '/login';
export const LOGIN_OTP_ROUTE = '/login/otp';

// Client Onboarding Identified Liability Routes
export const IDENTIFIED_LIABILITIES_ROUTE =
	'/client-onboarding/liabilities/identified';

export const DYNAMIC_LIABILITY_ROUTE =
	'/client-onboarding/liabilities/:tradelineType/:tradelineId';

// Client Onboarding Additional(manual) Routes
export const AUTO_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/auto';
export const HOME_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/mortgage';
export const STUDENT_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/student';
export const PERSONAL_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/personal';
export const LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/';

// Method Related Routes
export const METHODFI_UNABLE_TO_VERIFY_ROUTE =
	'/client/onboarding/unable-to-verify';

// Other Loan Route
export const OTHER_LOAN_ROUTE = '/client-onboarding/liabilities/other';

// Client Onboarding Income Routes
export const ASSETS_ROUTE = '/client-onboarding/assets/';
export const CLIENT_ADDITIONAL_DETAILS_ROUTE =
	'/client-onboarding/user-details'; // Where we collect their firstname , lastname, dob
export const CLIENT_INTRO_ROUTE = '/client-onboarding/intro'; // The page with methodfi
export const CLIENT_REVIEW_ROUTE = '/client-onboarding/review'; // final page in flow
export const CLIENT_SORAWALLET_ROUTE = '/sora-wallet-overview';
export const ADVISOR_WALLET_ROUTE = '/sora-wallet-overview/advisor/';
export const INCOME_ROUTE = '/client-onboarding/income/'; // Pinwheel page
export const INCOME_REVIEW_ROUTE = '/client-onboarding/income/review'; // review page after pinwheel
// Wallet
export const CLIENT_WALLET_OVERVIEW = '/sora-wallet-overview';
export const CLIENT_WALLET_LIABILITY = '/sora-wallet-overview/liability/';
export const ADVISOR_WALLET_LIABILITY = '/sora-wallet-overview/advisor/';

// Offers
export const NEW_LOAN_OFFER_ROUTE = '/offers/new_loan';
export const CASH_OUT_OFFER_ROUTE = '/offers/cash_out';
export const REFINANCE_OFFER_ROUTE = '/offers/refinance';

// New Loan Routes (Client only)
export const NEW_AUTO_LOAN_QUESTIONNAIRE = '/newloan/auto-loan-questionnaire';

// Settings Page
export const SETTINGS_PAGE = '/settings';

// Invite page
export const INVITE_PAGE = '/invite';
export const INVITE_THANKS_PAGE = '/invite/thanks';
export const DASHBOARD_PAGE = '/dashboard';

// Advisor Analytics page
export const ADVISOR_ANALYTICS = '/dashboard/analytics';
