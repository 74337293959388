import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

// Our Components
import SoraTheme from 'theme';
import ErrorAlert from 'components/Alert';
import Input from 'components/Input/TextInput';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// Input Types
import { TEXT } from 'components/Input/Types';

// Our Hooks
import useMutateLoginUser from 'hooks/useMutateLoginUser';
import useSignInWithPopup from 'hooks/useSignInWithPopup';

// Intercom update
import { intercomUpdateUserInfo } from 'services/API/IntercomInterface';

export function FullLoginForm() {
	// For our emails, we direct users to the /login page with the queryParam
	// app.sorafinance.com/login?email=emailaddress@gmail.com
	// if this query param is present, we pull it out of the url and pre-populate it
	// in the email input field
	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const encodedEmailFromUrl = urlParams.get('email');

	// This function is to check to see if the email pulled out of the URL is encoded
	const checkEmailEncoding = () => {
		if (encodedEmailFromUrl !== null) {
			try {
				// If able to correct decode this return that value.
				return window.atob(encodedEmailFromUrl);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
				// If not able to decode email correctly, return empty string.
				return '';
			}
		}
		return '';
	};

	// this will be either the decoded email address or a blank string
	const decodedEmailFromUrl = checkEmailEncoding();

	const [email, setEmail] = useState(decodedEmailFromUrl);
	const [password, setPassword] = useState('');
	const [isUsingGooglePopUp, setIsUsingGooglePopUp] = useState(false);

	const loginUser = useMutateLoginUser(); // Login Mutate

	const { error: loginUserError } = loginUser;

	useSignInWithPopup(isUsingGooglePopUp);

	// Handle field change
	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const handlePasswordChange = (value) => {
		setPassword(value);
	};

	// Defining useNavigate from react router dom
	const navigate = useNavigate();

	// Handle login form submit,
	// Sign into account w/ email and password
	const signInWithEmail = (e) => {
		e.preventDefault();
		// mutations should invoked by their mutate fn
		loginUser.mutate(
			{ email, password },
			{
				onSuccess: () => {
					// direct user to this intermediate page in order to parse the
					// user's profile data to know where to direct the user (RoutingUser.js)
					intercomUpdateUserInfo({ email });
					navigate('/routing-user', { replace: true });
				}
			}
		);
	};

	// Handle google login,
	// Login with existing google account
	const useSignInWithGoogle = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setIsUsingGooglePopUp(!isUsingGooglePopUp);
	};

	return (
		<>
			<Typography
				variant="h4"
				gutterBottom
				component="div"
				sx={{
					marginTop: 18,
					color: SoraTheme.palette.primary.soraBlue
				}}
			>
				Login
			</Typography>

			<Box
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={signInWithEmail}
			>
				{loginUserError && <ErrorAlert variant="error" />}
				<Grid item xs={8}>
					{/* Email */}
					<Input
						id="email"
						label="Email"
						type="email"
						onChange={handleEmailChange}
						inputProps={{
							'data-test': 'email-input'
						}}
						value={email}
						error={
							email === '' ||
							!email.includes('.') ||
							!email.includes('@')
						}
						helperText={
							email === '' ||
							!email.includes('.') ||
							!email.includes('@')
								? 'Please enter a valid email address'
								: ''
						}
						sx={{
							marginTop: 4,
							marginBottom: 2
						}}
					/>

					{/* Password */}
					<Input
						id="password"
						label="Password"
						type={TEXT}
						onChange={handlePasswordChange}
						inputProps={{
							'data-test': 'password-input'
						}}
						value={password}
						error={password.length < 8}
						helperText={
							password.length < 8
								? 'Password must be minimum of 8 characters'
								: ''
						}
						sx={{
							marginTop: 4,
							marginBottom: 2
						}}
						withVisibleToggle
						defaultVisability={false}
					/>

					<Box
						sx={{
							display: 'flex',
							gap: 9
						}}
					>
						{/* Forgot password link */}
						<Link
							to="/forgot"
							style={{
								color: SoraTheme.palette.primary.black,
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							<Typography
								variant="subtitle2"
								sx={{
									width: '100%'
								}}
							>
								Forgot your password?
							</Typography>
						</Link>
					</Box>

					<PrimaryButton
						onClick={signInWithEmail}
						data-test="login_button"
						type="submit"
						disabled={
							email === '' ||
							!email.includes('.') ||
							!email.includes('@') ||
							password === '' ||
							password.length < 8
						}
						sx={{
							marginTop: 4,
							display: 'block'
						}}
					>
						Log in
					</PrimaryButton>

					<Typography
						variant="subtitle1"
						sx={{
							marginTop: 4
						}}
					>
						- or -
					</Typography>

					<SecondaryButton
						onClick={useSignInWithGoogle}
						sx={{
							marginTop: 4
						}}
						startIcon={<GoogleIcon />}
					>
						Log in with Google
					</SecondaryButton>
				</Grid>
			</Box>
		</>
	);
}

export default FullLoginForm;
