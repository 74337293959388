import { Box, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
	AreaChart,
	Area,
	CartesianGrid,
	Legend,
	Label,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

// Our Theme
import SoraTheme from 'theme';

// Our components
import ResponsiveTable from 'components/Table/ResponsiveTable';
import SoraToolTip from 'components/Tooltip/index';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';

function InterestSavingsTab({
	analyticsSavingData,
	analyticsAmortizationData
}) {
	const { currentLoan, bestOffer, interestSavings } = analyticsSavingData;

	const safeAmortizationGraphData = useMemo(() => {
		if (analyticsAmortizationData) {
			const { amortization: bestOfferAmortization } =
				analyticsAmortizationData.bestOffer;

			const { amortization: currentLoanAmortization } =
				analyticsAmortizationData.currentLoan;

			return currentLoanAmortization.map((currentAmortData, index) => {
				const { month, totalInterest } = currentAmortData;
				const { totalInterest: bestOfferAmortizationData } =
					bestOfferAmortization[index];

				return {
					month,
					'Current Loan': totalInterest,
					'Best Offer': bestOfferAmortizationData
				};
			});
		}

		return [];
	}, [analyticsAmortizationData]);

	const savingsInvestmentPotentialTableRows = useMemo(
		() => [
			createFormattedRowData(
				'Total Payment',
				currentLoan?.totalPayment,
				bestOffer?.totalPayment
			),
			createFormattedRowData(
				'Principal',
				currentLoan?.originalLoanAmount,
				bestOffer?.originalLoanAmount
			),
			createFormattedRowData(
				'Total Interest Payments',
				currentLoan?.totalCostOfDebt,
				bestOffer?.totalCostOfDebt
			),
			['', '', ''],
			[
				'Interest Savings',
				dollarFormatter.format(interestSavings ?? 0),
				''
			]
		],
		[analyticsSavingData]
	);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
				<Typography
					variant="h6"
					sx={{
						color: SoraTheme.palette.primary.soraBlue,
						marginBottom: 6
					}}
				>
					Cumulative Interest Paid
				</Typography>
				<SoraToolTip text="Interest savings represents the total dollar savings achieved by refinancing your mortgage with the new loan offer." />
			</Box>

			<Grid
				container
				spacing={2}
				sx={{
					marginBottom: 4
				}}
			>
				<Grid item xs={12} xl={6}>
					{/* Graph here */}
					<ResponsiveContainer width="90%" height={500}>
						<AreaChart
							data={safeAmortizationGraphData}
							stackOffset="none"
						>
							<CartesianGrid strokeDasharray="1 2" />
							<XAxis dataKey="month">
								<Label position="left" offset={10}>
									Months
								</Label>
							</XAxis>
							<YAxis
								width={120}
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>
							<Tooltip />
							<Legend />
							<Area
								dot
								type="natural"
								dataKey="Current Loan"
								stroke="red"
								fill="red"
							/>
							<Area
								dot
								type="natural"
								dataKey="Best Offer"
								stroke={SoraTheme.palette.primary.soraBlue}
								fill={SoraTheme.palette.primary.soraBlue}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</Grid>

				<Grid container item xs={12} xl={6} sx={{ marginBottom: 4 }}>
					{currentLoan && bestOffer && (
						<ResponsiveTable
							makeLastBold
							columns={['', 'Current Loan', 'Best Offer']}
							rows={savingsInvestmentPotentialTableRows}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
}

InterestSavingsTab.propTypes = {
	analyticsSavingData: PropTypes.shape({
		bestOffer: PropTypes.shape({
			originalLoanAmount: PropTypes.number,
			totalCostOfDebt: PropTypes.number,
			totalPayment: PropTypes.number
		}),
		currentLoan: PropTypes.shape({
			originalLoanAmount: PropTypes.number,
			totalCostOfDebt: PropTypes.number,
			totalPayment: PropTypes.number
		}),
		interestSavings: PropTypes.number
	})
};

InterestSavingsTab.defaultProps = {
	analyticsSavingData: {
		currentLoan: {},
		bestOffer: {}
	}
};

export default InterestSavingsTab;
