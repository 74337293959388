import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import emailjs from '@emailjs/browser';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert';
import CardItem from 'components/SoraWallet/Cards/CardItem';
import ModalBox from 'components/Modal/Modal';
import { PrimaryButton, TertiaryButton } from 'components/Button/Button';
import Loader from 'components/Loader';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID =
	process.env.REACT_APP_EMAILJS_BOOK_ADVISOR_MEETING_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function FinancialAdvisorCard({ advisorData, clientData }) {
	const [isOpen, setIsOpen] = useState(false);
	const queryClient = useQueryClient();

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const {
		name: advisorName,
		imageUrl: advisorProfileIcon,
		email: advisorEmail,
		companyLogoUrl: advisorFirmLogo,
		company: advisorCompany
	} = advisorData;

	const {
		firstName: clientFirstName,
		lastName: clientLastName,
		email: clientEmail
	} = clientData;

	const { pathname } = useLocation();
	const isClient = !pathname.includes('advisor');

	const submitAdvisorConnectRequest = useMutation(
		async () => {
			const emailSubject = 'Advisor meeting request';

			let emailJsParams = {
				name: `${clientFirstName} ${clientLastName}`,
				emailAddress: clientEmail,
				subject: emailSubject
			};

			const message = `
			Advisor meeting requested
			
			Client Full name: ${clientFirstName} ${clientLastName}
			Client Email: ${clientEmail}
			
			Advisor Name: ${advisorName}
			Advisor Email: ${advisorEmail}
		`;

			emailJsParams = {
				...emailJsParams,
				message
			};

			await emailjs.send(
				EMAIL_SERVICE_ID,
				EMAIL_TEMPLATE_ID,
				emailJsParams,
				EMAIL_PUBLIC_KEY
			);
		},
		{
			onSettled: () => {
				// Set success message
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Your financial advisor will be in touch with you soon.'
				);
				// Close lightbox
				handleClose();
			}
		}
	);

	// Handlers for mutation
	const {
		isLoading,
		isSuccess: advisorRequestSuccess,
		isError: advisorRequestError
	} = submitAdvisorConnectRequest;

	const submitAdvisorConnectRequestForm = (event) => {
		event.preventDefault();
		submitAdvisorConnectRequest.mutate({});
	};

	return (
		<CardItem
			withoutButton
			cardHeading="Your Financial Advisor"
			buttonSx={{ alignSelf: 'center' }}
			sx={{ minHeight: '443px' }}
		>
			{advisorRequestSuccess && <Alert variant="success" />}
			{advisorRequestError && <Alert variant="error" />}
			{advisorProfileIcon ? (
				<Box
					sx={{
						width: 50,
						height: 50,
						borderRadius: 8,
						border: '2px solid #C4C4C4',
						display: 'flex',
						justifyContent: 'center',
						overflow: 'hidden'
					}}
				>
					<Box
						src={advisorProfileIcon}
						component="img"
						width={46}
						height={46}
					/>
				</Box>
			) : (
				<AccountCircleIcon
					sx={{ height: 50, width: 50, color: 'primary.lightGrey' }}
				/>
			)}

			<Typography variant="body1" sx={{ flexGrow: 2 }}>
				{advisorName}
			</Typography>
			{advisorFirmLogo ? (
				<Box
					component="img"
					src={advisorFirmLogo}
					sx={{ maxWidth: '100%' }}
				/>
			) : (
				<Typography variant="body1" sx={{ marginBottom: 0 }}>
					{advisorCompany}
				</Typography>
			)}

			{isClient && (
				<TertiaryButton
					sx={{ minWidth: 145, padding: 1.5, alignSelf: 'center' }}
					onClick={handleOpen}
				>
					Book Advisor Meeting
				</TertiaryButton>
			)}
			<ModalBox
				isOpen={isOpen}
				handleClose={handleClose}
				title="Your Financial Advisor"
				buttonText="yes"
			>
				<Typography variant="body1" sx={{ marginTop: 2 }}>
					Would you like your financial advisor to contact you?
				</Typography>
				{isLoading && <Loader />}
				{!isLoading && (
					<PrimaryButton
						onClick={submitAdvisorConnectRequestForm}
						sx={{ marginTop: 4 }}
					>
						Yes
					</PrimaryButton>
				)}
			</ModalBox>
		</CardItem>
	);
}

FinancialAdvisorCard.propTypes = {
	advisorData: PropTypes.shape({
		company: PropTypes.string,
		companyLogoUrl: PropTypes.string,
		email: PropTypes.string,
		imageUrl: PropTypes.string,
		name: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string
	}).isRequired,

	clientData: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string
	}).isRequired
};

export default FinancialAdvisorCard;
