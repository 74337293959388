import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'services/API/API';

// Api Endpoints
import { getMethodfiLiabilities } from 'shared/api-urls';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

// Our Routes
import { IDENTIFIED_LIABILITIES_ROUTE } from 'routes';

import { LIABILITY_ACCOUNT_CONNECTED } from 'shared/constants';

function useMutateGetMethodfiLiabilities() {
	const navigate = useNavigate();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	return useMutation(
		async (accountHolderId) => {
			const response = await axiosInstance.get(
				`${getMethodfiLiabilities}${accountHolderId}`
			);
			const methodfiData = response.data.data;

			// variable here is not needed but the AWAIT is.
			// stored in a variable for contextual purposes.
			const makeOnboardingStatusUpdateResponse =
				await updateOnboardingStatus.mutate(
					LIABILITY_ACCOUNT_CONNECTED
				);
			return methodfiData;
		},
		{
			onSuccess: () => {
				navigate(IDENTIFIED_LIABILITIES_ROUTE);
			},
			onError: () => {
				navigate('/client/onboarding/unable-to-verify');
			}
		}
	);
}

export default useMutateGetMethodfiLiabilities;
