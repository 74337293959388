import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

const StyledTextfield = styled(TextField)`
	& .MuiOutlinedInput-root {
		border-radius: 2.5rem;
		background-color: #fff;
	}

	& .MuiOutlinedInput-notchedOutline {
		border: 0;
	}
`;
function SearchInput(props) {
	const SoraTheme = useTheme();

	return (
		<StyledTextfield
			{...props}
			variant="outlined"
			size="small"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon
							sx={{
								color: SoraTheme.palette.primary.black
							}}
						/>
					</InputAdornment>
				)
			}}
		/>
	);
}

SearchInput.propTypes = {
	sx: CommonPropTypes.sx,
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.bool,
	helperText: PropTypes.node,
	onChange: PropTypes.func,
	type: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SearchInput.defaultProps = {
	sx: {},
	id: undefined,
	name: undefined,
	label: undefined,
	error: false,
	helperText: undefined,
	onChange: () => {},
	type: 'text',
	defaultValue: undefined,
	value: undefined
};

export default SearchInput;
