import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { maskAsAnotherUser } from 'shared/api-urls';

function useMutateAddUserMask() {
	return useMutation(
		async (accountToMimicData) => {
			const response = await axiosInstance.post(
				maskAsAnotherUser,
				accountToMimicData
			);
			const mockedAccountData = response.data;

			return mockedAccountData;
		},
		{
			onSuccess: () => {},

			onError: (error) => {
				console.error('Error when trying to mask account', error);
			}
		}
	);
}

export default useMutateAddUserMask;
