import { Grid } from '@mui/material';

// Our Components
import AmortizationTable from 'components/Table/AmortizationTable';
import AccordionCard from 'components/SoraWallet/Cards/AccordionCard';

function AmortizationCard({ analyticsAmortizationData }) {
	const { currentLoan, bestOffer } = analyticsAmortizationData;

	const { amortization: currentLoanAmortization } = currentLoan;
	const { amortization: bestOfferAmortization } = bestOffer;

	return (
		<AccordionCard
			cardHeading="Amortization Table"
			open
			accordianSummarySX={{ marginBottom: 5 }}
		>
			<Grid
				container
				item
				spacing={2}
				sx={{ maxHeight: 800, overflowY: 'auto' }}
			>
				<Grid item xs={6}>
					<AmortizationTable
						title="Current Loan"
						rows={currentLoanAmortization ?? []}
					/>
				</Grid>

				<Grid item xs={6}>
					<AmortizationTable
						title="Best Offer"
						rows={bestOfferAmortization ?? []}
					/>
				</Grid>
			</Grid>
		</AccordionCard>
	);
}

export default AmortizationCard;
