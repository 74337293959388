import { Helmet } from 'react-helmet-async';

// Our Components
import BasicOnboardingFrame from 'components/Frame/OnboardingFrame';
import AdditionalDetailsForm from 'components/Client/Onboarding/AdditionalDetailsForm';

function AdditionalDetailsPage() {
	return (
		<>
			<Helmet>
				<title>Client Onboarding - Additional details</title>
			</Helmet>

			<BasicOnboardingFrame
				progress={0}
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto'
				}}
			>
				<AdditionalDetailsForm />
			</BasicOnboardingFrame>
		</>
	);
}

export default AdditionalDetailsPage;
