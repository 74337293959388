import { Box, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

// Our theme
import SoraTheme from 'theme';

// Our components
import ResponsiveTable from 'components/Table/ResponsiveTable';
import SoraToolTip from 'components/Tooltip/index';

// Our utils
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';
import { dollarFormatter } from 'shared/utils';

// const FV_DATA = [
// 	4333, 8927, 13797, 18959, 24430, 30230, 36378, 42894, 49802, 57124, 64886,
// 	73113, 81833, 91077, 100876, 111262, 122272, 133942, 146313, 159426, 173325,
// 	188059, 203676, 220231, 237778, 256379, 276096, 296996, 319149, 3426326
// ];

// const CONTRIBUTION_DATA = [
// 	4333, 8667, 13001, 17335, 21669, 26003, 30337, 34671, 39005, 43339, 47673,
// 	52007, 56341, 60675, 65008, 69342, 73676, 78010, 82344, 86678, 91012, 95346,
// 	99680, 104014, 108348, 112682, 117016, 121350, 125683, 130017
// ];

// const graphData = FV_DATA.map((value, index) => ({
// 	year: index,
// 	FV: value,
// 	contribution: CONTRIBUTION_DATA[index]
// }));

function UpsidePotentialTab({ analyticsUpsidePotentialData }) {
	const { compoundings, currentLoan, bestOffer } =
		analyticsUpsidePotentialData;

	const {
		monthlyMortgagePayment: currentLoanMonthlyMortgagePayment,
		monthlyPaymentSavings,
		remainingMonth,
		upsidePotential,
		amortValueAtMonth,
		upsidePotentialAtEnd
	} = currentLoan;

	const { monthlyMortgagePayment: bestOfferMonthlyMortgagePayment } =
		bestOffer;

	const savingsInvestmentPotentialTableRows = [
		createFormattedRowData(
			'Current Monthly Payment',
			currentLoanMonthlyMortgagePayment,
			bestOfferMonthlyMortgagePayment
		),
		createFormattedRowData(
			'Monthly Payment Savings',
			monthlyPaymentSavings,
			''
		),
		createFormattedRowData('Current Loan Term', remainingMonth, ''),
		[' ', ' ', ' '],
		createFormattedRowData(
			'Saving Investment Potential (Value at end of Current loan)',
			upsidePotential,
			''
		),
		createFormattedRowData('Balance', amortValueAtMonth, ''),
		createFormattedRowData('', '', ''),
		createFormattedRowData(
			'Upside Potential at end of current loan',
			upsidePotentialAtEnd,
			''
		)
	];

	const formattedGraphData = useMemo(() => {
		if (analyticsUpsidePotentialData)
			return compoundings.map(
				({
					year,
					contribution,
					upsidePotential: compoundingUpsidePotential
				}) => ({
					year,
					contribution,
					FV: compoundingUpsidePotential
				})
			);

		return [];
	}, [analyticsUpsidePotentialData]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
				<Typography
					variant="h6"
					sx={{
						color: SoraTheme.palette.primary.soraBlue,
						marginBottom: 6
					}}
				>
					Savings Investment Potential
				</Typography>
				<SoraToolTip text="Upside potential represents the asset growth opportunity achieved refinancing your mortgage to the new loan offer and reinvesting monthly savings at the provided rate of return." />
			</Box>

			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					xl={6}
					sx={{
						marginBottom: 4,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<ResponsiveContainer height={500} width="90%">
						<LineChart data={formattedGraphData ?? []}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="year"
								label={{
									value: 'Year',
									position: 'left',
									offset: 10
								}}
							/>

							<YAxis
								width={120}
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>
							<Tooltip />
							<Legend />
							<Line type="monotone" dataKey="FV" stroke="red" />
							<Line
								type="monotone"
								dataKey="contribution"
								name="Contribution"
								stroke={SoraTheme.palette.primary.soraBlue}
								activeDot={{ r: 8 }}
							/>
						</LineChart>
					</ResponsiveContainer>
				</Grid>

				<Grid item xs={12} xl={6} sx={{ marginBottom: 4 }}>
					<ResponsiveTable
						makeLastBold
						columns={['', 'Current Loan', 'Best Offer']}
						rows={savingsInvestmentPotentialTableRows}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default UpsidePotentialTab;
