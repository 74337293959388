import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Constants
const defaultTableStyles = {
	maxWidth: '100%',
	overflowX: 'auto',
	height: '100%'
};

function ResponsiveTable({ columns, rows, elevation, makeLastBold }) {
	const tableColumns = columns.map((columnHeading) => (
		<TableCell key={uuidv4()}>{columnHeading}</TableCell>
	));

	const tableRows = rows.map((currentRow, index) => {
		const isLastRow = index === rows.length - 1 && makeLastBold;
		const currentTableCells = currentRow.map((currentCell) => (
			<TableCell key={uuidv4()}>
				{isLastRow && <strong>{currentCell}</strong>}
				{!isLastRow && currentCell}
			</TableCell>
		));

		return <TableRow key={uuidv4()}>{currentTableCells}</TableRow>;
	});

	return (
		<TableContainer
			component={Paper}
			sx={defaultTableStyles}
			elevation={elevation ?? 4}
		>
			<Table>
				<TableHead>
					<TableRow>{tableColumns}</TableRow>
				</TableHead>
				<TableBody>{tableRows}</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ResponsiveTable;
