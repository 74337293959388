import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import SearchClients from 'components/FADashboard/SearchClients';

import PropTypes from 'prop-types';

function TableHeadingAndSearch({ tableHeading, deBounceCallBack }) {
	const SoraTheme = useTheme();

	return (
		<Grid item xs={12}>
			<Box sx={{ display: ' flex', alignItems: 'flex-end' }}>
				<Typography
					variant="h5"
					gutterBottom
					component="div"
					sx={{
						fontWeight: '700',
						color: SoraTheme.palette.primary.indigo
					}}
				>
					{tableHeading}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexGrow: 4,
						justifyContent: 'flex-end',
						marginRight: 14
					}}
				>
					<SearchClients
						debounceCallBack={deBounceCallBack}
						sx={{ width: '30%' }}
					/>
				</Box>
			</Box>
		</Grid>
	);
}

TableHeadingAndSearch.propTypes = {
	tableHeading: PropTypes.string.isRequired
};

export default TableHeadingAndSearch;
