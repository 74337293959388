import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

function OffersPageHeading({ OfferType }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const location = useLocation();

	const { clientData } = location.state;

	const { firstName } = clientData;

	const isAdvisor =
		clientData.clientId !== undefined && clientData.clientData !== null;

	const Heading = OfferType.split('_').join(' '); // turns new_loan to new loan

	const headingMessage = isAdvisor ? `${firstName}'s` : 'your';

	return (
		<>
			<Typography variant="h4" gutterBottom color={primary.soraBlue}>
				Here are {headingMessage} best {Heading} options
			</Typography>
			<Typography variant="body2" gutterBottom>
				Click the button below a lender’s logo to execute on a loan
			</Typography>
		</>
	);
}

export default OffersPageHeading;
