import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Typography
} from '@mui/material';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// Our Components
import Alert from 'components/Alert';
import Loader from 'components/Loader';
import NavMenuAdvisor from 'components/NavMenu/NavMenuAdvisor';
import NavMenuClient from 'components/NavMenu/NavMenuClient';

// Our Assets
import SoraLogoBlue from 'assets/images/SoraLogoBlue.svg';

// Our Query Keys
import { ROLE, SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Our Hooks
import useMutateSignOutUser from 'hooks/useMutateSignOutUser';
import useGetProfile from 'hooks/useGetProfile';

function NavMenu() {
	const [clickedOnEmail, setClickedOnEmail] = useState(false);
	const SoraTheme = useTheme();
	const navigate = useNavigate();
	const signOut = useMutateSignOutUser();
	const queryClient = useQueryClient();

	const {
		isLoading,
		error,
		data: userProfileData,
		isSuccess
	} = useGetProfile();

	// Handle user sign out
	const signOutUser = () => {
		signOut.mutate();
	};

	useEffect(() => {
		if (clickedOnEmail) {
			setTimeout(() => setClickedOnEmail(false), 2000);
		}
	}, [clickedOnEmail]);

	useEffect(() => () => setClickedOnEmail(false), []);

	if (isLoading) return <Loader boxSX={{ justifyContent: 'center' }} />;

	if (error) return `An error has occurred: ${error.message}`;

	if (isSuccess) {
		const roleFromApi = userProfileData.role;
		const isClient = roleFromApi === 'CLIENT';

		// Set this user's role in react query
		queryClient.setQueryData(ROLE, roleFromApi);

		const {
			email: currentlyLoggedInUserEmail,
			firstName,
			lastName,
			profilePicUrl,
			companyLogo,
			companyLogoUrl
		} = userProfileData;

		const advisingCompanyLogo = isClient ? companyLogoUrl : companyLogo;

		return (
			<>
				{clickedOnEmail && <Alert variant="success" />}

				{currentlyLoggedInUserEmail && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							marginTop: 4
						}}
						onClick={() => {
							if (!clickedOnEmail) {
								queryClient.setQueryData(
									SUCCESS_MESSAGE_DATA,
									'Copied email address to clipboard.'
								);
								setClickedOnEmail(true);

								navigator.clipboard.writeText(
									currentlyLoggedInUserEmail
								);
							}
						}}
					>
						{advisingCompanyLogo && (
							<Box sx={{ marginBottom: 4 }}>
								<img
									src={advisingCompanyLogo}
									alt="Advising Company's Logo"
									width="inherit"
									height="inherit"
									style={{
										maxWidth: '120px'
									}}
								/>
							</Box>
						)}

						{profilePicUrl && (
							<Box>
								<img
									src={profilePicUrl}
									alt="User Profile"
									width="75"
									style={{
										borderRadius: '50%'
									}}
								/>
							</Box>
						)}
						<Typography
							variant="subtitle1"
							color={SoraTheme.palette.primary.white}
							sx={{
								maxWidth: 220,
								userSelect: 'none',
								marginTop: 2,
								marginBottom: 6
							}}
						>
							{firstName} {lastName}
						</Typography>
					</Box>
				)}

				{/* TOP LIST */}
				<Box
					sx={{
						margin: '0 auto'
					}}
				>
					{isClient ? <NavMenuClient /> : <NavMenuAdvisor />}
				</Box>
				<Box
					sx={{
						display: 'flex',
						height: '100%'
					}}
				/>
				{/* BOTTOM LIST */}
				<Box
					sx={{
						margin: '0 auto'
					}}
				>
					<Box sx={{ marginBottom: 2, textAlign: 'center' }}>
						<img
							src={SoraLogoBlue}
							alt="Sora logo"
							width="104"
							height="38"
						/>
					</Box>
					<List
						sx={{
							marginBottom: SoraTheme.spacing(6)
						}}
					>
						{/* Support link */}
						<ListItem
							disablePadding
							onClick={() => navigate('/contact-us')}
						>
							<ListItemButton>
								<ListItemIcon sx={{ paddingLeft: 1 }}>
									<SupportOutlinedIcon
										sx={{
											color: SoraTheme.palette.primary
												.indigo
										}}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography
										variant="subtitle1"
										sx={{
											color: SoraTheme.palette.primary
												.indigo
										}}
									>
										Support
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>

						{/* Settings link */}
						<ListItem
							disablePadding
							onClick={() => navigate('/settings')}
						>
							<ListItemButton>
								<ListItemIcon sx={{ paddingLeft: 1 }}>
									<SettingsIcon
										sx={{
											color: SoraTheme.palette.primary
												.indigo
										}}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography
										variant="subtitle1"
										sx={{
											color: SoraTheme.palette.primary
												.indigo
										}}
									>
										Settings
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>

						{/* Sign out link */}
						<ListItem disablePadding>
							<ListItemButton onClick={signOutUser}>
								<ListItemIcon sx={{ paddingLeft: 1 }}>
									<LogoutOutlinedIcon
										sx={{
											color: SoraTheme.palette.primary
												.indigo
										}}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography
										variant="subtitle1"
										sx={{
											color: SoraTheme.palette.primary
												.indigo
										}}
									>
										Sign out
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>
				</Box>
			</>
		);
	}
}

export default NavMenu;
