import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom/dist/index';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our Query Keys
import {
	CLIENT_ONBOARDING_ASSETS,
	ERROR_MESSAGE_DATA
} from 'shared/query-keys';

// Our endpoints
import { userAssets } from 'shared/api-urls';

function useGetAssets() {
	const queryClient = useQueryClient();

	const location = useLocation();

	const { state } = location;

	const params = {
		clientId: state?.clientId
	};

	return useQuery(
		CLIENT_ONBOARDING_ASSETS,
		() =>
			axiosInstance
				.get(userAssets, { params })
				.then((res) => res.data.data),
		{
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'We had an issue retrieving your asset information.'
				);
			}
		}
	);
}

export default useGetAssets;
