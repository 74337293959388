import { useState, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { EMAIL } from 'components/Input/Types';
import { useQueryClient, useMutation } from 'react-query';
import { isEmail } from 'validator';
import emailjs from '@emailjs/browser';
import { useTheme } from '@emotion/react';

// Our query keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Our Utils
import { isSubmissionReady } from 'shared/utils';

// Icons
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import Input from 'components/Input/TextInput';
import ModalBox from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader';

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_NEW_LOAN_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function NewLoanCard() {
	const queryClient = useQueryClient();
	const SoraTheme = useTheme();

	// Gathering user data from react query
	const userProfileData = queryClient.getQueryData(USERPROFILEDATA);
	const { firstName, lastName, email: loadedEmail } = userProfileData;

	// State data
	const [isOpen, setIsOpen] = useState(false);
	const [email, setEmail] = useState(loadedEmail);
	const [loanType, setLoanType] = useState('');

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const handleLoanTypeChange = (value) => {
		setLoanType(value);
	};

	const formData = [email, loanType];

	const isFormReady = useMemo(
		() => isSubmissionReady(formData) && isEmail(email),
		[...formData]
	);

	const sendNewLoanInquiry = useMutation(async () => {
		const emailSubject = 'New Loan Request';

		let emailJsParams = {
			name: `${firstName} ${lastName}`,
			subject: emailSubject
		};

		const message = `
			${firstName} ${lastName} is interested in taking out a new ${loanType}.

			New Loan Request:
			Full name: ${firstName} ${lastName}
			Email: ${email}
			Loan type: ${loanType}
		`;

		emailJsParams = {
			...emailJsParams,
			emailAddress: email,
			loanType,
			message
		};

		await emailjs.send(
			EMAIL_SERVICE_ID,
			EMAIL_TEMPLATE_ID,
			emailJsParams,
			EMAIL_PUBLIC_KEY
		);
		handleLoanTypeChange('');
		handleClose();
	});

	const { isLoading } = sendNewLoanInquiry;

	const submitNewLoanRequestForm = (event) => {
		event.preventDefault();
		sendNewLoanInquiry.mutate({});
	};

	return (
		<CardItem
			sx={{
				alignItems: 'center',
				border: '2px solid',
				borderColor: SoraTheme.palette.primary.indigo
			}}
			cardHeading="Request New Loan"
			buttonText="Request"
			onClickHandler={handleOpen}
		>
			<Box sx={{ height: '100%' }}>
				<HomeIcon
					sx={{
						fontSize: 40,
						color: '#89accc',
						marginRight: 1
					}}
				/>
				<DirectionsCarIcon
					sx={{
						fontSize: 40,
						color: '#da9e74',
						marginLeft: 1
					}}
				/>
				<br />
				<LocalAtmIcon
					sx={{
						fontSize: 40,
						color: '#98ba81',
						marginRight: 1
					}}
				/>
				<SchoolIcon
					sx={{
						fontSize: 40,
						color: '#e6c36f',
						marginLeft: 1
					}}
				/>

				<ModalBox
					isOpen={isOpen}
					handleClose={handleClose}
					title="Request New Loan Support"
				>
					<Box component="form" noValidate autoComplete="off">
						<Grid container spacing={2}>
							<Grid item xs={6}>
								{/* Email */}
								<Input
									type={EMAIL}
									label="Email Address"
									value={email}
									onChange={handleEmailChange}
									error={!isEmail(email)}
									helperText="Please enter a valid email address"
									sx={{ marginTop: 4, marginBottom: 2 }}
								/>

								{/* Type of new loan */}
								<Dropdown
									id="loanType"
									label="Type of loan"
									placeholder="Which kind of loan?"
									items={[
										'Home loan',
										'Automotive loan',
										'Personal loan',
										'Student loan'
									]}
									selected={loanType}
									type="visible"
									onChange={handleLoanTypeChange}
									sx={{
										marginBottom: 4,
										width: '100%'
									}}
								/>

								{isLoading && <Loader />}
								{!isLoading && (
									<PrimaryButton
										onClick={submitNewLoanRequestForm}
										sx={{
											marginTop: 4
										}}
										disabled={!isFormReady}
									>
										Submit
									</PrimaryButton>
								)}
							</Grid>
						</Grid>
					</Box>
				</ModalBox>
			</Box>
		</CardItem>
	);
}

export default NewLoanCard;
