import { format } from 'date-fns';
import englishLocale from 'date-fns/locale/en-US';

/**
 * Accepts a valid date string in the following format yyyy-MM-dd
 *
 * Which gets converted and returned as such MM/dd/yyyy
 *
 * @param {string} date This input argument is either a string or Date Object
 * @returns {string} `properlyFormattedDate` MM/dd/yyyy
 */

function convertDateToDatePickerFormat(date) {
	try {
		const splitDate = date.split('-');
		const year = Number.parseInt(splitDate[0], 10);
		const month = Number.parseInt(splitDate[1], 10) - 1;
		const day = Number.parseInt(splitDate[2], 10);

		const dateAsDateObject = new Date(year, month, day);

		const properlyFormattedDate = format(dateAsDateObject, 'MM/dd/yyyy', {
			locale: englishLocale
		});

		return properlyFormattedDate;
	} catch (e) {
		return '';
	}
}

export default convertDateToDatePickerFormat;
