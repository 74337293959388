import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';

// Our components
import NavMenu from 'components/NavMenu/NavMenu';
import Footer from 'components/Footer/Footer';

const StyledGrid = styled(Grid)(`
	margin: 0;

	& .MuiGrid-item {
		padding-top: 0;
		padding-left: 0;
	}
`);

function BasicNavFrame({ backEnabled, children, footer }) {
	const SoraTheme = useTheme();
	// const { children } = props;
	// A basic 12 column grid split:
	// [ 2 columns | 10 columns ]
	// 2 columns: will hold the navigation menu
	// 10 columns:  Within these 10 cols we reimplemented another 12 column grid,
	// giving us more spacing options for the right side of the frame.
	const navigation = useNavigate();
	return (
		<StyledGrid
			container
			spacing={2}
			sx={{ height: '100%', width: '100%' }}
		>
			<Grid item xs={2}>
				<Box
					sx={{
						position: 'fixed',
						background:
							SoraTheme.palette.gradient
								.soraBlueToWhiteLinearGradient,
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						width: '15%'
					}}
				>
					{/* Navigation menu here */}
					<NavMenu />
				</Box>
			</Grid>

			<StyledGrid
				container
				item
				xs={10}
				sx={{ height: '100vh', width: '100%' }}
			>
				<Grid item xs={1}>
					{backEnabled && (
						<Box
							sx={{
								height: 64,
								width: 64,
								padding: '12px',
								cursor: 'pointer'
							}}
							onClick={() => navigation(-1)}
						>
							<IconButton aria-label="back">
								<KeyboardBackspaceOutlinedIcon />
							</IconButton>
						</Box>
					)}
				</Grid>

				<Grid item xs={11} sx={{ marginTop: 10 }}>
					{children}
				</Grid>

				{footer && (
					<Grid item xs={12}>
						{footer}
					</Grid>
				)}
				<Grid
					item
					xs={12}
					sx={{
						alignSelf: 'flex-end',
						marginTop: 10
					}}
				>
					<Footer />
				</Grid>
			</StyledGrid>
		</StyledGrid>
	);
}

export default BasicNavFrame;
