import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';
import { Box, Grid, Tooltip } from '@mui/material';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';

// Utilities
import { dollarFormatter, extractSoraScore } from 'shared/utils';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

function LiabilitiesTable({ liabilities }) {
	// the icons are based off what type of loan it is. home mortgage
	// car is a car loan
	// cap is student loan
	// money is money loan

	// currently in our liabilities table there isn't an id associated with the user so i'm created one here per row

	const liabilityData = useMemo(
		() =>
			liabilities.map((userLiabilityData) => ({
				...userLiabilityData,
				id: uuidv4()
			})),
		[liabilities]
	);

	const nameKey = 'name';
	const soraScoreKey = 'soraScore';
	const balanceKey = 'outstandingBalance';
	const untappedSavingsKey = 'untappedSavings';
	const upsidePotentialKey = 'upsidePotential';
	const onboardingStatusKey = 'status';
	const numberOfLoansKey = 'numberOfLoans';

	const columnsLiability = [
		ColumnItem(nameKey, 'Name', 2),
		ColumnItem(numberOfLoansKey, 'Loans', 0.7, {
			valueFormatter: (params) => {
				const numberOfLoans = params.value;

				if (numberOfLoans || numberOfLoans === 0) return numberOfLoans;

				return 'NA';
			}
		}),

		ColumnItem(soraScoreKey, 'Sora Score', 0.7, {
			valueGetter: (params) => {
				const rowSoraScore = params.row[soraScoreKey];
				if (rowSoraScore) {
					const soraGrade = `${extractSoraScore(rowSoraScore)}`;
					return soraGrade;
				}
				return 'NA';
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(untappedSavingsKey, 'Untapped Savings', 1, {
			valueFormatter: (params) => {
				const untappedSavings = params.value;
				if (!untappedSavings && untappedSavings !== 0) return 'NA';
				if (untappedSavings === 0) return '$0';
				return dollarFormatter.format(untappedSavings);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(upsidePotentialKey, 'Upside Potential', 1, {
			valueFormatter: (params) => {
				const upsidePotential = params.value;
				if (!upsidePotential && upsidePotential !== 0) return 'NA';
				if (upsidePotential === 0) return '$0';
				return dollarFormatter.format(upsidePotential);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(balanceKey, 'Outstanding Balance', 1, {
			valueFormatter: (params) => {
				const outstandingBalance = params.value;
				if (!outstandingBalance && outstandingBalance !== 0)
					return 'NA';
				if (outstandingBalance === 0) return '$0';
				return dollarFormatter.format(outstandingBalance);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),

		ColumnItem(onboardingStatusKey, 'Onboarding Status', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),

			valueGetter: (params) => {
				const currentClientOnboardingStatus =
					params.row[onboardingStatusKey];

				const formattedClientStatus = sentenceCapitalize(
					currentClientOnboardingStatus,
					'_'
				);

				return formattedClientStatus;
			}
		})
	];

	const columnWithToolTips = columnsLiability.map((columnDef) => ({
		...columnDef,
		renderCell: (params) => {
			const { formattedValue, row } = params;

			const onboardingStatusIsActive =
				row[onboardingStatusKey] === 'ACTIVE';

			if (onboardingStatusIsActive) return null;
			return (
				<Tooltip
					title="This client hasn’t fully onboarded to Sora, so we’re unable to complete their Sora wallet."
					placement="top"
				>
					<Box
						className="MuiDataGrid-cellContent"
						style={{ width: '100%' }}
					>
						{formattedValue}
					</Box>
				</Tooltip>
			);
		}
	}));

	return (
		<Grid item xs={12} sx={{ paddingRight: 2 }}>
			<DataTable
				autoPageSize
				columns={columnWithToolTips}
				rows={liabilityData}
				rowsPerPageOptions={5}
				withRowColorCheck
			/>
		</Grid>
	);
}

export default LiabilitiesTable;
