import { Box } from '@mui/material';

// Assets
import SoraLogo from 'assets/images/SoraLogoBlack.svg';
import SignInImage from 'assets/images/signInBanner.svg';

// Pure component with no props
// renders the box that contains the sora logo and art assocaited with it.
function LoginArtAndSoraLogo() {
	return (
		<Box sx={{ height: '100%', display: 'block' }}>
			{/* SideBar Art */}
			<Box
				component="img"
				src={SignInImage}
				alt="Sign in"
				sx={{ height: '100%', width: '100%', display: 'block' }}
			/>
			{/* Sora Logo */}
			<Box
				component="img"
				src={SoraLogo}
				alt="Sora Logo"
				sx={{
					position: 'absolute',
					height: '10%',
					width: '10%',
					top: '20px',
					left: '20px'
				}}
			/>
		</Box>
	);
}

export default LoginArtAndSoraLogo;
