/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTheme } from '@emotion/react';
import {
	Box,
	Typography,
	List,
	Link as ExternalLink,
	ListItem,
	ListItemText,
	ListItemButton
} from '@mui/material';
import { Link } from 'react-router-dom';

// Our Components
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SoraLogo from 'assets/images/SoraLogoBlack.svg';
import NewsletterSignupForm from './NewsletterSignup';

function Footer({ sx }) {
	const SoraTheme = useTheme();

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				...sx
			}}
		>
			<Box sx={{ flexGrow: 1 }}>
				<Link to="/">
					<img
						src={SoraLogo}
						alt="Sora Logo"
						style={{
							width: '121px',
							marginTop: '32px',
							marginBottom: '25px'
						}}
					/>
				</Link>
				<Typography
					variant="body1"
					gutterBottom
					component="div"
					sx={{
						maxWidth: '372px',
						marginBottom: '25px'
					}}
				>
					Sora finds you better deals for your new or existing home,
					auto, and student loans and seamlessly moves you onto them.
				</Typography>

				{/* Twitter link */}
				<ExternalLink
					href="https://twitter.com/SoraFinanceUS"
					sx={{
						textDecorationColor: SoraTheme.palette.primary.black
					}}
				>
					<TwitterIcon
						sx={{
							color: SoraTheme.palette.primary.black,
							marginRight: '10px'
						}}
					/>
				</ExternalLink>

				{/* LinkedIn link */}
				<ExternalLink
					href="https://www.linkedin.com/company/sorafinance/"
					sx={{
						textDecorationColor: SoraTheme.palette.primary.black
					}}
				>
					<LinkedInIcon
						sx={{
							color: SoraTheme.palette.primary.black
						}}
					/>
				</ExternalLink>
			</Box>

			<Box
				sx={{
					flexGrow: 1
				}}
			>
				<List>
					{/* About Sora link */}
					<ListItem disablePadding>
						<ExternalLink
							href="https://www.sorafinance.com/"
							sx={{
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							<ListItemButton>
								<ListItemText
									sx={{
										color: SoraTheme.palette.primary.black
									}}
								>
									<Typography variant="subtitle1">
										About Sora
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ExternalLink>
					</ListItem>

					{/* Terms of Use link */}
					<ListItem disablePadding>
						<ExternalLink
							href="https://www.sorafinance.com/terms"
							sx={{
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							<ListItemButton>
								<ListItemText
									sx={{
										color: SoraTheme.palette.primary.black
									}}
								>
									<Typography variant="subtitle1">
										Terms of Use
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ExternalLink>
					</ListItem>

					{/* Privacy Policy link */}
					<ListItem disablePadding>
						<ExternalLink
							href="https://www.sorafinance.com/privacy-policy"
							sx={{
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							<ListItemButton>
								<ListItemText
									sx={{
										color: SoraTheme.palette.primary.black
									}}
								>
									<Typography variant="subtitle1">
										Privacy Policy
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ExternalLink>
					</ListItem>

					{/* Contact Us link */}
					<ListItem disablePadding>
						<Link
							to="/contact-us"
							style={{
								textDecoration: 'underline',
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							<ListItemButton>
								<ListItemText
									sx={{
										color: SoraTheme.palette.primary.black
									}}
								>
									<Typography variant="subtitle1">
										Contact Us
									</Typography>
								</ListItemText>
							</ListItemButton>
						</Link>
					</ListItem>
				</List>
			</Box>

			<NewsletterSignupForm />
		</Box>
	);
}

export default Footer;
