import styled from '@emotion/styled';
import { Grid } from '@mui/material';

import PropTypes from 'prop-types';
import BasicFrame from 'components/Frame/Basic';
import BackButton from 'components/Frame/BackButton';
// import ProgressMeter from 'components/ProgressMeter/ProgressMeter';

import ClientOnboardingNav from 'components/NavMenu/ClientOnboardingNav';

const StyledGrid = styled(Grid)(`
	margin: 0;

	& .MuiGrid-item {
		padding-top: 0;
		padding-left: 0;
	}
`);

function OnboardingFrame({ backEnabled, progress, children, sx }) {
	// A basic 12 column grid split:
	// [ 3 columns | 9 columns ]
	// 3 columns: will hold the onboarding progress meter w/ illustration
	// 9 columns: Within these 9 cols we re-implemented another 12 column grid,
	// giving us more spacing options for the right side of the frame.
	return (
		<StyledGrid
			container
			spacing={2}
			sx={{
				height: '100vh',
				width: '100vw'
			}}
		>
			<Grid item xs={2}>
				<ClientOnboardingNav progress={progress} />
			</Grid>

			<Grid item xs={10}>
				<BasicFrame sx={{ ...sx }}>
					<Grid item xs={1}>
						<BackButton backEnabled={backEnabled} />
					</Grid>

					{/* New row first grid item is for left hand spacing on pages */}
					<Grid item xs={11}>
						{children}
					</Grid>
				</BasicFrame>
			</Grid>
		</StyledGrid>
	);
}

OnboardingFrame.defaultProps = {
	backEnabled: false,
	progress: 0
};
OnboardingFrame.propTypes = {
	backEnabled: PropTypes.bool,
	progress: PropTypes.number
};

export default OnboardingFrame;
