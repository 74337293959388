import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Link,
	Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import React from 'react';

import { SecondaryButton } from 'components/Button/Button';

const CardImage = styled(CardMedia)`
	margin: 0;
	user-drag: none;
	user-select: none;
	-webkit-user-drag: none;
`;

// eslint-disable-next-line no-unused-vars
const withRefButton = React.forwardRef((props, ref) => (
	<SecondaryButton {...props}>{props.children}</SecondaryButton>
));

function NewsfeedItem({ logo, link, newsHeading }) {
	const SoraTheme = useTheme();

	const { indigo } = SoraTheme.palette.primary;

	return (
		<Card elevation={0} sx={{ marginBottom: 4 }}>
			<CardContent>
				{/* CardMedia should be adjusted by size on image card. Less tricky if the image provided is a svg */}
				<CardImage
					component="img"
					image={logo}
					alt="Company logo"
					sx={{
						width: '5rem',
						paddingBottom: 2
					}}
				/>

				<Typography
					variant="subtitle1"
					component="p"
					gutterBottom
					sx={{ marginTop: 1 }}
				>
					{newsHeading}
				</Typography>
			</CardContent>
			<CardActions>
				<Link
					href={link}
					target="_blank"
					rel="noopener"
					component={withRefButton}
					sx={{
						color: indigo,
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: 0.5
					}}
				>
					Read now
				</Link>
			</CardActions>
		</Card>
	);
}

NewsfeedItem.propTypes = {
	logo: PropTypes.string.isRequired,
	newsHeading: PropTypes.string.isRequired
};

export default NewsfeedItem;
