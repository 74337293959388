import { Box, Typography } from '@mui/material';

function TotalLiabilitiesCardHeading() {
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				borderBottom: '2px solid #C7C7C7',
				paddingBottom: 1
			}}
		>
			<Typography sx={{ fontWeight: 700, width: 157 }}>Type</Typography>
			<Typography sx={{ fontWeight: 700, width: 200 }}>
				Details
			</Typography>
			<Typography sx={{ fontWeight: 700, width: 157 }}>Rate</Typography>
			<Typography sx={{ fontWeight: 700, width: 157 }}>
				Balance
			</Typography>
			<Typography sx={{ fontWeight: 700, width: 157 }}>
				Monthly
				<br />
				Payment
			</Typography>
			<Typography sx={{ fontWeight: 700, width: 157 }}>
				Data
				<br />
				Confidence
			</Typography>
			<Typography sx={{ fontWeight: 700, width: 157 }}>
				Last
				<br />
				Updated
			</Typography>
			<Box sx={{ width: 157 }} />
		</Box>
	);
}

export default TotalLiabilitiesCardHeading;
