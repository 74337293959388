import { useLocation, useParams } from 'react-router-dom';
// Our Components
import DynamicTabs from 'components/Tabs/DynamicTabs';

// Our Constants
import {
	CREDITCARD,
	MORTGAGE,
	HELOC,
	MORTGAGE_TAB_TEXT
} from 'shared/constants';

// Our utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Our routes
import { OTHER_LOAN_ROUTE, LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Local Constants
const OTHER_LOAN_TAB_TEXT = 'Other';

function getActiveTab(pathname, tradeLineId, tabItems, liabilities) {
	const isOtherLoanRoute = pathname === OTHER_LOAN_ROUTE;

	// if its otherLoanRoute return the tabIndex of credit card
	if (isOtherLoanRoute) {
		const otherLoanTabIndex = tabItems.findIndex(
			({ type }) => type === OTHER_LOAN_TAB_TEXT
		);
		return otherLoanTabIndex;
	}

	const isAdditionalItem = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);
	// if additional is in the route it means we're not looking at a specific tradeLine and therefore the tab should just indicate additional
	if (isAdditionalItem) {
		const additionalItemTabIndex = tabItems.length - 1;
		return additionalItemTabIndex;
	}

	const tradeLineIds = liabilities.map(({ tradelineId }) => tradelineId);

	const currentActiveTab = tradeLineIds.indexOf(+tradeLineId);

	return currentActiveTab;
}

function LiabilityOnboardTabs({ liabilities }) {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();
	// this determines what data populates the tabs. type: string here also formats the string so modify it here if you're interested in that
	const filteredTabItems = liabilities.map(({ tradeLineType }) => {
		// if CREDITCARD OR HELOC tradeLineType then we return tab name of 'Other'
		if (tradeLineType === CREDITCARD || tradeLineType === HELOC)
			return { type: OTHER_LOAN_TAB_TEXT };
		if (tradeLineType === MORTGAGE) return { type: MORTGAGE_TAB_TEXT };
		return {
			type: normalizeLoanType(tradeLineType)
		};
	});

	const tabItems = [...filteredTabItems, { type: 'Additional' }];

	const tabPanelItems = liabilities
		.map(({ lender, tradeLineType }) => {
			// if CREDITCARD OR HELOC tradeLineType then we return blank string
			if (tradeLineType === CREDITCARD || tradeLineType === HELOC)
				return { panelItemText: '' };
			return {
				panelItemText: lender
			};
		})
		.concat([{ panelItemText: '' }]);

	const currentActiveTab = getActiveTab(
		pathname,
		tradeLineId,
		tabItems,
		liabilities
	);

	return (
		<DynamicTabs
			sx={{ marginTop: 10, marginBottom: 5 }}
			tabItems={tabItems}
			tabPanelItems={tabPanelItems}
			tabsSX={{ marginBottom: 2 }}
			tabItemSX={{ cursor: 'default', textTransform: 'none' }}
			variant="scrollable"
			value={currentActiveTab}
		/>
	);
}

export default LiabilityOnboardTabs;
