import Carousel from 'react-multi-carousel';
import { v4 as uuidv4 } from 'uuid';
import { Box, Typography } from '@mui/material';
import { useMemo, useCallback } from 'react';
import styled from '@emotion/styled';

// Our Assets
import slideOne from 'assets/images/welcome_onboard_screenshots/slideOne.png';
import slideTwo from 'assets/images/welcome_onboard_screenshots/slideTwo.png';
import slideThree from 'assets/images/welcome_onboard_screenshots/slideThree.png';

// Our Components
import Modal from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';

// Our Constants

// Note: Its important to keep this config the same.
const CarouselBreakPoints = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 6000, min: 0 },
		items: 1,
		slidesToSlide: 1
	}
};

const FIVE_SECONDS = 1000 * 5; // 1000 ms = 1s * 5 => 5s

const SORA_WELCOME_MESSAGES = [
	{
		message:
			'Save money and get the best deals on new loans. On average, Sora users save $28k on mortgages and $6k on auto loans!',
		image: slideOne
	},
	{
		message:
			"Onboarding takes just a few minutes to complete. We'll keep your data up to date automatically.",
		image: slideTwo
	},
	{
		message:
			"What are you waiting for? We're excited to build your Sora Wallet to optimize your finances.",
		image: slideThree
	}
];

const StyledImage = styled(Box)`
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
`;

function CarouselOnboardItem({ image, message, withGettingStarted }) {
	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				paddingBottom: 2
			}}
		>
			<Box
				sx={{
					width: '50%',
					height: '100%',
					userSelect: 'none',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}
			>
				<Typography variant="subtitle1">{message}</Typography>
			</Box>
			<StyledImage
				component="img"
				src={image}
				sx={{
					maxWidth: '50%',
					height: 'auto',
					marginLeft: 8,
					userDrag: 'none',
					userSelect: 'none',
					border: '1px solid grey'
				}}
			/>
		</Box>
	);
}

function ButtonGroup({ next, previous, goToSlide, ...rest }) {
	const {
		carouselState: { currentSlide },
		setIsModalOpen
	} = rest;

	const buttonText = currentSlide === 2 ? 'Onboard' : 'Next';

	return (
		<div className="carousel-button-group">
			<PrimaryButton
				onClick={() => {
					const isLastSlide = currentSlide === 2;

					if (!isLastSlide) {
						next();
						return;
					}
					setIsModalOpen(false);
				}}
			>
				{buttonText}
			</PrimaryButton>
		</div>
	);
}

function WelcomeModal({ isModalOpen, setIsModalOpen }) {
	const carouselItems = useMemo(
		() =>
			SORA_WELCOME_MESSAGES.map(({ message, image }) => (
				<CarouselOnboardItem
					image={image}
					message={message}
					key={uuidv4()}
				/>
			)),
		[]
	);

	const handleClose = useCallback(() => setIsModalOpen(false), []);

	return (
		<Modal
			isOpen={isModalOpen}
			handleClose={handleClose}
			title="Welcome to Sora"
			sx={{
				'& .react-multi-carousel-dot-list': {
					paddingBottom: 2
				}
			}}
		>
			<Carousel
				responsive={CarouselBreakPoints}
				autoPlay
				autoPlaySpeed={FIVE_SECONDS}
				infinite={false}
				arrows={false}
				slidesToSlide={1}
				customButtonGroup={
					<ButtonGroup setIsModalOpen={setIsModalOpen} />
				}
				renderButtonGroupOutside
				showDots
				renderDotsOutside
			>
				{carouselItems}
			</Carousel>
		</Modal>
	);
}

export default WelcomeModal;
