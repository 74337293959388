import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Link, useParams } from 'react-router-dom';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import { TertiaryButton } from 'components/Button/Button';

// Our Utils
import { dollarFormatter } from 'shared/utils';

// Our Routes
import { ASSETS_ROUTE } from 'routes';

function AssetsCard({ assets }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const params = useParams();

	const clientId = params ?? {};

	const formattedAssets = dollarFormatter.format(assets);

	return (
		<CardItem
			sx={{ alignItems: 'center', height: 'inherit', flexGrow: 1 }}
			withoutButton
			cardHeading="Assets"
		>
			<Box sx={{ flexGrow: 2 }}>
				<Typography
					variant="h5"
					color={primary.indigo}
					fontWeight={700}
					sx={{ marginTop: 1, marginBottom: 1 }}
				>
					{formattedAssets ?? 'NA'}
				</Typography>
			</Box>

			<Link
				to={ASSETS_ROUTE}
				state={{ fromWallet: true, ...clientId }}
				style={{ width: 'fit-content', textDecoration: 'none' }}
			>
				<TertiaryButton sx={{ minWidth: 145, padding: 1.5 }}>
					Update
				</TertiaryButton>
			</Link>
		</CardItem>
	);
}

export default AssetsCard;
