import { Box, Paper, Typography } from '@mui/material/index';
import { useTheme } from '@emotion/react/';
import { useMutation, useQueryClient } from 'react-query';
import emailjs from '@emailjs/browser';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert/index';
import Loader from 'components/Loader/index';
import { TextButton } from 'components/Button/Button';

// Assets
import TinySoraInsightsLogo from 'assets/icons/tinySoraLogo.svg';
import OnboardingNavImg from 'assets/images/OnboardingNavImg.png';

import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_NEW_LOAN_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function InsightsCard({ isClient, clientData, insightMessage, sx }) {
	const location = useLocation();
	const queryClient = useQueryClient();

	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const { firstName, lastName, email } = clientData;

	const currentUrl = location.pathname;

	const isWalletPage = currentUrl.includes('liability');

	const successMessage = isClient
		? 'Your financial advisor will be in touch with you soon.'
		: 'Sora will be in touch with you soon.';

	// isClient = Your financial advisor will be in touch with you soon.
	// isAdvisor = Sora Your financial advisor will be in touch with you soon.

	const sendNewLoanInquiry = useMutation(
		async () => {
			const emailSubject = `New Insight triggered | ${firstName} ${lastName}`;

			let emailJsParams = {
				name: `${firstName} ${lastName}`,
				subject: emailSubject
			};

			const message = `
			${firstName} ${lastName} is interested in this insight:

			Email: ${email}
			
			${insightMessage}
		`;

			emailJsParams = {
				...emailJsParams,
				emailAddress: email,
				message
			};

			await emailjs.send(
				EMAIL_SERVICE_ID,
				EMAIL_TEMPLATE_ID,
				emailJsParams,
				EMAIL_PUBLIC_KEY
			);
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(SUCCESS_MESSAGE_DATA, successMessage);
			}
		}
	);

	const { isLoading } = sendNewLoanInquiry;

	if (!isWalletPage)
		return (
			<Paper
				elevation={5}
				sx={{
					padding: 3,
					marginLeft: 7,
					marginRight: 7,
					marginBottom: 2,
					marginTop: 2,
					borderRadius: 6,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					minHeight: 250,
					...sx
				}}
			>
				{sendNewLoanInquiry?.isSuccess &&
					queryClient.getQueryData(SUCCESS_MESSAGE_DATA) && (
						<Alert variant="success" />
					)}
				<Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
					<Box
						component="img"
						src={TinySoraInsightsLogo}
						sx={{ width: 30, height: 30 }}
					/>
					<Typography
						variant="subtitle1"
						sx={{ color: primary.main }}
					>
						Sora Insight
					</Typography>
				</Box>
				<Typography
					variant="body1"
					sx={{ marginTop: 1, marginBottom: 2 }}
				>
					{insightMessage}
				</Typography>
				{sendNewLoanInquiry.isLoading && <Loader />}
				{!sendNewLoanInquiry.isLoading && (
					<TextButton
						sx={{ color: primary.indigo }}
						onClick={() => sendNewLoanInquiry.mutate({})}
					>
						Request More Information
					</TextButton>
				)}
			</Paper>
		);

	return (
		<Paper
			elevation={5}
			sx={{
				marginLeft: 3,
				marginRight: 3,
				marginBottom: 2,
				marginTop: 2,
				borderRadius: 6,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-evenly',
				minHeight: 250,
				alignItems: 'center',
				overflow: 'hidden',
				...sx
			}}
		>
			{sendNewLoanInquiry?.isSuccess &&
				queryClient.getQueryData(SUCCESS_MESSAGE_DATA) && (
					<Alert variant="success" />
				)}
			<Box
				component="img"
				src={OnboardingNavImg}
				sx={{
					width: '130px',
					height: '100%',
					borderRadiusBottomLeft: 6,
					marginRight: 4
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					flexDirection: 'column',
					gap: 4,
					flexGrow: 2
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						gap: 1,
						flexGrow: 2
					}}
				>
					<Box
						component="img"
						src={TinySoraInsightsLogo}
						sx={{ width: 30, height: 30 }}
					/>
					<Typography
						variant="subtitle1"
						sx={{ color: primary.main }}
					>
						SORA INSIGHT
					</Typography>
				</Box>

				<Typography
					variant="body1"
					sx={{ marginTop: 1, marginBottom: 2 }}
				>
					{insightMessage}
				</Typography>
			</Box>
			{isLoading && <Loader size={60} boxSX={{ marginRight: 3 }} />}
			{!isLoading && (
				<TextButton
					sx={{
						color: primary.indigo,
						marginLeft: 4,
						minWidth: '250px'
					}}
					onClick={() => sendNewLoanInquiry.mutate({})}
				>
					Request More Information
				</TextButton>
			)}
		</Paper>
	);
}

InsightsCard.propTypes = {
	insightMessage: PropTypes.string.isRequired
};

export default InsightsCard;
