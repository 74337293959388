import { FALOANSAPPLIED } from 'shared/query-keys';
import { loanAppliedUrl } from 'shared/api-urls';
import axiosInstance from 'services/API/API';
import { useQuery } from 'react-query';

function useGetLoanApplied() {
	return useQuery(FALOANSAPPLIED, () =>
		axiosInstance.post(loanAppliedUrl, {}).then((res) => res.data.data)
	);
}

export default useGetLoanApplied;
