import { Box, Typography } from '@mui/material/index';

// Our Components
import Loader from 'components/Loader/index';

function LiablitiesProcessing() {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				marginTop: 20
			}}
		>
			<Typography variant="h4" color="primary.soraBlue">
				Please Do Not Refresh The Page
			</Typography>
			<Typography
				variant="h5"
				sx={{
					marginTop: 5,
					marginBottom: 10
				}}
			>
				Sora is working to display and analyze your existing debt.
			</Typography>
			<Loader size={80} boxSX={{ marginBottom: 10 }} />
		</Box>
	);
}

export default LiablitiesProcessing;
