import { Skeleton } from '@mui/material/index';
import { generatePath, useNavigate } from 'react-router-dom';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { dollarFormatter } from 'shared/utils';

// Routes
import { ADVISOR_WALLET_LIABILITY } from 'routes';

function QueryEngineTable({ queryResults, isLoading }) {
	const navigate = useNavigate();

	// data Keys
	const tradelineType = 'tradelineType';
	const lender = 'lender';
	const clientName = 'clientName';
	const interestRate = 'interestRate';
	const outstandingBalance = 'outstandingBalance';
	const monthlyPayment = 'monthlyPayment';
	const untappedSavings = 'untappedSavings';
	const upsidePotential = 'upsidePotential';
	const equityAvailble = 'equityAvailable';

	const tableHeadings = [
		ColumnItem(tradelineType, 'Liability', 1.5, {
			valueGetter: (params) => {
				const currentTradelineType = params.row[tradelineType];

				if (currentTradelineType === 'CREDITCARD') return 'Credit Card';
				const formattedTradelineType = normalizeLoanType(
					params.row[tradelineType]
				);

				return formattedTradelineType;
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(lender, 'Lender', 2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(clientName, 'Client Name', 2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(interestRate, 'Interest Rate', 2, {
			valueFormatter: (params) => `${params.value}%`,
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(outstandingBalance, 'Outstanding Balance', 2, {
			valueFormatter: (params) => dollarFormatter.format(params.value),
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(monthlyPayment, 'Monthly Payment', 2, {
			valueFormatter: (params) => dollarFormatter.format(params.value),

			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(untappedSavings, 'Untapped Savings', 2, {
			valueFormatter: (params) => {
				const currentUntappedSavings = params.value;

				if (!currentUntappedSavings) return 'NA';

				return dollarFormatter.format(currentUntappedSavings);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(upsidePotential, 'Upside Potential', 2, {
			valueFormatter: (params) => {
				const currentUpsidePotential = params.value;

				if (!currentUpsidePotential) return 'NA';

				return dollarFormatter.format(currentUpsidePotential);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(equityAvailble, 'Equity Available', 2, {
			valueFormatter: (params) => {
				const currentEquityAvailable = params.value;

				if (!currentEquityAvailable) return 'NA';

				return dollarFormatter.format(currentEquityAvailable);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		})
	];

	const queryResultsWithId = queryResults.map((item) => ({
		...item,
		id: item.tradelineId
	}));

	if (isLoading) return <Skeleton height={800} width="100%" />;

	return (
		<DataTable
			enableToolbar
			columns={tableHeadings}
			rows={queryResultsWithId}
			handleRowClick={(liabilityClicked) => {
				const {
					tradelineType: tradelineTypeClicked,
					tradelineId,
					clientId
				} = liabilityClicked;

				if (tradelineTypeClicked === 'CREDITCARD') return;

				// we can take them somewhere
				const route = generatePath(
					`${ADVISOR_WALLET_LIABILITY}:clientId/liability/:tradelineId`,
					{
						clientId,
						tradelineId
					}
				);

				navigate(route);
			}}
		/>
	);
}

export default QueryEngineTable;
