import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';

import {
	Box,
	List,
	ListItemButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	useMediaQuery
} from '@mui/material';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// Our Components
import SoraLogo from 'components/NavMenu/NavBarComponents/SoraLogoComponent';
import ProgressMeter from 'components/ProgressMeter/ProgressMeter';

import {
	OnboardingLiabilityIcon,
	OnboardingAssetsIcon,
	OnboardingIncomeIcon
} from 'components/NavMenu/OnboardingProgress';

// Our Assets
import SidebarBg from 'assets/images/onboardArt.svg';

const LIST_ITEMS = [
	{
		route: '/client-onboarding/intro',
		Icon: OnboardingLiabilityIcon,
		text: 'Liabilities'
	},
	{
		route: '/onboarding/income',
		Icon: OnboardingIncomeIcon,
		text: 'Income'
	},
	{
		route: '/onboarding/assets',
		Icon: OnboardingAssetsIcon,
		text: 'Assets'
	},
	{
		route: '/onboarding/review',
		Icon: RadioButtonUncheckedIcon,
		text: 'Review'
	}
];

function SoraList({ color, navigate, progress }) {
	const ListItems = useMemo(
		() =>
			LIST_ITEMS.map(({ route, Icon, text }) => (
				<ListItem key={uuidv4()} disablePadding>
					<ListItemButton
						disableRipple
						sx={{
							cursor: 'auto',
							'&:hover': { backgroundColor: 'transparent' }
						}}
					>
						<ListItemIcon>
							<Icon progress={progress} />
						</ListItemIcon>
						<ListItemText>
							<Typography
								variant="subtitle1"
								sx={{
									color
								}}
							>
								{text}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</ListItem>
			)),
		[progress]
	);

	return ListItems;
}

function OnboardingNavigation({ progress }) {
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	const match = useMediaQuery('(min-height:968px)');

	const transformValue = match ? '1.34' : '1.7';

	const { indigo } = SoraTheme.palette.primary;
	const { onboardingNavLinearGradient } = SoraTheme.palette.gradient;

	return (
		<Paper
			sx={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				background: onboardingNavLinearGradient,
				boxSizing: 'border-box'
			}}
		>
			<SoraLogo />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<List
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						boxSizing: 'border-box'
					}}
				>
					<SoraList
						color={indigo}
						navigate={navigate}
						progress={progress}
					/>
				</List>
				<Box sx={{ width: '100%' }}>
					<ProgressMeter
						value={progress}
						containerStyles={{
							width: '100%',
							display: 'flex',
							marginTop: 4,
							justifyContent: 'center'
						}}
						sx={{
							borderRadius: '10px',
							width: '90%'
						}}
					/>
					<Typography
						variants="subtitle1"
						sx={{
							color: SoraTheme.palette.primary.indigo,
							fontWeight: '700',
							textAlign: 'center',
							marginTop: '15px'
						}}
					>
						{progress}% complete
					</Typography>
				</Box>
			</Box>

			<Box sx={{ width: '100%', flexGrow: 2, overflow: 'hidden' }}>
				<img
					src={SidebarBg}
					alt="Sidebar"
					style={{
						width: '100%',
						height: '100%',
						display: 'inline-block',
						transform: `scale(${transformValue}) translate(0, -10%)`,
						zIndex: 5
					}}
				/>
			</Box>
		</Paper>
	);
}

export default OnboardingNavigation;
