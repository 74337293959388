import { Helmet } from 'react-helmet-async';
import ContactUsForm from 'components/ContactUs/ContactUsForm';
import BasicNavFrame from 'components/Frame/BasicNav';

export default function ContactUsPage() {
	return (
		<>
			<Helmet>
				<title>Contact Us</title>
			</Helmet>

			<BasicNavFrame>
				<ContactUsForm
					sx={{
						marginBottom: 6
					}}
				/>
			</BasicNavFrame>
		</>
	);
}
