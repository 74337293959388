import { Box, Tab, Tabs } from '@mui/material';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

// Our Components
import a11yProps from 'components/Tabs/AccessibilityProps';
import InterestSavingsTab from 'components/FADashboard/InterestSavingsTab';
import NetPresentValueTab from 'components/FADashboard/NetPresentValueTab';
import UpsidePotentialTab from 'components/FADashboard/UpsidePotentialTab';
import TabPanel from 'components/Tabs/TabPanel';

function AnalyticsTabs({
	tabPanelSelected,
	setTabPanelSelected,
	analyticsSavingData,
	analyticsAmortizationData,
	analyticsNPVData,
	analyticsUpsidePotentialData
}) {
	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	return (
		<Box
			sx={{
				marginTop: 6
			}}
		>
			<Box>
				<Tabs
					value={tabPanelSelected}
					onChange={handleTabSelection}
					aria-label="Settings tabs"
				>
					<Tab label="Interest Savings" {...a11yProps(0)} />
					<Tab label="Net Present Value" {...a11yProps(1)} />
					<Tab label="Upside Potential" {...a11yProps(2)} />
				</Tabs>
			</Box>

			<TabPanel value={tabPanelSelected} index={0} sx={{ paddingTop: 4 }}>
				{analyticsSavingData && (
					<InterestSavingsTab
						analyticsSavingData={analyticsSavingData}
						analyticsAmortizationData={analyticsAmortizationData}
					/>
				)}
			</TabPanel>
			<TabPanel value={tabPanelSelected} index={1} sx={{ paddingTop: 4 }}>
				{analyticsNPVData && (
					<NetPresentValueTab analyticsNPVData={analyticsNPVData} />
				)}
			</TabPanel>
			<TabPanel value={tabPanelSelected} index={2} sx={{ paddingTop: 4 }}>
				{analyticsUpsidePotentialData && (
					<UpsidePotentialTab
						analyticsUpsidePotentialData={
							analyticsUpsidePotentialData
						}
					/>
				)}
			</TabPanel>
		</Box>
	);
}

AnalyticsTabs.propTypes = {
	tabPanelSelected: PropTypes.number.isRequired,
	setTabPanelSelected: PropTypes.func.isRequired
};

export default AnalyticsTabs;
