import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our Components
import FinancialGoalDropdown from 'components/Client/Onboarding/FinancialGoalDropdown';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import Loader from 'components/Loader';
import LoanTypeDropdown from 'components/Client/Onboarding/LoanTypeDropdown';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Client/Onboarding/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import TenureDropdown from 'components/Client/Onboarding/TenureDropdown';

// Input Types
import { TEXT } from 'components/Input/Types';

// Utils
import {
	convertMonthsToYears,
	isSubmissionReady,
	roundToDecimal
} from 'shared/utils';

import dateToIsoFormat from 'shared/utils/clientOnboarding/dateToIsoFormat';
import FINANCIAL_GOAL_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/financialGoalMapping';
import inverseFinancialGoalMapping from 'shared/utils/clientOnboarding/inverseFinancialGoalMapping';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import getDefaultApproxPayoffDate from 'shared/utils/clientOnboarding/getDefaultApproxPayoffDate';

// our hooks
import useGetUserPersonalLiability from 'hooks/clientOnboarding/useGetUserPersonalLiability';
import useMutateSavePersonalLiability from 'hooks/clientOnboarding/mutations/useSavePersonalLiability';

// Constants
import { PERSONAL } from 'shared/constants';

function PersonalLiability() {
	const navigate = useNavigate();
	const savePersonalLoan = useMutateSavePersonalLiability();
	const { isLoading, isSuccess, data } = useGetUserPersonalLiability();

	const { isLoading: isMutationLoading } = savePersonalLoan;

	// Personal Liability Related
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(PERSONAL)
	);
	const [lender, setLender] = useState('');
	const [loanType, setLoanType] = useState('Fixed');
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [ownershipTenure, setOwnershipTenure] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Personal Related Data
	const [loanPurpose, setLoanPurpose] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	useEffect(() => {
		if (isSuccess) {
			// This maps the fieldName in the data response to the corresponding state fnc that updates that state
			const WANTED_KEY_SET_MAPPING = {
				expectedPayOffDate: setApproxPayoffDate,
				financialGoal: setFinancialGoal,
				interestRate: setRate,
				lender: setLender,
				loanType: setLoanType,
				loanUserFor: setLoanPurpose,
				personalLoanUse: setLoanPurpose,
				monthlyPay: setMonthlyPayment,
				outstandingBalance: setOutstandingBalance,
				tenureMonth: setOwnershipTenure
			};

			const personalLiabilityFields = Object.keys(data);

			try {
				personalLiabilityFields.forEach((personalLiabilityField) => {
					const currentData = data[personalLiabilityField];
					const setUpdater =
						WANTED_KEY_SET_MAPPING[personalLiabilityField];
					if (
						personalLiabilityField === 'monthlyPay' ||
						personalLiabilityField === 'outstandingBalance' ||
						personalLiabilityField === 'interestRate'
					) {
						// Here the values are ints we need to convert them to strings
						setUpdater(`${currentData}`);
						return;
					}

					if (personalLiabilityField === 'tenureMonth') {
						// here the backend provides in months e.g. 360 but we want in years
						// hence 360 / 12 => 30 years
						const formattedTenureMonth = `${convertMonthsToYears(
							currentData
						)}`;
						setUpdater(formattedTenureMonth);
						return;
					}

					if (personalLiabilityField === 'loanType') {
						const formattedLoanType =
							normalizeLoanType(currentData);
						setUpdater(formattedLoanType);
						return;
					}

					if (personalLiabilityField === 'financialGoal') {
						setUpdater(inverseFinancialGoalMapping(currentData));
						return;
					}
					setUpdater(currentData);
				});
			} catch (e) {
				console.error(e);
			}
		}
	}, [data]);

	const requiredFormValues = [
		approxPayoffDate,
		financialGoal,
		lender,
		monthlyPayment,
		outstandingBalance,
		ownershipTenure,
		rate
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(requiredFormValues) && approxPayoffDate !== null,
		requiredFormValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);
		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);
		const formattedLoanType = loanType.toUpperCase();
		const formattedRate = roundToDecimal(+rate, 2);

		const personalLoan = {
			approxPayoffDate: formattedApproxPayOffDate,
			financialGoal: formattedFinancialGoal,
			lender,
			loanType: formattedLoanType,
			personalLoanUse: loanPurpose,
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			rate: formattedRate,
			tenure: +ownershipTenure,
			tradelineType: 'PERSONAL' // can be one of AUTO | MORTGAGE | PERSONAL | STUDENT
		};

		savePersonalLoan.mutate(personalLoan, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Personal Loan</title>
				</Helmet>
				{/* Once data binding begins we will add Mortgage specific information to this header */}
				<LiabilityFormHeading headingText="Personal Loan" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);
	}
	return (
		<>
			<Helmet>
				<title>Personal Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Personal Loan" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={5} sx={{ height: 120 }}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
						/>
					</Grid>
					<Grid item xs={5} sx={{ height: 120, marginBottom: 2 }}>
						<MonthlyPaymentInput
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={5} sx={{ height: 120 }}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>
					<Grid item xs={5} sx={{ height: 120, marginBottom: 2 }}>
						<StandardDatePicker
							label="Expected Payoff Date"
							helperText="Enter the approximate payoff date"
							onChange={handleApproxPayoffDateChange}
							value={approxPayoffDate}
							error={approxPayoffDate === ''}
						/>
					</Grid>

					<Grid item xs={2} />

					{/* Takes 5 Grid Columns */}
					<TenureDropdown
						tenure={ownershipTenure}
						setTenure={setOwnershipTenure}
						dropDownSX={{ width: '100%' }}
						sx={{ marginBottom: 0 }}
					/>

					{/* Takes 5 Grid Columns */}
					<LoanTypeDropdown
						loanType={loanType}
						setLoanType={setLoanType}
					/>

					<Grid item xs={2} />

					<Grid item xs={5} sx={{ height: 120 }}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>

					<Grid item xs={5} sx={{ height: 120, marginBottom: 2 }}>
						<TextInput
							type={TEXT}
							label="What are you using the loans for?"
							labelSx={{ whiteSpace: 'nowrap' }}
							subLabel="Optional."
							value={loanPurpose}
							onChange={setLoanPurpose}
						/>
					</Grid>
					<Grid item xs={2} />

					<FinancialGoalDropdown
						withoutCashout
						financialGoal={financialGoal}
						setFinancialGoal={setFinancialGoal}
						dropDownLabelSX={{ whiteSpace: 'nowrap' }}
					/>

					<Grid item xs={2} />
					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default PersonalLiability;
