import { dataIsValid } from 'shared/utils';

// CONSTANTS
const EQUITY_CONSTANT = 0.8;

// estimatedValue
// assedValue
// marketValue
// else use zero

/**
 *
 * @param {{assedValueUsd: number, estimatedValue: number, marketValue: number}} homePropertyDetails
 * @param {number} totalOutstandingBalance
 * @returns
 */

function calculateEquityAvailable(
	homePropertyDetails,
	totalOutstandingBalance
) {
	try {
		const isHomePropertyInvalid =
			homePropertyDetails === null || homePropertyDetails === undefined;

		const isBalanceOutstandingMissing = !dataIsValid(
			totalOutstandingBalance
		);

		if (isHomePropertyInvalid || isBalanceOutstandingMissing) return 0;

		if (
			homePropertyDetails?.estimatedValue &&
			homePropertyDetails.estimatedValue !== 0
		) {
			const { estimatedValue } = homePropertyDetails;

			return estimatedValue * EQUITY_CONSTANT - totalOutstandingBalance;
		}

		if (
			homePropertyDetails?.assedValueUsd &&
			homePropertyDetails.assedValueUsd !== 0
		) {
			const { assedValueUsd } = homePropertyDetails;

			return assedValueUsd * EQUITY_CONSTANT - totalOutstandingBalance;
		}

		if (
			homePropertyDetails?.marketValue &&
			homePropertyDetails.marketValue !== 0
		) {
			const { marketValue } = homePropertyDetails;

			return marketValue * EQUITY_CONSTANT - totalOutstandingBalance;
		}

		return 0;
	} catch (e) {
		console.error(e);

		return 0;
	}
}

export default calculateEquityAvailable;
