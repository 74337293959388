import 'react-multi-carousel/lib/styles.css';
import { v4 as uuidv4 } from 'uuid';
import { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our components
import Carousel from 'react-multi-carousel';
import modalData from 'components/FADashboard/modalData.json';
import { HighlightCard } from 'components/FADashboard/Liabilities/HightlightCard';
import Modal from 'components/Modal/Modal';

// our Assets
import avgScoreIcon from 'assets/icons/avgScoreIcon.svg';
import totalLoansIcon from 'assets/icons/totalLoansIcon.svg';
import piggyIcon from 'assets/icons/piggyIcon.svg';
import personalLoanIcon from 'assets/images/personal_loan.svg';

// Our Utils
import { numbroAveragedToDecimal } from 'shared/utils';

// Our Constants

// Note: Its important to keep this config the same.
const CarouselBreakPoints = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 6000, min: 1940 },
		items: 3
	},
	desktop: {
		breakpoint: { max: 1939, min: 1450 },
		items: 3
	},
	tablet: {
		breakpoint: { max: 1449, min: 955 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 954, min: 0 },
		items: 1
	}
};

function ModalChildrenText({ text }) {
	return <Typography variant="body">{text}</Typography>;
}

// TODO change carousel buttons from default to our arrows
// TODO move carousel buttons from default positions to bottom right.
// Carousel package link - https://www.npmjs.com/package/react-multi-carousel

function HighlightCarousel({
	averageSoraScore,
	untappedSavings,
	upsidePotential,
	totalLoansUnderManagement
}) {
	const SoraTheme = useTheme();

	const [showModal, setShowModal] = useState(false);
	const [modalDataIndex, setModalDataIndex] = useState(0);

	const { title: modalTitle, childrenText: modalText } =
		modalData[modalDataIndex];

	const updateModalSelection = (index) => {
		setShowModal(true);
		setModalDataIndex(index);
	};

	const averageSoraScoreToRender =
		averageSoraScore === 'F' ? 'NA' : averageSoraScore;

	const formattedTotalLoansUnderManagement = useMemo(
		() => numbroAveragedToDecimal(totalLoansUnderManagement ?? 0),
		[totalLoansUnderManagement]
	);

	const formattedClientUntappedSavings = useMemo(
		() => numbroAveragedToDecimal(untappedSavings ?? 0),
		[untappedSavings]
	);

	const formattedUpsidePotential = useMemo(
		() => numbroAveragedToDecimal(upsidePotential ?? 0),
		[upsidePotential]
	);

	const renderHighlightCards = () => {
		const hightlightCards = [
			{
				cardHeading: 'Total Upside Potential',
				cardSubmit: () => updateModalSelection(0),
				image: personalLoanIcon,
				headingColor: SoraTheme.palette.primary.white,
				soraScore: null,
				totalLoansValue: `$${formattedUpsidePotential}`
			},

			{
				background: `linear-gradient(180deg, ${SoraTheme.palette.primary.indigo} 0%, rgba(41, 89, 131, 0.42) 100%)`,
				cardHeading: `Total Untapped Savings`,
				cardSubmit: () => updateModalSelection(1),
				image: piggyIcon,
				headingColor: SoraTheme.palette.primary.white,
				soraScore: null,
				totalLoansValue: `$${formattedClientUntappedSavings}`
			},
			{
				background: `linear-gradient(180deg, ${SoraTheme.palette.primary.iceBlue} 0%, rgba(151, 216, 232, 0.22) 100%)`,
				cardHeading: 'Average Sora Score',
				cardSubmit: () => updateModalSelection(2),
				image: avgScoreIcon,
				totalLoansValue: null,
				soraScore: averageSoraScoreToRender
			},
			{
				cardHeading: 'Total Liabilities Under Management',
				cardSubmit: () => updateModalSelection(3),
				image: totalLoansIcon,
				headingColor: SoraTheme.palette.primary.white,
				soraScore: null,
				totalLoansValue: `$${formattedTotalLoansUnderManagement}`
			}
		];

		return hightlightCards.map(
			({
				buttonText,
				background,
				cardHeading,
				cardSubmit,
				headingColor,
				image,
				soraScore,
				totalLoansValue
			}) => {
				const uniqueIdentifier = uuidv4();
				return (
					<HighlightCard
						key={uniqueIdentifier}
						background={background}
						buttonText={buttonText}
						cardHeading={cardHeading}
						cardSubmit={cardSubmit}
						headingColor={headingColor}
						image={image}
						soraScore={soraScore}
						totalLoansValue={totalLoansValue}
					/>
				);
			}
		);
	};

	const hightlightCards = useMemo(
		() => renderHighlightCards(),
		[
			averageSoraScore,
			untappedSavings,
			upsidePotential,
			totalLoansUnderManagement
		]
	);

	return (
		<>
			<Carousel
				responsive={CarouselBreakPoints}
				arrows
				draggable={false}
				keyBoardControl={false}
				renderButtonGroupOutside
				renderDotsOutside={false}
				shouldResetAutoplay={false}
			>
				{hightlightCards}
			</Carousel>
			<Modal
				isOpen={showModal}
				handleClose={() => setShowModal(false)}
				title={modalTitle}
			>
				<ModalChildrenText text={modalText} />
			</Modal>
		</>
	);
}

export default HighlightCarousel;
