import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link, useLocation, useParams, generatePath } from 'react-router-dom';

// Our Components
import { TextButton } from 'components/Button/Button';

// Our Utils
import { dollarFormatter } from 'shared/utils';

// Routes
import { DYNAMIC_LIABILITY_ROUTE } from 'routes';

const getFormattedLiabilityType = (liabilityType) => {
	if (liabilityType === 'Mortgage') return 'Home';
	if (liabilityType === 'Creditcard') return 'Credit card';
	if (liabilityType === 'Heloc') return 'HELOC';

	return liabilityType;
};

const getLiabilityConfidenceColor = (confidenceLevel) => {
	const confidenceToColorMapping = {
		Low: 'red',
		Medium: '#bfa70a',
		High: 'green',
		manual: 'green',
		Manual: 'green'
	};

	return confidenceToColorMapping[confidenceLevel] ?? 'red';
};

function TotalLiabilitiesCardRowItem({
	liabilityBalance,
	liabilityConfidence,
	liabilityDetails,
	liabilityRate,
	liabilityType,
	lastRefreshDate,
	monthlyPay,
	tradelineId
}) {
	const { pathname } = useLocation();

	const { clientId } = useParams();

	const formattedBalance = useMemo(
		() => dollarFormatter.format(liabilityBalance),
		[liabilityBalance]
	);
	const formattedLiabilityType = useMemo(
		() => getFormattedLiabilityType(liabilityType),
		[liabilityType]
	);
	const formattedMonthlyPayment = useMemo(
		() => dollarFormatter.format(monthlyPay),
		[monthlyPay]
	);

	const updateRoute = useMemo(
		() =>
			generatePath(DYNAMIC_LIABILITY_ROUTE, {
				tradelineId,
				tradelineType: liabilityType?.toLowerCase()
			}),
		[liabilityType]
	);

	const isLiabilityUpdateable = useMemo(() => {
		if (liabilityType === 'Creditcard' || liabilityType === 'Heloc')
			return false;

		return true;
	}, [liabilityType, pathname]);

	const confidenceColor = getLiabilityConfidenceColor(liabilityConfidence);

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				borderBottom: '2px solid #C7C7C7',
				paddingBottom: 2
			}}
		>
			<Typography sx={{ fontWeight: 700, width: 159 }}>
				{formattedLiabilityType}
			</Typography>
			<Typography
				sx={{
					width: '15ch',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap'
				}}
			>
				{liabilityDetails}
			</Typography>
			<Typography sx={{ width: 157 }}>{liabilityRate}%</Typography>
			<Typography sx={{ width: 157 }}>{formattedBalance}</Typography>
			<Typography sx={{ width: 157 }}>
				{formattedMonthlyPayment}
			</Typography>

			<Typography sx={{ width: 157, color: confidenceColor }}>
				{liabilityConfidence}
			</Typography>
			<Typography sx={{ width: 157 }}>{lastRefreshDate}</Typography>

			<Box sx={{ width: 157 }}>
				{isLiabilityUpdateable && (
					<Link
						to={updateRoute}
						state={{ fromWallet: true, clientId }}
					>
						<TextButton sx={{ color: '#295983' }}>
							Update
						</TextButton>
					</Link>
				)}
			</Box>
		</Box>
	);
}

TotalLiabilitiesCardRowItem.propTypes = {
	liabilityType: PropTypes.oneOf([
		'Auto',
		'Creditcard',
		'Mortgage',
		'Personal',
		'Student',
		'Heloc'
	]).isRequired,
	liabilityDetails: PropTypes.string.isRequired,
	liabilityRate: PropTypes.number.isRequired,
	liabilityBalance: PropTypes.number.isRequired,
	tradelineId: PropTypes.number.isRequired,
	monthlyPay: PropTypes.number.isRequired
};

export default TotalLiabilitiesCardRowItem;
