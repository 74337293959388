import { forwardRef, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

// Our Components
import ExportableReportHeading from 'components/ExportableReport/ExportableReportHeading';
import ExportableReportBox from 'components/ExportableReport/ExportableReportBox';
import ExportableTable from 'components/ExportableReport/ExportableTable';
import ExportableReportQuestions from 'components/ExportableReport/ExportableReportQuestions';
import ExportableReportInsights from 'components/ExportableReport/ExportableReportInsights';

// Our Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our utils
import convertToCreditScoreWordFromValue from 'shared/utils/formatting/convertToCreditScoreWordFromValue';
import { dollarFormatter } from 'shared/utils';
import formatAddressTitleCase from 'shared/utils/formatting/formatAddressTitleCase';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import sortLiabilitiesByKey from 'shared/utils/walletOverview/sortByKey';

// This forces MUI to adhere to printing layout as landscape!
const StyledBox = styled(Box)`
	@page {
		size: landscape;
		margin: 10px 0 10px 0 !important;
	}
`;

const ExportableReport = forwardRef((props, ref) => {
	const {
		data: walletOverviewData,
		isSuccess,
		isLoading
	} = useGetWalletOverview();

	const openLiabilityItems = useMemo(() => {
		if (isSuccess) {
			const { liabilities } = walletOverviewData.liability;
			const liabilityHashMap = new Map();

			liabilities.map(({ tradeLineType }) =>
				liabilityHashMap.set(
					tradeLineType,
					liabilityHashMap.has(tradeLineType)
						? liabilityHashMap.get(tradeLineType) + 1
						: 1
				)
			);

			const openLiabilityRows = [];

			// eslint-disable-next-line no-restricted-syntax
			for (const currentLiabilityHash of liabilityHashMap) {
				const [currentLoanType, countOfLoan] = currentLiabilityHash;

				const formattedCurrentLoan = normalizeLoanType(currentLoanType);

				if (currentLoanType === 'CREDITCARD') {
					openLiabilityRows.push({
						currentLoan: 'Credit Card',
						count: countOfLoan
					});
					// eslint-disable-next-line no-continue
					continue;
				}

				openLiabilityRows.push({
					currentLoan: formattedCurrentLoan,
					count: countOfLoan
				});
			}

			return openLiabilityRows.map(({ currentLoan, count }) => (
				<Typography key={uuidv4()} variant="body1">
					{currentLoan}: {count}
				</Typography>
			));
		}

		return [];
	}, [walletOverviewData]);

	if (isLoading) return <h1>Loading...</h1>;

	if (isSuccess) {
		const {
			advisor,
			assetsAmount,
			annualIncome,
			creditScore,
			firstName,
			lastName,
			liability,
			address
		} = walletOverviewData;

		const { liabilities } = liability;

		const sortedLiabilities = sortLiabilitiesByKey(
			liabilities,
			'outstandingBalance',
			true
		);

		const formattedCreditScoreWord =
			convertToCreditScoreWordFromValue(creditScore);

		const formattedAnnualIncome = dollarFormatter.format(annualIncome);

		const formattedClientAddress = formatAddressTitleCase(address);

		const amountOfLiabilities = liabilities.length;

		// Below is commented out intentionally since we need some time to create a stronger solution to this.
		// Intentional coercion to empty string on falsely values
		// const clientHomeValue =
		// 	dollarFormatter.format(address?.marketValue) || '';

		return (
			<StyledBox
				ref={ref}
				{...props}
				sx={{
					flexDirection: 'column',
					padding: 2,
					display: 'none',
					displayPrint: 'flex'
				}}
			>
				<ExportableReportHeading advisor={advisor} />
				<Box
					sx={{
						marginTop: 2,
						display: 'flex',
						maxHeight: 350
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexGrow: 1
						}}
					>
						<ExportableReportBox
							headingText="Client Details"
							sx={{
								marginBottom: 2,
								marginRight: 2,
								padding: 2,
								flexGrow: 1
							}}
						>
							<Typography variant="body1">
								Name: {firstName} {lastName}
							</Typography>
							<Typography variant="body1">
								Credit Score: {creditScore} -{' '}
								{formattedCreditScoreWord}
							</Typography>
							<Typography variant="body1">
								Income: {formattedAnnualIncome}
							</Typography>
							<Typography variant="body1">
								Home Address: {formattedClientAddress}
							</Typography>
							{/* <Typography variant="body1">
								Home Value: {clientHomeValue}
							</Typography> */}
						</ExportableReportBox>

						<ExportableReportBox
							headingText={`Open Liabilities: ${amountOfLiabilities}`}
							withoutHeadingColon
							sx={{ marginBottom: 2, marginRight: 2, padding: 2 }}
						>
							{openLiabilityItems}
						</ExportableReportBox>

						<ExportableReportBox
							headingText="Notes"
							sx={{ marginBottom: 2, padding: 2, flexGrow: 1 }}
						/>
					</Box>
				</Box>
				<Box>
					<ExportableReportQuestions
						sortedLiabilities={sortedLiabilities}
					/>
					<ExportableReportInsights
						sortedLiabilities={sortedLiabilities}
						clientFinancialDetails={{
							annualIncome,
							assetsAmount,
							creditScore
						}}
						headingText="Sora Insights:"
					/>
				</Box>
				<ExportableTable liabilities={sortedLiabilities} />
			</StyledBox>
		);
	}

	return <Typography>Something went wrong!</Typography>;
});

export default ExportableReport;
