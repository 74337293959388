import { Box, IconButton } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';

function BackButton({ backEnabled }) {
	const navigate = useNavigate();

	if (!backEnabled) {
		return (
			<Box
				sx={{
					height: 64,
					width: 64,
					padding: '12px',
					bgcolor: 'white'
				}}
			/>
		);
	}
	return (
		<Box
			sx={{
				height: 64,
				width: 64,
				padding: '12px',
				cursor: 'pointer'
			}}
			onClick={() => navigate(-1)}
		>
			<IconButton aria-label="back">
				<KeyboardBackspaceOutlinedIcon />
			</IconButton>
		</Box>
	);
}

export default BackButton;
