import { useMemo } from 'react';

// constants
import { LOAN } from 'shared/constants';

// We have not created pages for these two Liabilities LOAN was confirmed to NOT mean personal.
const CURRENTLY_UNSUPPORTED_LIABILITIES = new Set([LOAN]);

// Hook is used to gather the list of liabilities that we display within the total liabilities table on the wallet overview page
function useGetLiabilitiesTableList(liabilities, isSuccess) {
	return useMemo(() => {
		// if no liabilities are found return an empty array.
		if (!liabilities) return [];

		return liabilities.filter((liability) => {
			const { tradeLineType } = liability;

			const isTradeLineSupported =
				!CURRENTLY_UNSUPPORTED_LIABILITIES.has(tradeLineType);
			if (isTradeLineSupported) return true;
			return false;
		});
	}, [liabilities, isSuccess]);
}

export default useGetLiabilitiesTableList;
