import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useState, useCallback, useMemo } from 'react';
import { isEmail } from 'validator';
import { v4 as uuidv4 } from 'uuid';

// Our Mutations
import useMutateSubscribeToNewsletter from 'hooks/useMutateSubscribeToNewsletter';

// Our Components
import Alert from 'components/Alert';
import { EMAIL } from 'components/Input/Types';
import { PrimaryButton } from 'components/Button/Button';
import TextInput from 'components/Input/TextInput';
import { isSubmissionReady } from 'shared/utils';

function NewsletterSignupForm() {
	const SoraTheme = useTheme();
	const subscribeToNewsletter = useMutateSubscribeToNewsletter();
	const { error: subscribeToNewsletterError, isSuccess } =
		subscribeToNewsletter;

	const [email, setEmail] = useState('');

	const [newsletterKey, setNewsLetterKey] = useState(uuidv4());

	const updateNewsLetterKey = useCallback(() => {
		setNewsLetterKey(uuidv4());
	}, []);

	const formValues = [email];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues) && isEmail(email),
		formValues
	);

	// Handle field change
	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const submitNewsletterSubscribe = () => {
		subscribeToNewsletter.mutate(
			{ email },
			{
				onSuccess: () => {
					setEmail('');
					updateNewsLetterKey();
				}
			}
		);
	};

	return (
		<Box
			sx={{
				flexGrow: 1
			}}
		>
			{subscribeToNewsletterError && <Alert variant="error" />}
			{isSuccess && <Alert variant="success" />}
			<Typography
				variant="subtitle1"
				sx={{
					marginBottom: 1,
					color: SoraTheme.palette.primary.soraBlue
				}}
			>
				Sign up for our newsletter
			</Typography>
			<TextInput
				key={newsletterKey}
				placeholder="Email Address"
				type={EMAIL}
				value={email}
				onChange={handleEmailChange}
				error={!isEmail(email)}
				helperText="Please enter a valid email address"
				sx={{
					maxWidth: '328px',
					marginBottom: 2
				}}
			/>

			<PrimaryButton
				disabled={!isFormReady}
				onClick={submitNewsletterSubscribe}
			>
				Sign up
			</PrimaryButton>
		</Box>
	);
}

export default NewsletterSignupForm;
