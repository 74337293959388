import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQueryClient } from 'react-query';
import { Typography, List, ListItem, ListItemButton } from '@mui/material';

import NewLoanIcon from '@mui/icons-material/AddCard';
// import LoanStatusIcon from 'assets/icons/cash.png';
import SoraWalletIcon from 'assets/icons/wallet.png';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// import { CLIENT_SORA_WALLET } from 'shared/query-keys';

// Our routes
import { CLIENT_SORAWALLET_ROUTE } from 'routes';

function NavMenuClient() {
	const SoraTheme = useTheme();
	// const queryClient = useQueryClient();
	const navigate = useNavigate();

	// const clientWalletDetails = queryClient.getQueryData(CLIENT_SORA_WALLET);

	// const hasExisitingLoan = clientWalletDetails?.currentLoan !== undefined;

	return (
		<List>
			{/* Dashboard link */}
			<ListItem
				disablePadding
				onClick={() => navigate(CLIENT_SORAWALLET_ROUTE)}
			>
				<ListItemButton>
					<ListItemIcon sx={{ paddingLeft: 1 }}>
						<img
							src={SoraWalletIcon}
							alt="Sora wallet icon"
							width="22"
							height="18"
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							variant="subtitle1"
							sx={{ color: SoraTheme.palette.primary.white }}
						>
							Wallet
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>

			<ListItem
				disablePadding
				onClick={() => navigate('/select-loan/new-loan')}
			>
				<ListItemButton>
					<ListItemIcon sx={{ paddingLeft: 1 }}>
						<NewLoanIcon
							sx={{
								color: SoraTheme.palette.primary.white
							}}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							variant="subtitle1"
							sx={{ color: SoraTheme.palette.primary.white }}
						>
							New Loan
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>

			{/* {hasExisitingLoan && (
				<ListItem
					disablePadding
					onClick={() => navigate('/dashboard/loan-status')}
				>
					<ListItemButton>
						<ListItemIcon sx={{paddingLeft: 1}}>
							<img
								src={LoanStatusIcon}
								alt="Loan status icon"
								width="20"
								height="14"
							/>
						</ListItemIcon>
						<ListItemText>
							<Typography variant="subtitle1" sx={{color: SoraTheme.palette.primary.white}}">
								Loan Status
							</Typography>
						</ListItemText>
					</ListItemButton>
				</ListItem>
			)} */}
		</List>
	);
}

export default NavMenuClient;
