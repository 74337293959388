import { v4 as uuidv4 } from 'uuid';

// Our Components
import ExportableReportBox from 'components/ExportableReport/ExportableReportBox';

// Our Utils
import { getExportableReportQuestions } from 'components/ExportableReport/ExportableReportRuleProcessor';

function ExportableReportQuestions({ sortedLiabilities }) {
	const questions = getExportableReportQuestions(sortedLiabilities);

	return (
		<ExportableReportBox headingText="Questions" sx={{ marginBottom: 2 }}>
			<ul>
				{questions.map((currentQuestion) => (
					<li
						key={uuidv4()}
						variant="body1"
						sx={{ marginTop: 1, marginBottom: 1 }}
					>
						{currentQuestion}
					</li>
				))}
			</ul>
		</ExportableReportBox>
	);
}

export default ExportableReportQuestions;
