import { useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert';
import { EMAIL } from 'components/Input/Types';
import Input from 'components/Input/TextInput';
import { PrimaryButton } from 'components/Button/Button';
import { LOGIN_ROUTE } from 'routes/index';

// Our hooks
import useMutateResetPassword from 'hooks/useMutatePasswordResetEmail';

// utils
import { simpleValidEmail, isSubmissionReady } from 'shared/utils';

function ForgotPasswordForm() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const navigate = useNavigate();

	const resetPassword = useMutateResetPassword();

	const { error: resetPasswordError, isSuccess } = resetPassword;

	const [email, setEmail] = useState('');

	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const formData = [email];
	const isFormReady = useMemo(
		() => isSubmissionReady(formData) && simpleValidEmail(email),
		formData
	);

	const sendPasswordResetEmail = (event) => {
		event.preventDefault();
		resetPassword.mutate(email, {
			onSuccess: () => {
				setTimeout(() => {
					navigate(LOGIN_ROUTE);
				}, 1500);
			}
		});
	};

	return (
		<>
			<Typography
				variant="h4"
				gutterBottom
				component="div"
				sx={{
					marginTop: 18,
					color: primary.soraBlue
				}}
			>
				Reset your password
			</Typography>

			<Box component="form" noValidate autoComplete="off">
				{resetPasswordError && <Alert variant="error" />}
				{isSuccess && <Alert variant="success" />}
				<Grid item xs={8}>
					{/* Email */}
					<Input
						id={EMAIL}
						label="Email"
						type={EMAIL}
						name={EMAIL}
						onChange={handleEmailChange}
						value={email}
						error={!simpleValidEmail(email)}
						helperText="Please enter a valid email address"
						sx={{
							marginTop: 4,
							marginBottom: 2,
							width: '100%'
						}}
					/>
				</Grid>

				<PrimaryButton
					onClick={sendPasswordResetEmail}
					disabled={!isFormReady}
					sx={{
						marginTop: 4
					}}
				>
					Send reset password email
				</PrimaryButton>
			</Box>
		</>
	);
}

export default ForgotPasswordForm;
