import { Box, Grid, Typography } from '@mui/material';

import CardItem from 'components/SoraWallet/Cards/CardItem';

// Assets
import soraWings from 'assets/images/soraMarkOnWhite.svg';

function WalletWellDoneCard() {
	return (
		<Grid item sm={12} md={12} lg={4} xl={4}>
			<CardItem withoutButton>
				<Typography variant="h5">Well done!</Typography>
				<Typography sx={{ marginTop: 2 }}>
					You have the best loan option for which you are eligible.
					Our algorithms will keep monitoring your savings
					opportunities and alert you when we find one.
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end'
					}}
				>
					<Box
						component="img"
						src={soraWings}
						sx={{
							width: 70,
							height: 70,
							float: 'right'
						}}
					/>
				</Box>
			</CardItem>
		</Grid>
	);
}

export default WalletWellDoneCard;
