import { useState, useMemo } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { isDate, isMobilePhone } from 'validator';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Query Keys
import { ERROR_MESSAGE_DATA, SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Our Components
import Alert from 'components/Alert';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import GetFullAddressPartialForm from 'components/Forms/GetFullAddressPartialForm';
import Loader from 'components/Loader';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import { PrimaryButton } from 'components/Button/Button';
import SSNTextInput from 'components/Input/SSNTextInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Endpoints
import { userGetLoginTokenUrl } from 'shared/api-urls';

// Our Constants
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';

// Our Utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';

export function AddSingleClient() {
	const queryClient = useQueryClient();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [piiNumber, setPiiNumber] = useState('');
	const [dob, setDob] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState('');

	// address related fields
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	const [income, setIncomeAmount] = useState('');
	const [assets, setAssetAmount] = useState('');
	const [spouseName, setSpouseName] = useState('');
	const [key, setKey] = useState('a');

	const {
		isLoading: isProfileLoading,
		data: userProfileData,
		isError: userProfileIsError
	} = useGetProfile();

	const handleFirstNameChange = (value) => {
		setFirstName(value);
	};

	const handleLastNameChange = (value) => {
		setLastName(value);
	};

	const formValues = [
		firstName,
		lastName,
		piiNumber,
		dob,
		phoneNumber,
		addressLine1,
		city,
		state,
		zipCode
	];

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const isValidPhoneNumber = useMemo(() => {
		if (PhoneNumberWhitelist.includes(phoneNumber)) {
			return true;
		}
		return isMobilePhone(phoneNumber, 'en-US');
	}, [phoneNumber]);

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(formValues) &&
			phoneNumber.length > 7 &&
			isValidDate,
		formValues
	);
	const mutateStartOnboardingProcess = useMutation(
		async () => {
			const { email: advisorEmail } = userProfileData;

			const checkForEmptyIncome = income !== '' ? income : '0';
			const checkForEmptyAsset = assets !== '' ? assets : '0';

			const onboardClientDetails = {
				advisorEmail,
				firstName,
				lastName,
				phoneNumber,
				dob,
				piiNumber,
				addressLine1,
				addressLine2,
				city,
				state,
				zipCode,
				incomeAmount: checkForEmptyIncome,
				assetAmount: checkForEmptyAsset
			};

			const userTokenResponse = await axiosInstance.get(
				userGetLoginTokenUrl
			);

			const userTokenData = userTokenResponse.data.data;

			// Url Subject to change
			const response = await axios.post(
				'https://sora-onboarding-service.herokuapp.com/onboard/single/client',
				{ ...onboardClientDetails, ...userTokenData }
			);

			// const response = await axios.post(
			// 	'http://localhost:3001/onboard/single/client',
			// 	{ ...onboardClientDetails, ...userTokenData }
			// );

			return response.data;
		},
		{
			onSuccess: (data) => {
				clearFormValues([
					setFirstName,
					setLastName,
					setPiiNumber,
					setAddressLine1,
					setAddressLine2,
					setCity,
					setState,
					setZipCode,
					setPhoneNumber,
					setIncomeAmount,
					setAssetAmount,
					setSpouseName
				]);

				setDob(null);
				setKey(key + 1);

				queryClient.setQueryData(SUCCESS_MESSAGE_DATA, data);
			}
		}
	);

	const { isError, isSuccess, isLoading } = mutateStartOnboardingProcess;

	// Handle Email Send
	const submitAddSingleClientForm = (event) => {
		event.preventDefault();
		mutateStartOnboardingProcess.mutate(
			{},
			{
				onError: (e) => {
					queryClient.setQueryData(ERROR_MESSAGE_DATA, e.message);
				}
			}
		);
	};

	if (isProfileLoading) {
		return <Loader size={60} />;
	}

	if (userProfileIsError) {
		return 'Something went wrong grabbing your profile. please log back in.';
	}

	return (
		<>
			{isError && <Alert variant="error" />}
			{isSuccess && <Alert variant="success" />}
			<>
				<Typography variant="h4" color="primary.soraBlue">
					Add Individual Client
				</Typography>

				<Typography variant="body2" marginTop={2}>
					Enter your client&apos;s information below and they will be
					added to your account.
				</Typography>

				<Box component="form" noValidate autoComplete="off">
					<Grid container columnSpacing={2} marginTop={4}>
						<Grid item xs={5} marginBottom={4}>
							<TextInput
								type={TEXT}
								label="First Name"
								subLabel="Use legal name"
								value={firstName}
								onChange={handleFirstNameChange}
							/>
						</Grid>
						<Grid item xs={5}>
							<TextInput
								type={TEXT}
								label="Last Name"
								subLabel="Use legal name"
								value={lastName}
								onChange={handleLastNameChange}
							/>
						</Grid>
						<Grid item xs={2} />

						<Grid item xs={5} marginBottom={4}>
							<SSNTextInput
								onChange={setPiiNumber}
								ssnValue={piiNumber}
								subLabel=""
							/>
						</Grid>
						<Grid item xs={5}>
							<StandardDatePicker
								key={key}
								id="dob"
								label="Date of Birth"
								helperText="Enter your client's birth date"
								onChange={setDob}
								value={dob}
								error={dob === null || !isValidDate}
								errorText="Date of birth needs to be a valid date"
							/>
						</Grid>
						<Grid item xs={2} />

						<Grid item xs={5}>
							<PhoneNumberInput
								key={key}
								isValidPhoneNumber={isValidPhoneNumber}
								onChange={setPhoneNumber}
								phoneNumber={phoneNumber}
								subLabel="Enter your client's phone number"
								sx={{ marginBottom: 4 }}
							/>
						</Grid>
						<Grid item xs={7} />

						{/* Address Rows */}
						<GetFullAddressPartialForm
							addressLine1={addressLine1}
							addressLine2={addressLine2}
							city={city}
							state={state}
							zipCode={zipCode}
							setAddressLine1={setAddressLine1}
							setAddressLine2={setAddressLine2}
							setCity={setCity}
							setState={setState}
							setZipCode={setZipCode}
						/>

						<Grid item xs={10} marginBottom={4} marginTop={4}>
							<hr />
						</Grid>
						<Grid item xs={2} />

						<Grid item xs={5} marginBottom={4}>
							{/* Income */}
							<CurrencyTextInput
								label="Annual Income"
								subLabel="Optional"
								value={income}
								onChange={setIncomeAmount}
							/>
						</Grid>
						<Grid item xs={5}>
							<CurrencyTextInput
								label="Estimated Value of Your Assets"
								subLabel="Optional (Includes cash, checking and savings accounts, stocks, retirement accounts and other assets.)"
								value={assets}
								onChange={setAssetAmount}
								inputprops={{
									'data-test': 'assets'
								}}
							/>
						</Grid>
						<Grid item xs={2} />

						<Grid item xs={5} marginBottom={4}>
							{/* Spouses name */}
							<TextInput
								type={TEXT}
								label="Spouse's Name"
								subLabel="Optional (Use legal name)"
								value={spouseName}
								onChange={setSpouseName}
							/>
						</Grid>
						<Grid item xs={7} />

						<Box
							sx={{
								marginTop: 2,
								marginLeft: 2,
								marginBottom: 6
							}}
						>
							{!isLoading && (
								<PrimaryButton
									data-test="inviteButton"
									onClick={submitAddSingleClientForm}
									disabled={!isFormReady}
								>
									Add Client
								</PrimaryButton>
							)}
							{isLoading && <Loader />}
						</Box>
					</Grid>
				</Box>
			</>
		</>
	);
}

export default AddSingleClient;
