import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import Chart from 'components/SoraWallet/Chart';

// Wallet Prop Types
import {
	bestRefiPropShape,
	currentLoanPropShape
} from 'components/SoraWallet/WalletPropTypes';

// Our Routes
import { REFINANCE_OFFER_ROUTE } from 'routes';

// Our Query Keys
import { FA_CURRENT_CLIENT_ID, WALLET_OVERVIEW_DATA } from 'shared/query-keys';

function RefinanceOptionsCard({
	bestRefi,
	currentLoan,
	isClient,
	shouldRenderChart
}) {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { tradelineId } = useParams();

	const clientId = queryClient.getQueryData(FA_CURRENT_CLIENT_ID);

	const dynamicKey = clientId
		? [WALLET_OVERVIEW_DATA, clientId]
		: WALLET_OVERVIEW_DATA;

	const walletOverviewData = queryClient.getQueryData(dynamicKey);

	const clientDataForOfferRoute = {
		firstName: walletOverviewData.firstName,
		lastName: walletOverviewData.lastName,
		clientId
	};

	const buttonText = isClient ? 'Refinance' : 'See Options';

	return (
		<Grid item sm={12} md={12} lg={12} xl={12}>
			<CardItem
				buttonText={buttonText}
				onClickHandler={() =>
					navigate(REFINANCE_OFFER_ROUTE, {
						state: {
							clientData: clientDataForOfferRoute,
							tradelineId
						}
					})
				}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Typography variant="h5" gutterBottom>
						Refinance Options
					</Typography>
					{/* TODO add drop down post MVP */}
					{/* <Dropdown
						items={[
							'Reduce monthly payments',
							'Reduce total cost of debt',
							'Pay off loans faster'
						]}
						placeholder="Priorities"
						sx={{ width: 125 }}
						onChange={() => console.log('dropdown Change')}
					/> */}
				</Box>
				{shouldRenderChart && (
					<Chart bestOfferLoan={bestRefi} currentLoan={currentLoan} />
				)}
			</CardItem>
		</Grid>
	);
}

RefinanceOptionsCard.propTypes = {
	bestRefi: PropTypes.shape(bestRefiPropShape).isRequired,
	currentLoan: PropTypes.shape(currentLoanPropShape).isRequired,
	isClient: PropTypes.bool.isRequired,
	shouldRenderChart: PropTypes.bool.isRequired
};

export default RefinanceOptionsCard;
