import CardItem from 'components/SoraWallet/Cards/CardItem';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

import WalletMoney from 'assets/icons/walletMoney.png';

// Our Utility
import { dollarFormatter } from 'shared/utils';

function BalanceOutstandingCard({ currentLoanDetails }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const outstandingBalance = dollarFormatter.format(
		currentLoanDetails?.totalOutstandingBalance
	);

	return (
		<CardItem
			withoutButton
			sx={{
				display: 'flex',
				flexDirection: 'row',
				height: 'inherit',
				marginBottom: 4,
				alignItems: 'center'
			}}
		>
			<Typography
				variant="h6"
				sx={{
					fontWeight: 700,
					fontSize: 16,
					flexGrow: 2,
					textAlign: 'right'
				}}
			>
				Balance Outstanding
			</Typography>
			<Typography
				variant="h5"
				sx={{ color: primary.indigo, flexGrow: 2, textAlign: 'left' }}
			>
				{outstandingBalance}
			</Typography>

			<Box>
				<a
					href="https://www.flaticon.com/free-icons/accounting"
					rel="noreferrer"
					target="_blank"
					style={{ cursor: 'default' }}
				>
					<img
						alt="Bank info"
						src={WalletMoney}
						style={{ width: 70, height: 70, marginRight: 10 }}
						title="Accounting icons created by KP Arts - Flaticon"
					/>
				</a>
			</Box>
		</CardItem>
	);
}

BalanceOutstandingCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	currentLoanDetails: PropTypes.object.isRequired
};

export default BalanceOutstandingCard;
