import { IconButton } from '@mui/material/index';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

function SoraCarouselArrow({ onClick, variant, sx }) {
	const customStyling =
		variant === 'right' ? { right: '0px' } : { left: '0px' };

	return (
		<IconButton
			onClick={() => onClick()}
			type="button"
			sx={{
				position: 'absolute',
				...customStyling,
				...sx
			}}
		>
			{variant === 'right' ? (
				<ArrowForwardIosIcon />
			) : (
				<ArrowBackIosIcon />
			)}
		</IconButton>
	);
}

SoraCarouselArrow.propTypes = {
	variant: PropTypes.oneOf(['right', 'left']).isRequired,
	sx: CommonPropTypes.sx
};

SoraCarouselArrow.defaultProps = {
	sx: {}
};

export default SoraCarouselArrow;
