import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';

// utils
import { roundToDecimal } from 'shared/utils';

function YearsRemainingCard({ remainingMonth }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const displayedValue =
		remainingMonth < 12 ? '< 1' : roundToDecimal(remainingMonth / 12, 1);

	return (
		<CardItem
			sx={{ alignItems: 'center' }}
			cardHeading="Years Remaining"
			withoutButton
		>
			<Box sx={{ flexGrow: 2 }}>
				<Typography
					variant="h4"
					color={primary.indigo}
					sx={{ marginTop: 2 }}
				>
					{displayedValue}
				</Typography>
			</Box>
		</CardItem>
	);
}

YearsRemainingCard.propTypes = {
	remainingMonth: PropTypes.number.isRequired
};

export default YearsRemainingCard;
