import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';
// Our Endpoints
import { userProfile } from 'shared/api-urls';

// our query keys
import {
	USER_DATA,
	ERROR_MESSAGE_DATA,
	USERPROFILEDATA
} from 'shared/query-keys';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

import { SIGNUP_COMPLETED } from 'shared/constants';

function useMutateAddProfileData() {
	const queryClient = useQueryClient();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	return useMutation(
		async (userData) => {
			const response = await axiosInstance.post(userProfile, userData);
			const userProfileData = response.data.data;

			// variable here is not needed but the AWAIT is.
			// stored in a variable for contextual purposes.
			const makeOnboardingStatusUpdateResponse =
				await updateOnboardingStatus.mutate(SIGNUP_COMPLETED);

			return userProfileData;
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData(USER_DATA, data);
				queryClient.invalidateQueries(USERPROFILEDATA);
			},

			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateAddProfileData;
