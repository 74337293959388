import { useQuery } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our Query Keys
import { FALIABILITYSUMMARY } from 'shared/query-keys';

// Our End points
import { faLiabilitySummary } from 'shared/api-urls';

// Our utils
import { dataIsValid } from 'shared/utils';

function useGetFaSummary(filterBy) {
	const sanitzedFilter = filterBy.trim().toLowerCase();
	return useQuery(
		FALIABILITYSUMMARY,
		async () => {
			const response = await axiosInstance.get(faLiabilitySummary);

			const faSummaryData = response.data;

			const filteredClientSummaryData =
				faSummaryData.data.summaryLiability.map((client) => {
					const isNamePresent = dataIsValid(client?.name);

					// If name exists do not touch and simply return it
					if (isNamePresent) return client;

					// If name does not exist return all data BUT overwrite name and insert as Unknown
					return { ...client, name: 'Unknown' };
				});

			// Okay to mutate this way since references to this object are updated by React Query.
			faSummaryData.data.summaryLiability = filteredClientSummaryData;

			return faSummaryData;
		},
		{
			select: (soraDataResponse) => {
				const isFilterEmpty = filterBy === '';
				if (isFilterEmpty) return soraDataResponse;
				// eslint-disable-next-line no-param-reassign

				const filteredSummary =
					soraDataResponse.data.summaryLiability.filter(
						(clientInfo) =>
							clientInfo.name
								.trim()
								.toLowerCase()
								.includes(sanitzedFilter)
					);
				const data = {
					...soraDataResponse.data,
					summaryLiability: filteredSummary
				};
				return { ...soraDataResponse, data };
			}
		}
	);
}

export default useGetFaSummary;
