import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import CreditScoreBar from 'components/SoraWallet/Cards/creditScoreBar/';
import ModalBox from 'components/Modal/Modal';

// Our Constants
import { CREDITCARD } from 'shared/constants';

// Our Utils
import calculateDTI from 'shared/utils/calculations/debtToIncomeCalculation';

// helper fn
function getDebtToIncomeArrowIndex(debtToIncomeRatio) {
	// this function returns an index based on debtToIncomeRatio
	if (debtToIncomeRatio <= 35) return 0;
	if (debtToIncomeRatio > 35 && debtToIncomeRatio < 50) return 1;
	if (debtToIncomeRatio >= 50) return 2;
	return 1; // if we aren't given a number for some reason the arrow points to 50% on scale
}

function DebtToIncomeCard({ liabilities, annualIncome }) {
	const SoraTheme = useTheme();
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const { primary } = SoraTheme.palette;

	const liabilitiesWithoutCreditCard = useMemo(
		() =>
			liabilities.filter(
				({ tradeLineType }) => tradeLineType !== CREDITCARD
			),
		[liabilities]
	);

	const debtToIncomeRatio = useMemo(
		() => calculateDTI(liabilitiesWithoutCreditCard, annualIncome),
		[liabilities, annualIncome]
	);

	const debtToIncomeRatioIndex = useMemo(
		() => getDebtToIncomeArrowIndex(debtToIncomeRatio),
		[debtToIncomeRatio]
	);

	const colors = ['#387C5B', '#F8DB4A', '#B92D43'];

	const colorPills = useMemo(
		() =>
			colors.map((color, index) => (
				<CreditScoreBar
					key={uuidv4()}
					color={color}
					sx={{ width: '100%' }}
					withArrow={index === debtToIncomeRatioIndex}
				/>
			)),
		[debtToIncomeRatio]
	);

	return (
		<CardItem
			buttonText="Learn More"
			onClickHandler={handleOpen}
			cardHeading="Debt to Income"
			sx={{ alignItems: 'center' }}
		>
			<Typography
				variant="h5"
				color={primary.indigo}
				fontWeight={700}
				sx={{ marginTop: 2 }}
			>
				{debtToIncomeRatio === 0 ? 'NA' : `${debtToIncomeRatio}%`}
			</Typography>
			<ModalBox
				isOpen={isOpen}
				handleClose={handleClose}
				title="Debt to Income"
			>
				Your DTI (debt-to-income) ratio compares how much you owe with
				how much you earn in a given month. It typically includes
				monthly payments such as rent, mortgage, credit cards, car
				payments, and other debt. Having a DTI below 35% is considered
				good, and is reflective of a manageable debt load.
			</ModalBox>
			<Box
				sx={{
					flexGrow: 2,
					width: '100%',
					textAlign: 'center'
				}}
			>
				<Box
					sx={{
						width: '100%',
						height: 50,
						display: 'flex',
						justifyContent: 'space-evenly',
						alignItems: 'flex-end'
					}}
				>
					<Typography variant="subtitle1" fontSize={10}>
						0%
					</Typography>
					{colorPills}
					<Typography variant="subtitle1" fontSize={10}>
						100%
					</Typography>
				</Box>
			</Box>
		</CardItem>
	);
}

DebtToIncomeCard.propTypes = {
	annualIncome: PropTypes.number.isRequired
};

export default DebtToIncomeCard;
