import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';

// Our Prop types
import CommonPropTypes from 'shared/prop-types';

// Our components
import BasicFrame from 'components/Frame/Basic';
import NavMenu from 'components/NavMenu/NavMenu';
import Footer from 'components/Footer/Footer';

const StyledGrid = styled(Grid)(`
	margin: 0;

	& .MuiGrid-item {
		padding-top: 0;
		padding-left: 0;
	}
`);

function BasicNavFrame({ backEnabled, children, mainGridSX }) {
	const SoraTheme = useTheme();
	// const { children } = props;
	// A basic 12 column grid split:
	// [ 2 columns | 10 columns ]
	// 2 columns: will hold the navigation menu
	// 10 columns:  Within these 10 cols we reimplemented another 12 column grid,
	// giving us more spacing options for the right side of the frame.
	const navigate = useNavigate();

	return (
		<StyledGrid
			container
			spacing={2}
			sx={{ height: '100%', width: '100%' }}
		>
			<Grid item xs={2}>
				<Box
					sx={{
						position: 'fixed',
						background:
							SoraTheme.palette.gradient
								.soraBlueToWhiteLinearGradient,
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						width: '15%'
					}}
				>
					{/* Navigation menu here */}
					<NavMenu />
				</Box>
			</Grid>

			<Grid item xs={10}>
				<BasicFrame
					sx={{ height: '100vh', width: '100%', ...mainGridSX }}
				>
					{backEnabled && (
						<>
							<Grid item xs={2}>
								<Box
									sx={{
										height: 64,
										width: 64,
										padding: '12px',
										cursor: 'pointer'
									}}
									onClick={() => navigate(-1)}
								>
									<IconButton aria-label="back">
										<KeyboardBackspaceOutlinedIcon />
									</IconButton>
								</Box>
							</Grid>
							<Grid item xs={10} />
						</>
					)}
					<Grid item xs={12}>
						{children}
					</Grid>

					<Grid
						container
						item
						xs={12}
						sx={{ alignItems: 'flex-end' }}
					>
						<Footer sx={{ width: '100%' }} />
					</Grid>
				</BasicFrame>
			</Grid>
		</StyledGrid>
	);
}

BasicFrame.propTypes = {
	backEnabled: PropTypes.bool,
	leftColumn: PropTypes.number,
	mainGridSX: CommonPropTypes.sx
};

BasicFrame.defaultProps = {
	backEnabled: false,
	mainGridSX: {}
};

export default BasicNavFrame;
