import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTypography = styled(Typography)`
	text-decoration: underline;
	text-underline-offset: 2px;
`;

function ExportableReportBox({
	children,
	headingText,
	sx,
	withoutHeadingColon,
	withBorder
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	return (
		<Box
			sx={{
				border: withBorder
					? `1px solid ${primary.soraMariner}`
					: 'none',
				paddingTop: 1,
				paddingLeft: 2,
				...sx
			}}
		>
			<StyledTypography
				variant="subtitle1"
				sx={{
					color: primary.indigo
				}}
			>
				{headingText}
				{!withoutHeadingColon && ':'}
			</StyledTypography>
			{children}
		</Box>
	);
}

ExportableReportBox.propTypes = {
	headingText: PropTypes.string,
	withBorder: PropTypes.bool,
	withoutHeadingColon: PropTypes.bool
};

ExportableReportBox.defaultProps = {
	headingText: '',
	withBorder: true,
	withoutHeadingColon: false
};

export default ExportableReportBox;
