import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';

import { dollarFormatter } from 'shared/utils';

// Our Constants
import { CREDITCARD } from 'shared/constants';

function ExportableTable({ liabilities }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const tableColumnsHeadings = useMemo(() => {
		const tableHeadings = [
			'',
			'Lender',
			'Balance',
			'Rate',
			'Monthly Payment'
		];

		return tableHeadings.map((tableHeading) => (
			<TableCell
				key={uuidv4()}
				sx={{
					bgcolor: primary.soraMariner,
					color: primary.white,
					border: `1px solid ${primary.grey}`,
					padding: 0,
					paddingLeft: 1
				}}
				align="justify"
			>
				{tableHeading}
			</TableCell>
		));
	}, []);

	const tableBodyRows = useMemo(
		() =>
			liabilities.map(
				(
					{
						lender,
						outstandingBalance,
						interestRate,
						tradeLineType,
						monthlyPay
					},
					index
				) => {
					const formattedBalance =
						dollarFormatter.format(outstandingBalance);
					const formattedMonthlyPayment = () => {
						// If liability has a tradeLineType of credit card, set monthly payment to display as $0
						if (tradeLineType === CREDITCARD) {
							return '$0';
						}
						return dollarFormatter.format(monthlyPay);
					};

					const currentCells = [
						index + 1,
						lender,
						formattedBalance,
						`${interestRate}%`,
						formattedMonthlyPayment()
					];

					return (
						<TableRow key={uuidv4()} sx={{ padding: 0 }}>
							{currentCells.map((currentCellData) => (
								<TableCell
									key={uuidv4()}
									sx={{
										border: `2px solid ${primary.grey}`,
										padding: '2px',
										paddingLeft: 1
									}}
								>
									{currentCellData}
								</TableCell>
							))}
						</TableRow>
					);
				}
			),
		[]
	);

	return (
		<TableContainer>
			<Table
				sx={{ flexGrow: 2, marginTop: 1 }}
				aria-label="Liability Table"
			>
				<TableHead>
					<TableRow>{tableColumnsHeadings}</TableRow>
				</TableHead>
				<TableBody>{tableBodyRows}</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ExportableTable;
