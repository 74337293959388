import { useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useNavigate, Link } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { Grid, Typography, Link as ExternalLink } from '@mui/material';
import { isEmail } from 'validator';
import { useQueryClient } from 'react-query';

// Our Components
import Checkbox from 'components/Checkbox/Checkbox';
import ErrorAlert from 'components/Alert';
import Input from 'components/Input/TextInput';
import LoginArtAndSoraLogo from 'components/LoginForm/LoginArtAndSoraLogo';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';
import { EMAIL, TEXT } from 'components/Input/Types';

// Hooks
import useMutateAddUser from 'hooks/useMutateAddUser';
import useSignInWithPopup from 'hooks/useSignInWithPopup';

// Our Utils
import { INVITEE_EMAIL } from 'shared/query-keys';
import { isSubmissionReady } from 'shared/utils';

export function FullSignupForm() {
	const SoraTheme = useTheme();
	const addUser = useMutateAddUser();
	const queryClient = useQueryClient();

	const { error: addUserError } = addUser;

	// Create state for form items
	const [userEmail, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [agreeTerms, setTerms] = useState(false);
	const [agreePP, setPP] = useState(false);

	const [isUsingGooglePopUp, setIsUsingGooglePopUp] = useState(false);

	const formData = [userEmail, password];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(formData) &&
			agreePP &&
			agreeTerms &&
			password.length >= 8 &&
			isEmail(userEmail),
		[...formData, agreePP, agreeTerms]
	);

	// Handle field changes
	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const handlePasswordChange = (value) => {
		setPassword(value);
	};

	const handleTermsChange = (event) => {
		const checked = event;
		setTerms(checked);
	};

	const handlePPChange = (event) => {
		const checked = event;
		setPP(checked);
	};

	// Defining useNavigate from react router dom
	const navigate = useNavigate();
	// React Query hook waiting on isUsingGooglePopUp to be true then it opens.
	useSignInWithPopup(isUsingGooglePopUp);

	// Handle signup form submit,
	// create account w/ email and password via firebase
	const signUpWithEmail = (event) => {
		event.preventDefault();
		// mutations should invoked by their mutate fn
		addUser.mutate(
			{ email: userEmail, password },
			{
				onSuccess: () => {
					// Direct user to routing page to handle
					// profile parsing and to be directed to next step (RoutingUser.js)
					navigate('/routing-user');
				}
			}
		);
	};

	// Handle google signup,
	// create account w/ an existing google account
	const useSignUpWithGoogle = () => {
		setIsUsingGooglePopUp(!isUsingGooglePopUp);
	};

	// Handling the client invite case and pre-populating invitee's email
	// in email input
	const inviteeEmail = queryClient.getQueryData(INVITEE_EMAIL) || '';
	if (inviteeEmail !== '' && userEmail === '') {
		handleEmailChange(inviteeEmail);
	}

	return (
		<>
			<Grid
				item
				xs={6}
				sx={{
					height: '100%',
					width: '100%'
				}}
			>
				<LoginArtAndSoraLogo />
			</Grid>
			<Grid
				item
				xs={1}
				sx={{ backgroundColor: SoraTheme.palette.primary.white }}
			/>
			<Grid
				item
				xs={4}
				sx={{ backgroundColor: SoraTheme.palette.primary.white }}
			>
				{addUserError && <ErrorAlert variant="error" />}
				<Typography
					variant="h4"
					gutterBottom
					component="div"
					sx={{
						marginTop: 18,
						color: SoraTheme.palette.primary.soraBlue
					}}
				>
					Create your account
				</Typography>

				{/* Link to login page */}
				<Typography variant="subtitle2">
					Already have an account?&nbsp;
					<Link
						to="/login"
						style={{
							color: SoraTheme.palette.primary.black,
							textDecorationColor: SoraTheme.palette.primary.black
						}}
					>
						Login
					</Link>
				</Typography>

				{/* Signup form */}
				<Grid
					component="form"
					noValidate
					autoComplete="off"
					item
					xs={12}
					lg={10}
				>
					{/* Email */}
					<Input
						id={EMAIL}
						label="Email"
						type={EMAIL}
						name={EMAIL}
						onChange={handleEmailChange}
						value={userEmail}
						error={!isEmail(userEmail)}
						helperText="Please enter a valid email address"
						sx={{
							marginTop: 4,
							marginBottom: 2,
							width: '100%'
						}}
					/>

					{/* Password */}
					<Input
						id="PASSWORD"
						label="Password"
						type={TEXT}
						value={password}
						onChange={handlePasswordChange}
						error={password.length < 8}
						helperText="Password must be minimum of 8 characters"
						sx={{
							marginTop: 4,
							marginBottom: 2,
							width: '100%'
						}}
						withVisibleToggle
						defaultVisability={false}
					/>
				</Grid>

				{/* Terms of use checkbox */}
				<Grid item xs={12} lg={10}>
					<Checkbox
						checked={agreeTerms}
						onChange={handleTermsChange}
						label={
							<Typography variant="subtitle1">
								I agree to Sora&apos;s&nbsp;
								<ExternalLink
									href="https://www.sorafinance.com/terms"
									sx={{
										color: SoraTheme.palette.primary.black,
										'&:visited': {
											color: SoraTheme.palette.primary
												.black
										}
									}}
								>
									Terms of Service
								</ExternalLink>
								&nbsp;*
							</Typography>
						}
						sx={{
							marginTop: 4
						}}
					/>
				</Grid>

				{/* Privacy policy checkbox */}
				<Grid item xs={12} lg={10}>
					<Checkbox
						checked={agreePP}
						onChange={handlePPChange}
						label={
							<Typography variant="subtitle1">
								I agree to Sora&apos;s&nbsp;
								<ExternalLink
									href="https://www.sorafinance.com/privacy-policy"
									sx={{
										color: SoraTheme.palette.primary.black,
										'&:visited': {
											color: SoraTheme.palette.primary
												.black
										}
									}}
								>
									Privacy Policy
								</ExternalLink>
								&nbsp;* &nbsp;and&nbsp;
								<ExternalLink
									href="https://www.sorafinance.com/data-processing-agreement"
									sx={{
										color: SoraTheme.palette.primary.black,
										'&:visited': {
											color: SoraTheme.palette.primary
												.black
										}
									}}
								>
									Data Processing Agreement
								</ExternalLink>
								&nbsp;*
							</Typography>
						}
					/>
				</Grid>

				<PrimaryButton
					onClick={signUpWithEmail}
					disabled={!isFormReady}
					sx={{
						marginTop: 4,
						display: 'block'
					}}
				>
					Sign up
				</PrimaryButton>

				<Typography
					variant="subtitle1"
					sx={{
						marginTop: 4
					}}
				>
					- or -
				</Typography>

				<Typography
					variant="subtitle1"
					sx={{
						marginTop: 2,
						fontStyle: 'italic'
					}}
				>
					Agree to Sora&apos;s Terms of Service, Privacy Policy and
					Data Processing Agreement in order to sign up with Google
				</Typography>

				<SecondaryButton
					onClick={useSignUpWithGoogle}
					disabled={agreeTerms !== true || agreePP !== true}
					sx={{
						marginTop: 4
					}}
					startIcon={<GoogleIcon />}
				>
					Sign up with Google
				</SecondaryButton>
			</Grid>
		</>
	);
}

export default FullSignupForm;
