import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function TotalCostOfDebtCard({ totalCostOfDebt }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const formattedTotalCostOfDebt = dollarFormatter.format(totalCostOfDebt);

	return (
		<Grid item sm={6} md={6} lg={4} xl={2}>
			<CardItem
				sx={{ alignItems: 'center' }}
				cardHeading="Total Cost of Debt"
				withoutButton
			>
				<Box sx={{ flexGrow: 2 }}>
					<Typography
						variant="h4"
						color={primary.indigo}
						fontWeight={700}
						sx={{ marginTop: 2 }}
					>
						{formattedTotalCostOfDebt}
					</Typography>
				</Box>
			</CardItem>
		</Grid>
	);
}

TotalCostOfDebtCard.propTypes = {
	totalCostOfDebt: PropTypes.number.isRequired
};

export default TotalCostOfDebtCard;
