import PropTypes from 'prop-types';
import { Box, Link as ExternalLink, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { format } from 'date-fns';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import Dropdown from 'components/Dropdown/Dropdown';
import TotalLiabilitiesCardHeading from 'components/SoraWallet/Cards/totalLiabilitiesCardComponents/TotalLiabilitiesCardHeading';
import TotalLiabilitiesCardRowItem from 'components/SoraWallet/Cards/totalLiabilitiesCardComponents/TotalLiabilitiesCardRowItem';
import { TertiaryButton } from 'components/Button/Button';
import SoraToolTip from 'components/Tooltip';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Our hooks
import useGetLiabilitiesTableList from 'hooks/clientOnboarding/useGetLiabilitiesTableList';

// Routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// constants
import { MORTGAGE } from 'shared/constants';

/*
  snowball: smallest to largest loan balance.
  avalanche: highest to lowest interest rate.
*/

const SNOWBALL = 'Snowball (lowest balance)';
const AVALANCHE = 'Avalanche (highest interest)';
const MONTHLY_PAYMENT = 'Monthly Payment';
const TRADELINE_TYPE = 'Loan Type';

const DropDownOptions = [SNOWBALL, AVALANCHE, MONTHLY_PAYMENT, TRADELINE_TYPE];

const getLiabilityConfidence = (rateSource) => {
	if (rateSource === 'financial_institution') return 'High';
	if (rateSource === 'method') return 'Medium';

	return 'Low';
};

const getFormattedRefreshDate = (refreshDate) => {
	if (refreshDate) {
		return format(new Date(refreshDate), 'MM/dd/yyyy');
	}

	return 'NA';
};

// Sorts by TradelineType
const sortLiabilitiesByKey = (liabilities, key, descending = false) =>
	liabilities.sort((liabilityA, liabilityB) => {
		const liabilityAValue = liabilityA[key];
		const liabilityBValue = liabilityB[key];

		if (descending) {
			if (liabilityAValue > liabilityBValue) return -1;
			if (liabilityAValue < liabilityBValue) return 1;
		} else {
			if (liabilityAValue < liabilityBValue) return -1;
			if (liabilityAValue > liabilityBValue) return 1;
		}

		return 0;
	});

const sortLiabilities = (userLiabilities, sortBy) => {
	const liabilityCopyForSort = userLiabilities.slice(0);
	if (sortBy === '') {
		// default Sort
		// sorted by outstanding balance with largest value on top
		sortLiabilitiesByKey(liabilityCopyForSort, 'outstandingBalance', true);
	} else if (sortBy === SNOWBALL) {
		// Snowball
		// sorted via outstanding balance with smallest value on top
		sortLiabilitiesByKey(liabilityCopyForSort, 'outstandingBalance', false);
	} else if (sortBy === MONTHLY_PAYMENT) {
		// Monthly Payment
		// sorted via monthly payment with largest value on top
		sortLiabilitiesByKey(liabilityCopyForSort, 'monthlyPay', true);
	} else if (sortBy === TRADELINE_TYPE) {
		// Loan Type
		// sorted via loan type with largest value on top
		sortLiabilitiesByKey(liabilityCopyForSort, 'tradeLineType', false);
	} else {
		// Avalanche
		// sort via interest rate with highest interest rate on top
		sortLiabilitiesByKey(liabilityCopyForSort, 'interestRate', true);
	}

	return liabilityCopyForSort;
};

function ToolTipWithLink() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant="subtitle2">
				What are the Snowball and Avalanche methods?
			</Typography>
			<ExternalLink
				href="https://www.sorafinance.com/insights/snowball-vs-avalanche-what-is-the-best-way-to-pay-off-debt"
				target="_blank"
			>
				Learn More
			</ExternalLink>
		</Box>
	);
}

function TotalLiabilitiesCard({ totalLiabilities, liabilities }) {
	const { pathname } = useLocation();
	const isClient = !pathname.includes('advisor');
	const [sortBy, setSortBy] = useState('');

	const formattedTotalLiabilities = dollarFormatter.format(totalLiabilities);

	const sortedLiabilities = sortLiabilities(liabilities, sortBy);

	const filteredLiabilities = useGetLiabilitiesTableList(
		sortedLiabilities,
		true
	);

	const totalLiabilitiesRowItems = useMemo(
		() =>
			filteredLiabilities.map((liability) => {
				const {
					interestRate: liabilityRate,
					interestRateSource,
					lender: liabilityDetails,
					lastRefreshedDate,
					outstandingBalance: liabilityBalance,
					tradeLineType,
					tradelineId
				} = liability;

				const liabilityConfidence =
					getLiabilityConfidence(interestRateSource);

				const formattedLastRefreshDate =
					getFormattedRefreshDate(lastRefreshedDate);

				const monthlyPay =
					tradeLineType === MORTGAGE
						? liability?.monthlyMortgagePayment ??
						  liability?.monthlyPay
						: liability?.monthlyPay;

				const liablityType = normalizeLoanType(tradeLineType);

				return (
					<TotalLiabilitiesCardRowItem
						key={uuidv4()}
						liabilityBalance={liabilityBalance}
						liabilityConfidence={liabilityConfidence}
						liabilityDetails={liabilityDetails}
						liabilityRate={liabilityRate}
						liabilityType={liablityType}
						lastRefreshDate={formattedLastRefreshDate}
						monthlyPay={monthlyPay}
						tradelineId={tradelineId}
					/>
				);
			}),
		[liabilities, sortBy]
	);

	return (
		<CardItem withoutButton sx={{ padding: 6 }}>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: 4
				}}
			>
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						gap: 1,
						marginBottom: 4
					}}
				>
					<Typography variant="h5" fontWeight={700}>
						Total Liabilities:
					</Typography>
					<Typography
						variant="h5"
						fontWeight={700}
						sx={{ textDecoration: 'underline' }}
					>
						{formattedTotalLiabilities}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
					<Dropdown
						items={DropDownOptions}
						selected={sortBy}
						onChange={setSortBy}
						placeholder="Sort by"
						variant="outlined"
					/>
					<SoraToolTip text={<ToolTipWithLink />}>
						<InfoIcon sx={{ cursor: 'pointer' }} />
					</SoraToolTip>
				</Box>
			</Box>
			<Box
				sx={{
					flexGrow: 2,
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					marginBottom: 4
				}}
			>
				<TotalLiabilitiesCardHeading />
				{totalLiabilitiesRowItems}
			</Box>
			{isClient && (
				<Link
					to={LIABILITY_ADDITIONAL_ROUTE}
					state={{ fromWallet: true }}
					style={{ width: 'fit-content', textDecoration: 'none' }}
				>
					<TertiaryButton sx={{ height: 45 }}>
						Add liabilities
					</TertiaryButton>
				</Link>
			)}
		</CardItem>
	);
}

TotalLiabilitiesCard.propTypes = {
	totalLiabilities: PropTypes.number.isRequired
};

export default TotalLiabilitiesCard;
