import { generatePath, useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

// Our Components
import Loader from 'components/Loader';
import { PrimaryButton } from 'components/Button/Button';

// Our Hooks
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';
import useGetAllUserLiabilities from 'hooks/clientOnboarding/useGetAllUserLiabilities';
import useGetSupportedLiabilities from 'hooks/clientOnboarding/useGetSupportedLiabilities';

// Our Utils
import getNextLiabilityRoute from 'shared/utils/clientOnboarding/getNextLiabilityRoute';

// Our Routes
import { ADVISOR_WALLET_LIABILITY, CLIENT_WALLET_OVERVIEW } from 'routes';

function LiabilityDynamicButton({ disabled, isMutationLoading, onClick }) {
	const location = useLocation();

	const fromWallet = useWasFromWallet(location);

	const { pathname, state } = location;

	const { tradeLineId: currentTradeLineId } = useParams();

	// if you're on the client onboarding then get all user liabilities to determine the next route
	const enableGetAllLiabilities = !fromWallet;

	const { isLoading, isSuccess, data } = useGetAllUserLiabilities(
		enableGetAllLiabilities
	);

	const filteredLiabilityList = useGetSupportedLiabilities(data, isSuccess);

	const userTradeLines = useMemo(
		() =>
			filteredLiabilityList.map(({ tradelineId, tradeLineType }) => {
				const formattedTradeLineType = tradeLineType?.toLowerCase();
				return {
					tradelineId,
					tradeLineType: formattedTradeLineType
				};
			}),
		[filteredLiabilityList]
	);

	const handleOnClick = (e, route) => {
		e.preventDefault();
		onClick(route);
	};

	// IMPORTANT order here matters!
	if (isLoading || isMutationLoading) {
		return <Loader boxSX={{ alignItems: 'center' }} />;
	}

	if (fromWallet) {
		const clientIdForAdvisor = state?.clientId;

		const nextRoute = clientIdForAdvisor
			? generatePath(`${ADVISOR_WALLET_LIABILITY}:clientId`, {
					clientId: clientIdForAdvisor
			  })
			: CLIENT_WALLET_OVERVIEW;

		return (
			<PrimaryButton
				disabled={disabled}
				onClick={(e) => handleOnClick(e, nextRoute)}
			>
				Save
			</PrimaryButton>
		);
	}

	if (isSuccess) {
		const nextRoute = getNextLiabilityRoute(
			pathname,
			currentTradeLineId,
			userTradeLines
		);

		return (
			<PrimaryButton
				data-test="next"
				disabled={disabled}
				onClick={(e) => handleOnClick(e, nextRoute)}
			>
				Next
			</PrimaryButton>
		);
	}
}

LiabilityDynamicButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	isMutationLoading: PropTypes.bool
};

LiabilityDynamicButton.defaultProps = {
	disabled: false,
	isMutationLoading: false
};

export default LiabilityDynamicButton;
