/**
 *
 * @param {string} sentence
 * @param {string} splitter character that is expected to separate values between words. e.g. ' ' a space
 * @returns `string` capitalize sentence i.e. "foo bar cat" -> "Foo Bar Cat"
 */

function sentenceCapitalize(sentence, splitter = ' ') {
	// Another Method: We could also, lowercase the hole sentence and then just .toUpperCase() the first character. ie. moving like 14 up and performing it on the whole string.
	// const lowercaseSentence = sentence.toLowerCase();

	const separatedBySpaces = sentence.split(splitter);

	try {
		const capitalizeSentence = separatedBySpaces.map(
			(currentString) =>
				currentString[0].toUpperCase() +
				currentString.slice(1).toLowerCase()
		);

		return capitalizeSentence.join(' ');
	} catch (e) {
		console.error(e);
		return 'Data Not Found';
	}
}

export default sentenceCapitalize;
