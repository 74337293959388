import { Box, Tab, Tabs } from '@mui/material';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

// Our Components
import a11yProps from 'components/Tabs/AccessibilityProps';
import ChangePassword from 'components/Settings/ChangePassword';
import SettingsPanel from 'components/Settings/SettingsForm';
import PlanBillingTab from 'components/Settings/PlanBillingTab';
import TabPanel from 'components/Tabs/TabPanel';

// Our Query Keys
import { ROLE } from 'shared/query-keys';

// Main Component
function SettingsTabs({ tabPanelSelected, setTabPanelSelected }) {
	const queryClient = useQueryClient();
	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	// Retrieve role from react query
	const role = queryClient.getQueryData(ROLE);
	const isClient = role === 'CLIENT';

	return (
		<Box
			sx={{
				marginTop: 6
			}}
		>
			<Box>
				<Tabs
					value={tabPanelSelected}
					onChange={handleTabSelection}
					aria-label="Settings tabs"
				>
					<Tab label="My Profile" {...a11yProps(0)} />
					<Tab label="Change Password" {...a11yProps(1)} />
					{!isClient && (
						<Tab label="Plan & Billing" {...a11yProps(2)} />
					)}
				</Tabs>
			</Box>

			<TabPanel value={tabPanelSelected} index={0} sx={{ paddingTop: 4 }}>
				<SettingsPanel />
			</TabPanel>
			<TabPanel value={tabPanelSelected} index={1} sx={{ paddingTop: 4 }}>
				<ChangePassword />
			</TabPanel>

			{!isClient && (
				<TabPanel
					value={tabPanelSelected}
					index={2}
					sx={{ paddingTop: 8 }}
				>
					<PlanBillingTab />
				</TabPanel>
			)}
		</Box>
	);
}

SettingsTabs.propTypes = {
	tabPanelSelected: PropTypes.number.isRequired,
	setTabPanelSelected: PropTypes.func.isRequired
};

export default SettingsTabs;
