import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// our Components
import BasicNavFrame from 'components/Frame/BasicNavAndFooter';
import SelectLoanLiabilityCard from 'components/Client/newLoan/SelectLoanLiabilityCard';
import LoanOrderedListDetails from 'components/Client/newLoan/loanOrderedListDetails';
import listDetails from 'components/Client/newLoan/listDetails.json';

// Our Routes
import { NEW_AUTO_LOAN_QUESTIONNAIRE } from 'routes';

function NewLoanSelectType() {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<BasicNavFrame
			backEnabled
			footer={
				<Box
					sx={{
						padding: 15,
						paddingTop: 6,
						paddingBottom: 6,
						bgcolor: primary.indigo,
						marginTop: 4
					}}
				>
					<Typography
						variant="h4"
						gutterBottom
						color={primary.iceBlue}
					>
						Everything you need to know
					</Typography>
					<LoanOrderedListDetails details={listDetails[0]} />
					<LoanOrderedListDetails details={listDetails[1]} />
				</Box>
			}
		>
			<Grid item xs={10} sx={{ marginBottom: 4 }}>
				<Typography variant="h4" gutterBottom color={primary.soraBlue}>
					What kind of loan are you looking for?
				</Typography>
			</Grid>
			<Grid item xs={2} />

			<Grid item xs={12}>
				<Box
					sx={{
						display: 'flex',
						gap: 15
					}}
				>
					<SelectLoanLiabilityCard
						LiabilityType="Mortgage"
						loanHeading="Home Loan"
						withoutCheckBox
					/>
					<SelectLoanLiabilityCard
						LiabilityType="Auto"
						loanHeading="Auto Loan"
						onClickNavRoute={NEW_AUTO_LOAN_QUESTIONNAIRE}
						withoutCheckBox
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						gap: 15
					}}
				>
					<SelectLoanLiabilityCard
						disable
						LiabilityType="Student"
						loanHeading="Student Loan"
						withoutCheckBox
					/>
					<SelectLoanLiabilityCard
						disable
						LiabilityType="Personal"
						loanHeading="Personal Loan"
						withoutCheckBox
					/>
				</Box>
			</Grid>
		</BasicNavFrame>
	);
}

export default NewLoanSelectType;
