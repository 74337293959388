import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our components
import BasicFrame from 'components/Frame/Basic';
import NavMenu from 'components/NavMenu/NavMenu';
import Newsfeed from 'components/FADashboard/Newsfeed';
import Footer from 'components/Footer/Footer';

const StyledGrid = styled(Grid)(`
	margin: 0;

	& .MuiGrid-item {
		padding-top: 0;
		padding-left: 0;
	}
`);

function BasicNavAndFeedFrame(props) {
	const SoraTheme = useTheme();
	const environment = process.env.REACT_APP_ENVIRONMENT;
	const isDevEnvironment = environment === 'development';

	const { children } = props;
	// A basic 12 column grid split:
	// [ 2 columns | 8 columns | 2 columns ]
	// 2 columns: will hold the navigation menu
	// 8 columns:  Within these 8 cols we reimplemented another 12 column grid,
	// giving us spacing options for the center of the frame.
	// 2 columns: will hold the news feed

	return (
		<StyledGrid
			container
			spacing={2}
			sx={{ height: '100vh', width: '100%' }}
		>
			<Grid item xs={2}>
				<Box
					sx={{
						position: 'fixed',
						background:
							SoraTheme.palette.gradient
								.soraBlueToWhiteLinearGradient,
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						width: '15%'
					}}
				>
					{/* Navigation menu here */}
					<NavMenu />
				</Box>
			</Grid>

			<Grid item xs={8} sx={{ height: '100vh' }}>
				<BasicFrame sx={{ height: '100vh' }}>
					{children}
					<Grid container item xs={12}>
						<Footer sx={{ alignSelf: 'flex-end', flexGrow: 2 }} />
					</Grid>
				</BasicFrame>
			</Grid>

			<Grid item xs={2}>
				{/* News feed here */}
				<Typography
					variant="h6"
					color={SoraTheme.palette.primary.indigo}
					sx={{ marginTop: 9.5, marginLeft: 6 }}
				>
					Newsfeed
				</Typography>
				<Box sx={{ padding: 4, margin: 0 }}>
					{isDevEnvironment && (
						<Box sx={{ border: '2px solid red', padding: '10px' }}>
							<Typography variant="body1">
								The newsfeed is only visible in the Test and
								Production environments.
							</Typography>
						</Box>
					)}
					{!isDevEnvironment && <Newsfeed />}
				</Box>
			</Grid>
		</StyledGrid>
	);
}

export default BasicNavAndFeedFrame;
