import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SoraColors from 'components/colors';
import SoraTypography from 'components/typography';

export default function StyleReferencePage() {
	return (
		<Box>
			{/* Page heading */}
			<Typography
				variant="h1"
				gutterBottom
				component="div"
				sx={{
					textAlign: 'center',
					marginTop: 2
				}}
			>
				Sora brand styles
			</Typography>
			{/* Sora colors */}
			<SoraColors />
			{/* Sora typography */}
			<SoraTypography />
			<Box
				sx={{
					textAlign: 'center',
					marginTop: 2
				}}
			>
				<Link to="/">Home</Link>
			</Box>
		</Box>
	);
}
