import { Box, Typography } from '@mui/material/index';
import PropTypes from 'prop-types';
import numbro from 'numbro';

// Our Components
import ResponsiveTable from 'components/Table/ResponsiveTable';

// Column Names
const columnNames = [
	'',
	'Starting Balance',
	'Interest',
	'Principal',
	'Ending Balance'
];

function AmortizationTable({ title, rows }) {
	const formattedRows = rows.map(
		({ month, balance, interest, principal, endingBalance }) => {
			const values = [balance, interest, principal, endingBalance];
			return [month].concat(
				values.map((value) =>
					numbro(value < 0 ? 0 : value).formatCurrency({
						thousandSeparated: true,
						mantissa: 0
					})
				)
			);
		}
	);

	return (
		<Box sx={{ textAlign: 'center' }}>
			<Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
				{title}
			</Typography>
			<ResponsiveTable columns={columnNames} rows={formattedRows} />
		</Box>
	);
}

AmortizationTable.propTypes = {
	title: PropTypes.string.isRequired,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			balance: PropTypes.number,
			endingBalance: PropTypes.number,
			interest: PropTypes.number,
			month: PropTypes.number,
			pay: PropTypes.number,
			principal: PropTypes.number,
			totalInterest: PropTypes.number
		})
	).isRequired
};

export default AmortizationTable;
