import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// our components
import BalanceOutstandingCard from 'components/SoraWallet/Cards/BalanceOutstandingCard';
import DetailedListCard from 'components/SoraWallet/Cards/DetailedListCard';
import EquityAvailableCard from 'components/SoraWallet/Cards/EquityAvailableCard';
import Loader from 'components/Loader';
import LoanDataCard from 'components/SoraWallet/Cards/LoanDataCard';
import PropertyDataCardSimple from 'components/SoraWallet/Cards/PropertyDataCardSimple';
import RefinanceOptionsCard from 'components/SoraWallet/Cards/RefinanceOptionsCard';
import SoraInsightsCarousel from 'components/Carousel/SoraInsightsCarousel';
import SoraWalletLoanCard from 'components/SoraWallet/SoraWalletLoanCard';
import SoraScoreCard from 'components/SoraWallet/Cards/SoraScoreCard';
import TotalCostOfDebtCard from 'components/SoraWallet/Cards/TotalCostOfDebtCard';
import UntappedSavingsCard from 'components/SoraWallet/Cards/UntappedSavingsCard';
import UpsidePotentialCard from 'components/SoraWallet/Cards/UpsidePotentialCard';
import WalletWellDoneCard from 'components/SoraWallet/Cards/WalletWellDoneCard';
import YearsRemainingCard from 'components/SoraWallet/Cards/YearsRemainingCard';
import ZeroLiabilities from 'components/SoraWallet/ZeroLiabilities';

// our Hooks
import useGetSoraWalletLiability from 'hooks/useGetSoraWalletLiability';

// Our Query Keys
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

// Tool Tips
import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

// Our utils
import { dataIsValid, extractSoraScore } from 'shared/utils';
import { getMortgageReportInsights } from 'components/ExportableReport/ExportableReportRuleProcessor';

// Constants
import { MORTGAGE, AUTO, STUDENT, PERSONAL, HELOC } from 'shared/constants';

function SoraWallet({ isClient }) {
	// isClient is undefined for FA and isClient is true for Clients
	const { clientId, tradelineId } = useParams();
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();

	const { primary } = SoraTheme.palette;

	// Here are the hooks to make the api calls
	// isClient is passed as a prop and it decides which hook to call
	// e.g. isClient is true and therefore useGetFaWallet will be disabled

	const {
		data: walletLiabilityData,
		isLoading,
		isSuccess,
		error
	} = useGetSoraWalletLiability(isClient);

	// The Wallet Overview Liability Data structure
	const currentLiabilityInsights = useMemo(() => {
		const walletOverviewData = queryClient.getQueryData(
			isClient ? WALLET_OVERVIEW_DATA : [WALLET_OVERVIEW_DATA, clientId]
		);

		if (walletOverviewData?.liability?.liabilities) {
			const currentLiability =
				walletOverviewData.liability.liabilities.find(
					(liability) => liability.tradelineId === +tradelineId
				);
			return getMortgageReportInsights(currentLiability);
		}

		return [];
	}, [(tradelineId, walletLiabilityData)]);

	if (isLoading) {
		return (
			<Loader
				size={60}
				boxSX={{
					justifyContent: 'center',
					alignItems: 'center',
					height: 800,
					width: '100%'
				}}
			/>
		);
	}

	if (error) {
		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet</title>
				</Helmet>
				<Box sx={{ height: '70vh', width: '100%', paddingTop: 20 }}>
					<Typography
						variant="h4"
						gutterBottom
						sx={{ marginBottom: 4 }}
					>
						Whoops. Something went wrong.
					</Typography>
					<Typography variant="subtitle1">
						The Sora team will fix it right away!
					</Typography>
				</Box>
			</>
		);
	}

	if (isSuccess) {
		const { bestRefi, currentLoan, owner, soraGrade } = walletLiabilityData;

		const isCurrentLoanMissing = !dataIsValid(currentLoan);

		if (isCurrentLoanMissing)
			return (
				<>
					<Helmet>
						<title>{isClient ? 'Client' : 'FA'} Wallet</title>
					</Helmet>
					<Box sx={{ height: '70vh', width: '100%', paddingTop: 20 }}>
						<Typography
							variant="h4"
							gutterBottom
							sx={{ marginBottom: 4 }}
						>
							Whoops. Something went wrong.
						</Typography>
						<Typography variant="subtitle1">
							The Sora team will fix it right away!
						</Typography>
					</Box>
				</>
			);

		// These values are received in cents from the backend, Dividing by 100 to format value before beginning to work with it.
		const untappedSavings =
			(walletLiabilityData?.untappedSaving ?? 0) / 100;
		const upsidePotential =
			(walletLiabilityData?.upsidePotential ?? 0) / 100;

		const { firstName, lastName } = owner;

		const contactClientData = {
			clientId,
			name: `${firstName} ${lastName}`
		};

		const walletOverviewData = queryClient.getQueryData(
			isClient ? WALLET_OVERVIEW_DATA : [WALLET_OVERVIEW_DATA, clientId]
		);

		const assetsAmount = walletOverviewData?.assetAmount ?? 0;
		const creditScore = walletOverviewData?.creditScore ?? 0;
		const annualIncome = walletOverviewData?.annualIncome ?? 0;
		const emailAddress = walletOverviewData?.email ?? '';

		const clientData = {
			assetsAmount,
			creditScore,
			annualIncome,
			firstName,
			lastName,
			emailAddress
		};

		const formattedSoraGrade = extractSoraScore(soraGrade) ?? 'A';

		// Checks
		const noCurrentLoan = currentLoan === undefined;
		const hasAnExistingLoan = !noCurrentLoan;
		const shouldRenderChart = !!currentLoan && !!bestRefi; // this checks if each object exists and therefore results in a boolean expression
		const hasRefinanceOffer = bestRefi !== undefined;
		const hasNoOffers = bestRefi === undefined;

		const tradelineType = currentLoan.tradeLineType;
		const isHomeLiability = tradelineType === MORTGAGE;
		const isHelocLiability = tradelineType === HELOC;
		const isAutoLiability = tradelineType === AUTO;
		const isStudentLiability = tradelineType === STUDENT;
		const isPersonalLiability = tradelineType === PERSONAL;

		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet</title>
				</Helmet>

				<Grid
					container
					spacing={4}
					sx={{ backgroundColor: primary.lightgrey, paddingRight: 6 }}
				>
					{noCurrentLoan && <ZeroLiabilities isClient={isClient} />}

					{/* HOME LIABILITY */}
					{hasAnExistingLoan && isHomeLiability && (
						<>
							<PropertyDataCardSimple
								currentLoanDetails={currentLoan}
							/>

							<LoanDataCard currentLoanDetails={currentLoan} />
							<Grid item sm={12} md={12} lg={6} xl={6}>
								<BalanceOutstandingCard
									currentLoanDetails={currentLoan}
								/>
								<Grid container item spacing={4}>
									<Grid item sm={6} md={6} lg={6} xl={6}>
										<EquityAvailableCard
											currentLoanDetails={currentLoan}
											clientData={contactClientData}
										/>
									</Grid>
									<Grid item sm={6} md={6} lg={6} xl={6}>
										<SoraScoreCard soraScore={soraGrade} />
									</Grid>
								</Grid>
							</Grid>

							<UpsidePotentialCard
								heading="Upside Potential"
								calculatedValue={upsidePotential}
								tooltTipText={WalletToolTips.upsidePotential}
							/>

							<UntappedSavingsCard
								heading="Untapped Savings"
								calculatedValue={untappedSavings}
								tooltTipText={WalletToolTips.untappedSavings}
							/>

							{hasRefinanceOffer && (
								<RefinanceOptionsCard
									bestRefi={bestRefi}
									currentLoan={currentLoan}
									isClient={isClient}
									shouldRenderChart={shouldRenderChart}
								/>
							)}

							{/* Insights Section */}
							<Grid item sm={12}>
								{currentLiabilityInsights.length > 0 && (
									<>
										<Typography
											variant="h4"
											gutterBottom
											sx={{
												color: primary.indigo,
												marginTop: 4,
												marginBottom: 4
											}}
										>
											Sora Insights
										</Typography>

										<SoraInsightsCarousel
											clientData={clientData}
											carouselBreakPoints={{
												desktop: {
													breakpoint: {
														max: 6000,
														min: 0
													},
													items: 1,
													slidesToSlide: 1
												}
											}}
											isClient={isClient}
											insights={currentLiabilityInsights}
											insightCardSX={{
												marginRight: 6,
												marginLeft: 6
											}}
											leftArrowSX={{
												left: 2
											}}
											rightArrowSX={{
												right: 2
											}}
										/>
									</>
								)}
							</Grid>

							{/* Liability Details Section */}
							<Grid item sm={12}>
								<Typography
									variant="h4"
									gutterBottom
									sx={{
										color: primary.indigo,
										marginTop: 4,
										marginBottom: 4
									}}
								>
									Additional Details
								</Typography>
							</Grid>

							<Grid item sm={12} sx={{ marginBottom: 2 }}>
								<DetailedListCard
									cardHeading="Property Data"
									currentLoan={currentLoan}
								/>
							</Grid>

							<Grid item sm={12} sx={{ marginBottom: 2 }}>
								<DetailedListCard
									cardHeading="Current Liabilities Data"
									currentLoan={currentLoan}
									soraScore={soraGrade}
								/>
							</Grid>
						</>
					)}

					{/* HELOC LIABILITY */}
					{hasAnExistingLoan && isHelocLiability && (
						<>
							<SoraWalletLoanCard
								currentLoanDetails={currentLoan}
								homeDetails={currentLoan?.home}
								firstName={firstName}
								lastName={lastName}
								soraGrade={formattedSoraGrade}
							/>
							<TotalCostOfDebtCard
								totalCostOfDebt={currentLoan?.costOfDebt}
							/>

							<YearsRemainingCard
								remainingMonth={currentLoan?.remainingMonth}
							/>

							{hasNoOffers && <WalletWellDoneCard />}
						</>
					)}

					{/* AUTO, STUDENT & PERSONAL LIABILITY */}
					{hasAnExistingLoan &&
						(isAutoLiability ||
							isStudentLiability ||
							isPersonalLiability) && (
							<>
								<SoraWalletLoanCard
									currentLoanDetails={currentLoan}
									homeDetails={currentLoan?.home}
									firstName={firstName}
									lastName={lastName}
									soraGrade={formattedSoraGrade}
								/>

								<TotalCostOfDebtCard
									totalCostOfDebt={currentLoan?.costOfDebt}
								/>
								<Grid item sm={6} md={6} lg={4} xl={2}>
									<YearsRemainingCard
										remainingMonth={
											currentLoan?.remainingMonth
										}
									/>
								</Grid>

								{hasNoOffers && <WalletWellDoneCard />}
							</>
						)}
				</Grid>
			</>
		);
	}
}

SoraWallet.propTypes = {
	isClient: PropTypes.bool.isRequired
};

export default SoraWallet;
