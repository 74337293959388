import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

// Our Components
import DynamicTabs from 'components/Tabs/DynamicTabs';
import ClientInvitation from 'pages/Client/AddingClient/SendClientInvite';
import OnboardClientsBySpreadsheet from 'pages/Client/AddingClient/AddClientsBySpreadsheet';
import OnboardSingleClient from 'pages/Client/AddingClient/AddingSingleClient';
import Frame from 'components/Frame/BasicNav';

// TABS
const TAB_ITEMS = [
	{ type: 'Upload Multiple Clients' },
	{ type: 'Add Individual Client' },
	{ type: 'Invite a Client' }
];
const TAB_PANEL_ITEMS = [
	{ children: <OnboardClientsBySpreadsheet />, value: 0 },
	{ children: <OnboardSingleClient />, value: 1 },
	{ children: <ClientInvitation />, value: 2 }
];

function AssetOnboardTabs() {
	const [value, setValue] = useState(0);

	const handleClick = (e, indexClicked) => {
		setValue(indexClicked);
	};

	return (
		<>
			<Helmet>
				<title>Invite Clients</title>
			</Helmet>

			<Frame
				onboardingEnabled={false}
				sx={{ width: '100%', height: '100vh', overflow: 'auto' }}
			>
				<DynamicTabs
					dataTestTag="inviteClientTabs"
					sx={{ marginBottom: 4, marginTop: 4 }}
					tabItems={TAB_ITEMS}
					handleChange={handleClick}
					tabPanelItems={TAB_PANEL_ITEMS}
					tabsSX={{ marginBottom: 6 }}
					tabItemSX={{ cursor: 'pointer', textTransform: 'none' }}
					variant="standard"
					value={value}
				/>
			</Frame>
		</>
	);
}

export default AssetOnboardTabs;
